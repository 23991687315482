import React, { Component } from 'react'

import Privileges from './Privileges';
import Main from './../LayoutComponent/Main';
import { connect } from 'react-redux';
// import { checkUser } from '../../actions/AuthAction';
import { serverLoad, serverGetPrivileges, serverSavePrivileges, serverGetContactPrivileges, serverDisableAccount } from '../../actions/PrivilegesAction';
import { serverGetContacts } from '../../actions/ContactsAction'
// import axios from 'axios'
// import types from '../../types';
// const $ = require("jquery");

class PrivilegesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contacts_dropdown: [],
            privilege_details: {},
            privileges_dropdown: [],
            selected_privilege_category: [],
            selected_privileges: [],
            contact_disabled: '',
            final_selected_privileges: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.selectPrivilege = this.selectPrivilege.bind(this);
        this.savePrivileges = this.savePrivileges.bind(this);
        this.disableAccount = this.disableAccount.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/")
        }

        if (this.props.auth.user) {
            if (!this.props.contacts.contacts.length) {
                this.props.serverGetContacts(this.props.auth.user.token);
            } else {
                let arr = []

                // eslint-disable-next-line array-callback-return
                this.props.contacts.contacts.filter((data, index) => {
                    let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company_name})` };
                    arr.push(v);
                });

                this.setState({ contacts_dropdown: arr });
            }

            this.props.serverGetPrivileges(this.props.auth.user.token);
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.contacts !== this.props.contacts) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company_name})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        if (prevProps.privileges.privileges !== this.props.privileges.privileges) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.privileges.privileges.filter((data, index) => {
                let v = { "value": data.id, "label": data.category };
                arr.push(v);
            });

            this.setState({ privileges_dropdown: arr });
        }

        if (prevProps.privileges.contact_privileges !== this.props.privileges.contact_privileges) {

            let selected_privileges = { ...this.state.selected_privileges };
            // eslint-disable-next-line array-callback-return
            this.state.selected_privilege_category[0].privileges.filter((data, index) => {
                if (this.props.privileges.contact_privileges.includes(data.id + "")) {
                    selected_privileges[data.id] = data;
                }
            });

            this.setState({ selected_privileges: selected_privileges, contact_disabled: this.props.privileges.contact_disabled });
        }

        if (prevProps.privileges.contact_disabled !== this.props.privileges.contact_disabled) {
            this.setState({ contact_disabled: this.props.privileges.contact_disabled });
        }
    }

    handleChange = (data, type) => {

        switch (type) {
            case 'privilege_details':

                let privilege_details = { ...this.state.privilege_details };

                privilege_details[data.field] = data.value;

                this.setState({ privilege_details: privilege_details });

                // this.setState(prevState => ({
                //     privilege_details: {                   // object that we want to update
                //         ...prevState.privilege_details,    // keep all other key-value pairs
                //         [data.field]: data.value     // update the value of specific key
                //     }
                // }));



                // check if user and privilege has been selected
                if (privilege_details.users && privilege_details.category) {

                    let selected_privilege_category = this.props.privileges.privileges.filter(item => item.id + '' === privilege_details.category.value + '');

                    this.setState({ selected_privilege_category: selected_privilege_category, selected_privileges: [] });

                    // go to server and load all contact privileges
                    this.props.serverGetContactPrivileges(privilege_details.users, this.props.auth.user.token)

                    // console.log("selected_privilege_category", selected_privilege_category);
                }

                

                break;

            default:
                break;
        }

        // console.log(data);
    }

    selectPrivilege = (data) => {

        let newState = { ...this.state.selected_privileges };

        if (newState[data.id]) {
            delete newState[data.id];
        } else {
            newState[data.id] = data;
        }

        this.setState({
            selected_privileges: newState
        });

        let results = Object.values(newState);

        // console.log(results);

        this.setState({ final_selected_privileges: results })
    }

    savePrivileges = () => {

        let obj = {
            user: this.state.privilege_details.users,
            privilege_category: this.state.privilege_details.category,
            selected_privileges: this.state.final_selected_privileges
        }

        this.props.serverSavePrivileges(obj, this.props.auth.user.token)
    }

    disableAccount = (state) => {
        let obj = {
            state: state,
            user: this.state.privilege_details.users
        }
        this.props.serverDisableAccount(obj, this.props.auth.user.token);
    }

    render() {
        return (
            <>
                <Main>
                    <Privileges
                        c_state={{ ...this.state }}
                        privileges={this.props.privileges}
                        auth={this.props.auth}
                        handleChange={this.handleChange}
                        select_privilege={this.selectPrivilege}
                        save_privileges={this.savePrivileges}
                        disable_account={this.disableAccount}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        privileges: { ...state.privileges },
        contacts: { ...state.contacts },
    }
}

export default connect(mapStateToProps, {
    // checkUser,
    serverLoad,
    serverGetContacts,
    serverGetPrivileges,
    serverSavePrivileges,
    serverGetContactPrivileges,
    serverDisableAccount
})(PrivilegesContainer)


