import React from 'react'
import { Link } from 'react-router-dom'
import types from '../../types';
import Select from 'react-select';

const TemplateDocs = (props) => {

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Documents</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Documents</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Documents Created From Templates</h3>
                                    <div align="right">
                                        <button type="button" className="btn btn-sm btn-outline-warning" onClick={props.reload}><i className="fa fa-sync"></i> Reload</button>&nbsp;
                                        <button type="button" className="btn btn-sm btn-outline-success" data-toggle="modal" data-target="#search_tag_modal"><i className="fa fa-filter"></i> Filter By Tags</button>&nbsp;
                                        <button type="button" className="btn btn-sm btn-outline-default">
                                            <div className="input-group input-group-sm" style={{ width: 200 }}>
                                                <input type="search" className="form-control form-control-sm" placeholder="Type your keywords here" onChange={(event) => props.search_docs(event.target.value)} />
                                                <div className="input-group-append">
                                                    <Link to="#" type="submit" disabled className="btn btn-sm btn-default">
                                                        <i className="fa fa-search"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                {
                                    props.templates.isLoading === "true" ?
                                        <div align="center">
                                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>We are loading your documents. Please wait...</p></span>
                                        </div>
                                        :
                                        <div className="card-body col-12 row" style={{ margin: 10 }}>
                                            {
                                                props.c_state.document_list ?
                                                    // eslint-disable-next-line array-callback-return
                                                    props.c_state.document_list.map((data) => {
                                                        return (
                                                            <div className="col-2" key={data.did}>
                                                                <div style={{ width: 150, height: 150 }}>
                                                                    <Link to="#" title="View Document" data-toggle="modal" onClick={() => props.edit_document(data)}><span className="mailbox-attachment-icon"><i className={data.ext === ".docx" ? "far fa-file-word" : "far fa-file"}></i></span></Link>

                                                                    <div className="mailbox-attachment-info">
                                                                        <Link to="#" className="mailbox-attachment-name" title="View Document" onClick={() => props.edit_document(data)}> {data.name}{data.ext}</Link>
                                                                        <span className="mailbox-attachment-size clearfix mt-1">
                                                                            {/* <span>1,245 KB</span> */}
                                                                            <Link to="#" className="btn btn-default btn-sm float-right" title="Upload to File Manager" onClick={() => props.upload_to_fm("documents", data.did)}><i className="fas fa-cloud-upload-alt"></i></Link>
                                                                            <a href={`${types.SERVER_URL}static/documents/${data.did}${data.ext}`} title="Download Document" target="download_document" className="btn btn-default btn-sm float-right"><i className="fas fa-download"></i></a>
                                                                            <button className="btn btn-default btn-sm float-right" title="Delete Document" onClick={() => window.confirm(`Are you sure you want to delete ${data.name} ?`) ? props.delete_document(data.did) : ''}><i className="fas fa-trash"></i></button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                    : null
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>


                    {/* modals will come here */}
                    <div className="modal fade" id="search_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Select Tags</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <Select
                                            options={props.c_state.tags_dropdown}
                                            name="tags"
                                            // value={props.c_state.contact_details.tags ? props.c_state.contact_details.tags : []}
                                            id="custom_select"
                                            value={typeof props.c_state.search_params === "string" ? JSON.parse(props.c_state.search_params.replace(/'/g, '"')) : props.c_state.search_params}
                                            onChange={(value) => props.handleChange(value, "search_params")}
                                            isMulti style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select" />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_search_params_modal">Close</button>
                                    <button type="button" className="btn btn-primary" onClick={props.search_document_by_tags}>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default TemplateDocs
