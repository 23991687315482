import React, { Component } from 'react'
import Contacts from './Contacts';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import {
    serverLoad, serverGetContacts, serverAddContact, serverEditContact, serverAddGroup,
    serverDeleteContact, serverGetCompanies, serverAddCompany, serverEditCompany,
    serverDeleteCompany, serverUploadContacts, serverGetGroups, serverEditGroup, serverDeleteGroup
} from '../../actions/ContactsAction';
import { serverGetTags } from '../../actions/TagsAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const $ = require("jquery");

class ContactsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact_details: {},
            company_details: {},
            group_details: {},
            groups_dropdown: [],
            contact_photo: null,
            show_modal: "true",
            contact_list: [],
            company_list: [],
            group_list: [],
            tags_dropdown: [],
            contacts_file: {},
            contacts_dropdown: []
        }

        this.deleteContact = this.deleteContact.bind(this);
        this.deleteCompany = this.deleteCompany.bind(this);
        this.addContact = this.addContact.bind(this);
        this.addCompany = this.addCompany.bind(this);
        this.editContact = this.editContact.bind(this);
        this.editCompany = this.editCompany.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.searchContactByTags = this.searchContactByTags.bind(this);
        this.doCompare = this.doCompare.bind(this);
        this.uploadContacts = this.uploadContacts.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.editGroup = this.editGroup.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
    }

    componentDidMount() {

        // console.log(this.props);

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(this.props.auth.user.token);
            this.props.serverGetCompanies(this.props.auth.user.token);
            this.props.serverGetGroups(this.props.auth.user.token);
        } else {
            this.setState({ contact_list: [...this.props.contacts.contacts] });
            this.setState({ company_list: [...this.props.contacts.companies] });
            this.setState({ group_list: [...this.props.contacts.groups] });
            this.setState({ contacts_file: {} });

            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                if (data.category === "Positional") {
                    let v = { "value": data.contact_id, "label": `${data.position}` };
                    arr.push(v);
                }
            });

            this.setState({ contacts_dropdown: arr });

            let arr2 = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.groups.filter((data, index) => {
                    let v = { "value": data.group_id, "label": `${data.group_name}` };
                    arr2.push(v);
            });

            this.setState({ groups_dropdown: arr2 });
        }

        this.props.serverGetTags(this.props.auth.user.token);
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.auth);

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.contacts !== this.props.contacts) {
            this.setState({ contact_list: [...this.props.contacts.contacts] });
            this.setState({ company_list: [...this.props.contacts.companies] });
            this.setState({ group_list: [...this.props.contacts.groups] });
            this.setState({ contacts_file: {} });

            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                if (data.category === "Positional") {
                    let v = { "value": data.contact_id, "label": `${data.position}` };
                    arr.push(v);
                }
            });

            this.setState({ contacts_dropdown: arr });

            let arr2 = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.groups.filter((data, index) => {
                    let v = { "value": data.group_id, "label": `${data.group_name}` };
                    arr2.push(v);
            });

            this.setState({ groups_dropdown: arr2 });

            $("#dismiss_add_company_modal").click();
            $("#dismiss_add_contact_modal").click();
            $("#dismiss_add_group_modal").click();
            $("#dismiss_edit_company_modal").click();
            $("#dismiss_edit_contact_modal").click();
            $("#dismiss_edit_group_modal").click();
            $("#dismiss_upload_contacts_modal").click();
        }

        if (prevProps.auth.clear_data !== this.props.auth.clear_data) {
            if (this.props.auth.clear_data === "true") {

                // $("#edit_contact_modal").hide();


                this.props.clearData("false");

                this.setState({ contact_details: {} })

                // console.log(this.state);
            }
        }
    }


    titleCase = (text) => {
        if (text) {
            let sentence = text.toLowerCase().split(" ");
            if (sentence) {
                for (let i = 0; i < sentence.length; i++) {
                    if (sentence[i])
                        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
                }
            }

            return sentence.join(" ");
        }
    }

    clearModalContents = () => {
        this.setState({ contact_details: {}, company_details: {}, group_details: {}, contact_photo: null });

        return;
    }


    reload = () => {
        this.props.serverGetContacts(this.props.auth.user.token);
        this.props.serverGetCompanies(this.props.auth.user.token);
        this.props.serverGetGroups(this.props.auth.user.token);

        // toast.success("Contact list has been reloaded successfully", {
        //     position: 'top-center',
        //     autoClose: 3000
        // });
    }


    deleteContact = (contact_id) => {
        this.props.serverDeleteContact(contact_id, this.props.auth.user.token);
    }


    deleteCompany = (company_id) => {
        this.props.serverDeleteCompany(company_id, this.props.auth.user.token);
    }


    setModalContent = (data, type) => {
        switch (type) {
            case 'contact':
                this.setState({
                    contact_details: data
                })
                break;
            case 'company':
                this.setState({
                    company_details: data
                });
                break;
            case 'group':
                this.setState({
                    group_details: data
                });
                break;
            default:
                break;
        }

        // console.log(data);
    }

    fixTags = (tags) => {

        if (typeof tags == "string") {
            let t = JSON.parse(tags.replace(/'/g, '"'))

            let labels = [];

            t.forEach(element => {
                labels.push(element.label);
            });

            return JSON.stringify(labels);
        }
    }

    handleChange = (data, type) => {
        switch (type) {
            case 'contact':
                this.setState(prevState => ({
                    contact_details: {                   // object that we want to update
                        ...prevState.contact_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));

                if (data.field === "photo") {
                    this.setState({ contact_photo: data.value[0] });
                    // console.log(data.value[0]);
                }

                if (data.field === "contacts_file") {
                    this.setState({ contacts_file: data.value });
                }

                break;
            case 'company':
                this.setState(prevState => ({
                    company_details: {                   // object that we want to update
                        ...prevState.company_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            case 'group':
                this.setState(prevState => ({
                    group_details: {                   // object that we want to update
                        ...prevState.group_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            default:
                break;
        }


        // console.log(this.state.contact_details);
    }


    addContact = () => {
        let contact_details = { ...this.state.contact_details }

        if (contact_details.category) {
            if (contact_details.category === "Positional") {
                if (!contact_details.position) {
                    toast.error("Please specify the position for this positional account", {
                        position: 'top-center',
                        autoClose: 3000
                    });

                    return;
                }

                contact_details['contact_type'] = "Public";

                if (contact_details.contact_type === "Group") {
                    contact_details['contact_type'] = "Group";
                }
                
                contact_details['first_name'] = contact_details.position;
                contact_details['last_name'] = "";
                contact_details['title'] = "";

            } else if (contact_details.category === "Personal") {
                if (!this.state.contact_details.first_name || !this.state.contact_details.last_name || !this.state.contact_details.title) {
                    toast.error("Please fill all required fields.", {
                        position: 'top-center',
                        autoClose: 3000
                    });

                    return;
                }
            }
        }

        if (contact_details.contact_type === "Group" && !contact_details.groups) {
            toast.error("Please select a group.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        // check entry
        if (!contact_details.company_name || !contact_details.email || !contact_details.phone ||
            !contact_details.address || !contact_details.contact_type || !contact_details.category) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        const formData = new FormData();

        if (this.state.contact_photo != null) {

            formData.append("photo", this.state.contact_photo);

        }

        formData.append("contact_details", JSON.stringify(contact_details))

        // console.log("formData", formData);

        this.props.serverAddContact(formData, this.props.auth.user.token)
    }

    addCompany = () => {

        // check entry
        if (!this.state.company_details.company_name || !this.state.company_details.short_name || !this.state.company_details.url || !this.state.company_details.is_internal ||
            !this.state.company_details.contact_person || !this.state.company_details.position || !this.state.company_details.phone_number ||
            !this.state.company_details.email || !this.state.company_details.country ||
            !this.state.company_details.city || !this.state.company_details.region || !this.state.company_details.address) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverAddCompany(this.state.company_details, this.props.auth.user.token)
    }

    editContact = () => {

        let contact_details = { ...this.state.contact_details }

        if (contact_details.category) {
            if (contact_details.category === "Positional") {
                if (!contact_details.position) {
                    toast.error("Please specify the position for this positional account", {
                        position: 'top-center',
                        autoClose: 3000
                    });

                    return;
                }
                contact_details['contact_type'] = "Public";

                if (contact_details.contact_type === "Group") {
                    contact_details['contact_type'] = "Group";
                }
                contact_details['first_name'] = contact_details.position;
                contact_details['last_name'] = "";
                contact_details['title'] = "";

            } else if (contact_details.category === "Personal") {
                if (!this.state.contact_details.first_name || !this.state.contact_details.last_name || !this.state.contact_details.title) {
                    toast.error("Please fill all required fields.", {
                        position: 'top-center',
                        autoClose: 3000
                    });

                    return;
                }
            }
        }

        if (contact_details.contact_type === "Group" && !contact_details.groups) {
            toast.error("Please select a group.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        // check entry
        if (!contact_details.company_name || !contact_details.email || !contact_details.phone ||
            !contact_details.address || !contact_details.contact_type || !contact_details.category) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        const formData = new FormData();

        if (this.state.contact_photo != null) {

            formData.append("photo", this.state.contact_photo);

        }

        formData.append("contact_details", JSON.stringify(contact_details))

        this.props.serverEditContact(formData, this.props.auth.user.token)
    }

    editCompany = () => {
        // console.log(this.state.company_details);
        // check entry
        if (!this.state.company_details.name || !this.state.company_details.short_name || !this.state.company_details.url || !this.state.company_details.is_internal ||
            !this.state.company_details.contact_person || !this.state.company_details.position || !this.state.company_details.phone_number ||
            !this.state.company_details.email || !this.state.company_details.phone_number || !this.state.company_details.country ||
            !this.state.company_details.city || !this.state.company_details.region || !this.state.company_details.address) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverEditCompany(this.state.company_details, this.props.auth.user.token)
    }

    searchContact = (value) => {
        // eslint-disable-next-line array-callback-return
        let new_contacts = this.props.contacts.contacts.filter((data, index) => {
            let keys = Object.keys(data);
            let valid = false;
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (typeof data[key] !== "string") {
                    continue;
                }

                if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    valid = true;
                    break;
                }
            }

            if (valid) {
                return data;
            }
        });

        this.setState({ contact_list: new_contacts });
    }

    doCompare = (selected_tags, contacts) => {
        let label = {};
        let value = {};
        let results = [];

        for (let index = 0; index < selected_tags.length; index++) {
            const element = selected_tags[index];

            label[element.label] = true;
            value[element.value] = true;

        }

        for (let i = 0; i < contacts.length; i++) {
            const el = contacts[i];
            let isValid = false
            if (el.tags) {
                let tags = JSON.parse(el.tags.replace(/'/g, '"'));

                // check if all selected tags exist in contacts
                //  let myValues = {...value}

                for (let ind = 0; ind < tags.length; ind++) {
                    const elt = tags[ind];
                    if (value[elt.value]) {
                        isValid = true;
                        break;
                    }

                    // check if all selected tags exist in contacts
                    // if (myValues[elt.value]) {
                    //     delete myValues[elt.value]
                    // }
                }
            }

            if (isValid) {
                results.push(el);
            }

            // check if all selected tags exist in contacts
            // if (!Object.keys(myValues).length) {
            //     results.push({"value": el.contact_id, "label": `${el.first_name} ${el.last_name}`});
            // }
        }

        return results;
    }

    searchContactByTags = () => {
        if (this.state.contact_details.search_params) {
            let new_contacts = this.doCompare(this.state.contact_details.search_params, this.props.contacts.contacts);
            this.setState({ contact_list: new_contacts });
            $("#dismiss_search_params_modal").click();
            // console.log(new_contacts);
        }
    }

    uploadContacts = () => {

        // Create an object of formData
        const formData = new FormData();

        formData.append("contacts_file", this.state.contacts_file);

        this.props.serverUploadContacts(formData, this.props.auth.user.token);
    }

    addGroup = () => {
        if (!this.state.group_details.group_name) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return
        }

        this.props.serverAddGroup(this.state.group_details, this.props.auth.user.token);
    }

    editGroup = () => {
        if (!this.state.group_details.group_name) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return
        }

        this.props.serverEditGroup(this.state.group_details, this.props.auth.user.token);
    }

    deleteGroup = (group_id) => {
        this.props.serverDeleteGroup(group_id, this.props.auth.user.token);
    }

    render() {
        return (
            <>
                <Main>
                    <Contacts
                        c_state={{ ...this.state }}
                        auth={this.props.auth}
                        search_contact={this.searchContact}
                        search_contact_by_tags={this.searchContactByTags}
                        reload={this.reload}
                        titleCase={this.titleCase}
                        deleteContact={this.deleteContact}
                        deleteCompany={this.deleteCompany}
                        setModalContent={this.setModalContent}
                        fixTags={this.fixTags}
                        add_contact={this.addContact}
                        add_company={this.addCompany}
                        edit_contact={this.editContact}
                        edit_company={this.editCompany}
                        handleChange={this.handleChange}
                        isLoading={this.props.contacts.isLoading}
                        clear_modal_contents={this.clearModalContents}
                        upload_contacts={this.uploadContacts}
                        add_group={this.addGroup}
                        edit_group={this.editGroup}
                        deleteGroup={this.deleteGroup}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        contacts: { ...state.contacts },
        dashboard: { ...state.dashboard },
        tags: { ...state.tags }
    }
}

export default connect(mapStateToProps,
    {
        // checkUser,
        serverLoad,
        clearData,
        serverGetTags,
        serverGetContacts,
        serverGetCompanies,
        serverGetGroups,
        serverAddContact,
        serverEditContact,
        serverDeleteContact,
        serverDeleteCompany,
        serverAddCompany,
        serverEditCompany,
        serverUploadContacts,
        serverAddGroup,
        serverEditGroup,
        serverDeleteGroup
    }
)(ContactsContainer)


