import types from '../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.PROFILE_LOADING,
            payload: action
        })
    }
}

export const serverEditProfile = (data, token) => {
    return (dispatch) => {

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'edit_profile',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.PROFILE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.PROFILE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                })
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.PROFILE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.LOADING,
                payload: "false"
            })
            return
        })
    }
}

export const serverGetUserActivity = (token) => {
    return (dispatch) => {

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {}

        axios({
            headers,
            url: 'get_user_activity',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.PROFILE_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.PROFILE_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                })
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.PROFILE_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.LOADING,
                payload: "false"
            })
            return
        })
    }
}