import React, { Component } from 'react'
import Task from './Task';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverLoad, serverGetTasks, serverDeleteTask, serverAddTask, serverEditTask, serverChangeTaskState } from '../../actions/TasksAction';
import { serverSaveAttachments, serverClearSavedAttachments, serverGetAllFiles } from '../../actions/AttachmentsAction';
import { serverGetDocuments, serverGetTemplates, serverUseTemplate } from '../../actions/TemplatesAction';
import { serverGetTags } from '../../actions/TagsAction';
import { serverGetContacts } from '../../actions/ContactsAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fileDownload from 'js-file-download';
import axios from 'axios'
import types from '../../types';

const $ = require("jquery");

class TasksContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            task_details: [],
            task_info: {},
            task_list: [],
            task_logs: [],
            tags_dropdown: [],
            contact_list: [],
            reminders: [{ "r_date": "", "r_time": "" }],
            selected_attachments: [],

            // for generic attachments modal
            a_type: "tasks",
            document_config: {},
            my_computer: [],
            file_list: [],
            document_list: [],
            template_list: [],
            breadcrumbs: [],
            my_computer_selected: [],
            my_documents_selected: [],
            files_selected: [],
            selected_items: {},
            selected_files: {},
            selected_template: [],
            selected_template_details: [],
            saved_attachments: [],
            answers: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.removeReminder = this.removeReminder.bind(this);
        this.addReminder = this.addReminder.bind(this);
        this.setReminderDate = this.setReminderDate.bind(this);
        this.setReminderTime = this.setReminderTime.bind(this);
        this.addTask = this.addTask.bind(this);
        this.deleteTask = this.deleteTask.bind(this);
        this.editTask = this.editTask.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.setModalContentAlt = this.setModalContentAlt.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.reload = this.reload.bind(this);
        this.changeTaskState = this.changeTaskState.bind(this)
        this.searchTaskByTags = this.searchTaskByTags.bind(this);
        this.doCompare = this.doCompare.bind(this);
        this.searchTaskByUsers = this.searchTaskByUsers.bind(this);
        this.selectedAttachments = this.selectedAttachments.bind(this);
        this.taskLogs = this.taskLogs.bind(this);

        // attachments
        this.setAttachmentInfo = this.setAttachmentInfo.bind(this);
        this.useTemplate = this.useTemplate.bind(this);
        this.selectMyDocument = this.selectMyDocument.bind(this);
        this.selectTemplate = this.selectTemplate.bind(this);
        this.clearAttachments = this.clearAttachments.bind(this);
        this.saveAttachments = this.saveAttachments.bind(this);
        this.clearAttachmentInfo = this.clearAttachmentInfo.bind(this);
        this.fileType = this.fileType.bind(this);
        this.download = this.download.bind(this);
        this.searchAttachment = this.searchAttachment.bind(this);
        this.enterFolder = this.enterFolder.bind(this);
        this.enterFolderAlt = this.enterFolderAlt.bind(this);
        this.clickBreadCrumbs = this.clickBreadCrumbs.bind(this);
    }

    componentDidMount() {

        // console.log(this.props);

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.tasks.tasks.length) {
            this.props.serverGetTasks(this.props.auth.user.token);
        } else {
            this.setState({ task_list: [...this.props.tasks.tasks] });
        }

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (!this.props.templates.documents.length) {
            this.props.serverGetDocuments(this.props.auth.user.token);
        } else {
            this.setState({ document_list: [...this.props.templates.documents] });
        }

        if (!this.props.templates.templates.length) {
            this.props.serverGetTemplates(this.props.auth.user.token);
        } else {
            this.setState({ template_list: [...this.props.templates.templates] });
        }

        if (!this.props.attachments.all_files.length) {
            this.props.serverGetAllFiles(this.props.auth.user.token);
        } else {
            if (!this.state.breadcrumbs.length) {

                let parent = this.props.attachments.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if (parent.length) {
                    file_list = this.props.attachments.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
            }
        }

        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(this.props.auth.user.token);
        } else {
            // let arr = [{ "value": "Myself", "label": "Myself" }]
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company_name})` };
                arr.push(v);
            });

            this.setState({ contact_list: arr });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.tasks !== this.props.tasks) {

            this.setState({ task_list: [...this.props.tasks.tasks] });
        }

        if (prevProps.contacts !== this.props.contacts) {
            // let arr = [{ "value": this.props.auth.user.contact_id, "label": "Myself" }]
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company_name})` };
                arr.push(v);
            });

            this.setState({ contact_list: arr });
        }

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.tasks.tasks !== this.props.tasks.tasks) {
            $("#dismiss_edit_task_modal").click();
            $("#dismiss_add_task_modal").click();

            this.clearModalContents();
            this.clearAttachments();
        }

        if (prevProps.templates.use_docs_config !== this.props.templates.use_docs_config) {
            if (this.props.templates.use_docs_config.document) {
                $("#dismiss_use_template_modal").click();
                $("#btn-show-doc-modal").click();

                this.setState({ selected_template_details: [], selected_template: [] });
            }
        }

        // generic attachments
        if (prevProps.templates.use_docs_config !== this.props.templates.use_docs_config) {
            if (this.props.templates.use_docs_config.document) {
                $("#dismiss_use_template_modal").click();
                $("#btn-show-doc-modal").click();

                this.setState({ selected_template_details: [], selected_template: [] });
            }
        }

        if (prevProps.attachments.all_files !== this.props.attachments.all_files) {

            if (!this.state.breadcrumbs.length) {

                let parent = this.props.attachments.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if (parent.length) {
                    file_list = this.props.attachments.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
            }
        }

        if (prevProps.templates.documents !== this.props.templates.documents) {
            this.setState({ document_list: [...this.props.templates.documents] });
        }

        if (prevProps.templates.templates !== this.props.templates.templates) {
            this.setState({ template_list: [...this.props.templates.templates] });
        }
    }

    clickBreadCrumbs = (index) => {
        let breadcrumbs = [...this.state.breadcrumbs];
        let selected_breadcrumbs = this.state.breadcrumbs[index];
        let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === selected_breadcrumbs.fid + '');
        breadcrumbs = breadcrumbs.slice(0, index + 1);
        return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
    }

    handleChange = (data, type) => {

        switch (type) {
            case 'task':
                this.setState(prevState => ({
                    task_details: {                   // object that we want to update
                        ...prevState.task_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            // generic attachments modal
            case 'answers':
                this.setState(prevState => ({
                    answers: {                   // object that we want to update
                        ...prevState.answers,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            // generic attachments modal
            case 'template':
                this.setState(prevState => ({
                    selected_template_details: {                   // object that we want to update
                        ...prevState.selected_template_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            // generic attachments modal
            case 'my_computer':
                this.setState({ my_computer: data });

                let attachments = [];

                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    attachments.push({
                        "file_name": element.name,
                        "file_size": element.size
                    });
                }

                this.setState({ my_computer_selected: attachments });

                break;
            default:
                break;
        }

        if (data.field === 'attachments') {

            this.setState(prevState => ({
                email_details: {                   // object that we want to update
                    ...prevState.email_details,    // keep all other key-value pairs
                    "attachments": []     // update the value of specific key
                }
            }));

            let attachments = [];

            for (let index = 0; index < data.value.length; index++) {
                const element = data.value[index];
                attachments.push({
                    "file_name": element.name,
                    "file_size": element.size
                });
            }

            this.setState(prevState => ({
                email_details: {                   // object that we want to update
                    ...prevState.email_details,    // keep all other key-value pairs
                    "attachments": attachments     // update the value of specific key
                }
            }));
        }
    }

    setModalContent = (data) => {

        this.setState({
            task_details: data.task,
            reminders: data.reminders
        });

        return;
    }


    setModalContentAlt = (data) => {
        this.setState({task_info: data});
    }

    clearModalContents = () => {
        this.setState({ task_details: {}, reminders: [{ "r_date": "", "r_time": "" }] });
        this.clearAttachments();
        return;
    }

    removeReminder = (marker) => {
        // eslint-disable-next-line array-callback-return
        let reminders = this.state.reminders.filter((data, index) => { // loop through state.todo
            if (index !== marker) { // check what data to update using key
                return data
            }
        });

        this.setState({ reminders: reminders });

        return;
    }

    addReminder = () => {

        let valid = true;

        // console.log(this.state.reminders);

        this.state.reminders.forEach(element => {
            if (element.r_date === "" || element.r_time === "") {

                valid = false;

                return;
            }
        });

        if (valid === true) {
            this.setState({ reminders: [...this.state.reminders, { "r_date": "", "r_time": "" }] });
        } else {
            toast.info("Please set reminder date and time before you add a new one", {
                position: 'top-center'
            });
        }
    }

    setReminderDate = (date, marker) => {
        // eslint-disable-next-line array-callback-return
        let reminders = this.state.reminders.filter((data, index) => { // loop through state.todo
            if (index === marker) { // check what data to update using key
                data.r_date = date;
            }

            return data
        });

        this.setState({ reminders: reminders });
    }

    setReminderTime = (time, marker) => {
        // eslint-disable-next-line array-callback-return
        let reminders = this.state.reminders.filter((data, index) => { // loop through state.todo
            if (index === marker) { // check what data to update using key
                data.r_time = time;
            }

            return data
        });

        this.setState({ reminders: reminders });
    }

    addTask = () => {
        // check entry
        if (!this.state.task_details.deadline_date || !this.state.task_details.deadline_time || !this.state.task_details.priority
            || !this.state.task_details.recipients || !this.state.task_details.text || !this.state.task_details.note) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            // console.log("required", this.state);

            return;
        }

        // Create an object of formData
        const formData = new FormData();

        if (this.state.my_computer.length) {

            for (let index = 0; index < this.state.my_computer.length; index++) {
                const element = this.state.my_computer[index];
                formData.append("my_computer[]", element);
            }
        }

        if (this.state.my_documents_selected.length) {
            formData.append("my_documents", JSON.stringify(this.state.my_documents_selected));
        }

        if (this.state.files_selected.length) {
            formData.append("files", JSON.stringify(this.state.files_selected));
        }

        formData.append("type", "tasks");

        formData.append("task_details", JSON.stringify(this.state.task_details));

        formData.append("reminders", JSON.stringify(this.state.reminders));

        this.props.serverAddTask(formData, this.props.auth.user.token)
    }

    deleteTask = (task_id) => {
        this.props.serverLoad("true");
        this.props.serverDeleteTask(task_id, this.props.auth.user.token);
    }

    editTask = () => {
        // check entry
        if (!this.state.task_details.deadline_date || !this.state.task_details.deadline_time || !this.state.task_details.priority
            || !this.state.task_details.recipients || !this.state.task_details.text || !this.state.task_details.note) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        let vars = { ...this.state };

        if (typeof vars.task_details.recipients === "string") {

            vars.task_details.recipients = JSON.parse(vars.task_details.recipients.replace(/'/g, '"'));
        }

        // Create an object of formData
        const formData = new FormData();

        if (this.state.my_computer.length) {

            for (let index = 0; index < this.state.my_computer.length; index++) {
                const element = this.state.my_computer[index];
                formData.append("my_computer[]", element);
            }
        }

        if (this.state.my_documents_selected.length) {
            formData.append("my_documents", JSON.stringify(this.state.my_documents_selected));
        }

        if (this.state.files_selected.length) {
            formData.append("files", JSON.stringify(this.state.files_selected));
        }

        formData.append("type", "tasks");

        formData.append("task_details", JSON.stringify(vars.task_details));

        formData.append("reminders", JSON.stringify(vars.reminders));

        this.props.serverEditTask(formData, this.props.auth.user.token)

    }

    reload = () => {
        this.props.serverGetTasks(this.props.auth.user.token);

        toast.success("Your tasks have been reloaded successfully", {
            position: 'top-center',
            autoClose: 3000
        });

        this.setState({ task_details: {} });
    }

    searchTasks = (value) => {
        // console.log(this.props.tasks);
        // eslint-disable-next-line array-callback-return
        let new_tasks = this.props.tasks.tasks.filter((data, index) => { // loop through state.todo
            let keys = Object.keys(data.task);
            let valid = false;
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (typeof data.task[key] !== "string") {
                    continue;
                }

                if (data.task[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    valid = true;
                    break;
                }
            }

            if (valid) {
                return data;
            }
        });

        this.setState({ task_list: new_tasks });
    }

    changeTaskState = (state, task_id) => {
        this.props.serverChangeTaskState(state, task_id, this.props.auth.user.token);
    }

    getContactName = (contact_id) => {

        let conts = [...this.state.contact_list];

        var contact = conts.filter(item => (item.value === contact_id));

        if (contact[0]) {
            return contact[0].label;
        }

        return contact_id;
    }

    doCompare = (selected_tags, contacts) => {
        // console.log(selected_tags, contacts);
        let label = {};
        let value = {};
        let results = [];

        for (let index = 0; index < selected_tags.length; index++) {
            const element = selected_tags[index];

            label[element.label] = true;
            value[element.value] = true;

        }

        for (let i = 0; i < contacts.length; i++) {
            const el = contacts[i];
            let isValid = false
            if (el.task.tags) {
                let tags = JSON.parse(el.task.tags.replace(/'/g, '"'));

                // check if all selected tags exist in contacts
                //  let myValues = {...value}

                for (let ind = 0; ind < tags.length; ind++) {
                    const elt = tags[ind];
                    if (value[elt.value]) {
                        isValid = true;
                        break;
                    }

                    // check if all selected tags exist in contacts
                    // if (myValues[elt.value]) {
                    //     delete myValues[elt.value]
                    // }
                }
            }

            if (isValid) {
                results.push(el);
            }

            // check if all selected tags exist in contacts
            // if (!Object.keys(myValues).length) {
            //     results.push({"value": el.contact_id, "label": `${el.first_name} ${el.last_name}`});
            // }
        }

        return results;
    }

    searchTaskByTags = () => {
        if (this.state.task_details.search_params) {
            let new_tasks = this.doCompare(this.state.task_details.search_params, this.props.tasks.tasks);
            this.setState({ task_list: new_tasks });
            $("#dismiss_search_params_modal").click();
            // console.log(new_tasks);
        }
    }

    searchTaskByUsers = () => {
        let task_details = { ...this.state.task_details }

        // console.log(task_details.search_users);

        if (!task_details.search_category || !task_details.search_users) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return
        }

        let search_users = []
        task_details.search_users.forEach(element => {
            search_users.push(element.value);
        });

        let task_list = [];

        switch (task_details.search_category) {
            case 'From':
                // eslint-disable-next-line array-callback-return
                task_list = this.props.tasks.tasks.filter((item) => search_users.includes(item.task.user));
                break;
            case 'To':
                task_list = this.props.tasks.tasks.filter((item) => search_users.includes(item.task.recipient));
                // eslint-disable-next-line array-callback-return
                // task_list = this.props.tasks.tasks.filter((item, index) => {
                //     if (item.task.recipients) {
                //         let people = JSON.parse(item.task.recipients.replace(/'/g, '"'));
                //         people.forEach(el => {
                //             if (search_users.includes(el.value)) {
                //                 return item;
                //             }
                //         });
                //     }
                // });
                break;
            default:
                break;
        }

        this.setState({ task_list: task_list });

        $("#dismiss_search_recipients_modal").click();

    }

    clearAttachments = () => {
        this.setState({ my_documents_selected: [], files_selected: [], my_computer_selected: [], selected_items: {}, selected_files: {}, my_computer: [] });
    }

    saveAttachments = () => {
        if (!this.state.my_computer_selected.length && !this.state.my_documents_selected.length && !this.state.files_selected.length) {

            toast.error("Please select files to add as attachments", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        $("#dismiss_attachment_modal").click();
    }

    setAttachmentInfo = (data, type) => {

        let id = "";
        let file_name = "";
        let folder = "";
        let ext = "";

        switch (type) {
            case 'attachment':
                id = data.aid;
                file_name = data.file_name;
                folder = "attachments";
                ext = data.ext;
                break;
            case 'document':
                id = data.did;
                file_name = data.name;
                folder = "documents";
                ext = data.ext;
                break;
            case 'file':
                id = data.fid;
                file_name = data.filename;
                folder = "file_manager/files";
                ext = data.filetype;
                break;
            case 'template':
                id = data.tid;
                file_name = data.template_name;
                folder = "templates";
                ext = data.ext;
                break;
            default:
                break;
        }

        if (ext === ".docx" || ext === ".pptx" || ext === ".xlsx" || ext === ".csv") {

            let kind = "";
            if (ext === '.docx') {
                kind = "word";
            } else if (ext === '.pptx') {
                kind = "slide";
            } else if (ext === '.xlsx' || ext === ".csv") {
                kind = "cell";
            }

            let config = {
                "document": {
                    "fileType": ext.replace('.', ''),
                    "permissions": {
                        "download": false,
                        "edit": false
                    },
                    "key": Math.floor(Math.random() * 999999999999) + "",
                    "title": file_name,
                    "url": `${types.SERVER_URL}static/${folder}/${id.toString()}${ext}`,
                    "user": {
                        "id": this.props.auth.user.contact_id,
                        "name": `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`
                    },
                },
                "documentType": kind,
                "editorConfig": {
                    "callbackUrl": `${types.SERVER_URL}documentserver_callback?file=${folder}:${id.toString()}${ext}`,
                    "customization": {
                        "forcesave": true
                    }
                }
            }

            this.setState({ document_config: config });
        }

        this.setState({ view_attachment_data: { "id": id, "file_name": file_name, "folder": folder, "ext": ext } })
    }

    selectMyDocument = (data) => {

        let newState = { ...this.state.selected_items };

        if (newState[data.did]) {
            delete newState[data.did];
        } else {
            newState[data.did] = data;
        }

        this.setState({
            selected_items: newState
        });

        let results = Object.values(newState);

        // console.log(results);

        this.setState({ my_documents_selected: results })
    }

    selectFiles = (data) => {

        let newState = { ...this.state.selected_files };

        if (newState[data.fid]) {
            delete newState[data.fid];
        } else {
            newState[data.fid] = data;
        }

        this.setState({
            selected_files: newState
        });

        let results = Object.values(newState);

        // console.log(results);

        this.setState({ files_selected: results })
    }

    enterFolder = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === data.fid + '');

            let breadcrumbs = [...this.state.breadcrumbs, data];

            this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    enterFolderAlt = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === data.fid + '');
            this.setState({ file_list: file_list });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    selectTemplate = (data) => {
        this.setState({ selected_template: data });
    }

    useTemplate = () => {
        // console.log("stemp", this.state.selected_template_details);
        // console.log("answers", this.state.answers);

        if (!this.state.selected_template_details.document_name || !this.state.answers) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverLoad("true");
        this.props.serverUseTemplate(
            this.state.selected_template.templates.tid,
            this.state.selected_template_details.document_name,
            this.state.selected_template_details.tags ? this.state.selected_template_details.tags : [],
            this.state.answers,
            this.props.auth.user.token
        );
    }

    searchAttachment = (value, type) => {
        switch (type) {
            case 'documents':
                // eslint-disable-next-line array-callback-return
                let document_list = this.props.templates.documents.filter((data, index) => {
                    if (data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return data;
                    }
                })
                this.setState({ document_list: document_list });
                break;
            case 'files':
                if (value) {
                    // eslint-disable-next-line array-callback-return
                    let file_list = this.props.attachments.all_files.filter((data, index) => {
                        if (data.filename.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                            return data;
                        }
                    })
                    this.setState({ file_list: file_list });
                } else {
                    let file_list = this.state.breadcrumbs[this.state.breadcrumbs.length - 1];
                    if (typeof file_list !== 'undefined') {
                        this.enterFolderAlt(file_list);
                    }
                }
                break;
            case 'templates':
                // eslint-disable-next-line array-callback-return
                let template_list = this.props.templates.templates.filter((data, index) => {
                    if (data.templates.template_name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return data;
                    }
                })
                this.setState({ template_list: template_list });
                break;
            default:
                break;
        }
    }

    clearAttachmentInfo = () => {
        this.setState({ view_attachment_data: {} });
    }

    fileType = (type) => {

        var image_type_list = ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi', '.raw', '.arw', '.cr2', '.nrw', '.k25', '.png', '.gif', '.webp', '.tiff', '.tif', '.bmp', '.dib', '.jp2', '.j2k', '.jpf', '.jpx', '.jpm', '.mj2',
            '.svg', '.svgz', '.ai', '.eps', '.ico'
        ];

        var video_type_list = ['.mp4', '.m4a', '.m4v', '.f4v', '.f4a', '.m4b', '.m4r', '.f4b', '.mov', '.3gp', '.3gp2', '.3g2', '.3gpp', '.3gpp2',
            '.ogg', '.oga', '.ogv', '.ogx', '.webm', '.avi', '.flv', '.mpg', '.wmv', '.mkv'
        ];

        var audio_type_list = ['.mid', '.mp3', '.m4a', '.flac', '.wav', '.amr'];

        var compressed_type_list = ['.001', '.7z', '.arj', '.bin', '.bzip', '.bzip2', '.cab', '.cpio', '.deb', '.ear', '.gz', '.hqx', '.jar', '.lha',
            '.rar', '.rpm', '.sea', '.sit', '.tar', '.war', '.zip', '.epub', '.bz2'
        ];

        var doc_type_list = ['.txt', '.php', '.py', '.html', '.css', '.js', '.sql'];


        if (image_type_list.includes(type)) {

            return 'image';

        } else if (video_type_list.includes(type)) {

            return 'video';

        } else if (audio_type_list.includes(type)) {

            return 'audio';

        } else if (compressed_type_list.includes(type)) {

            return 'zip';

        } else if (doc_type_list.includes(type)) {

            return 'docs';

        } else {

            return '';
        }
    }

    download = async (folder, id, ext, file_name) => {

        axios.get(`${types.SERVER_URL}static/${folder}/${id}${ext}`, {
            responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, `${file_name}${ext}`);
        });
    }

    selectedAttachments = (attachments) => {
        this.setState({selected_attachments: attachments})
    }

    taskLogs = (task_logs) => {
        this.setState({task_logs: task_logs});
    }

    render() {
        return (
            <>
                <Main>
                    <Task
                        c_state={{ ...this.state }}
                        isLoading={this.props.tasks.isLoading}
                        auth={this.props.auth}
                        handleChange={this.handleChange}
                        remove_reminder={this.removeReminder}
                        add_reminder={this.addReminder}
                        set_reminder_date={this.setReminderDate}
                        set_reminder_time={this.setReminderTime}
                        add_task={this.addTask}
                        delete_task={this.deleteTask}
                        edit_task={this.editTask}
                        set_modal_content={this.setModalContent}
                        set_modal_content_alt={this.setModalContentAlt}
                        clear_modal_contents={this.clearModalContents}
                        reload={this.reload}
                        search_tasks={this.searchTasks}
                        change_task_state={this.changeTaskState}
                        getContactName={this.getContactName}
                        search_task_by_tags={this.searchTaskByTags}
                        search_task_by_users={this.searchTaskByUsers}
                        selected_attachments={this.selectedAttachments}
                        task_logs={this.taskLogs}

                        // attachments component
                        templates={this.props.templates}
                        attachments={this.props.attachments}
                        select_my_document={this.selectMyDocument}
                        select_files={this.selectFiles}
                        enter_folder={this.enterFolder}
                        click_breadcrumbs={this.clickBreadCrumbs}
                        select_template={this.selectTemplate}
                        use_template={this.useTemplate}
                        save_attachments={this.saveAttachments}
                        clear_attachments={this.clearAttachments}
                        search_attachment={this.searchAttachment}
                        // view attachments
                        set_attachment_info={this.setAttachmentInfo}
                        clear_attachment_info={this.clearAttachmentInfo}
                        file_type={this.fileType}
                        download={this.download}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tasks: { ...state.tasks },
        tags: { ...state.tags },
        contacts: { ...state.contacts },
        templates: { ...state.templates },
        attachments: { ...state.attachments }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        clearData,
        // checkUser,
        serverGetTags,
        serverGetContacts,
        serverGetTasks,
        serverAddTask,
        serverEditTask,
        serverDeleteTask,
        serverChangeTaskState,
        serverUseTemplate,
        serverGetDocuments,
        serverGetTemplates,
        serverSaveAttachments,
        serverClearSavedAttachments,
        serverGetAllFiles
    }
)(TasksContainer)
