import { Link } from 'react-router-dom';
import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="main-footer">
                <div className="float-right d-none d-sm-block">
                    <b>Version</b> 1.0.0
			</div>
                {/* <strong>Copyright &copy; 2014-2021 <a href="https://adminlte.io">AdminLTE.io</a>.</strong> All rights
			reserved. */}
            <strong>Copyright &copy; 2021 <Link to="#">iCubicle</Link>.</strong> All rights
			reserved.
		</footer>

            {/* <!-- Control Sidebar --> */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* <!-- Control sidebar content goes here --> */}
            </aside>
            {/* <!-- /.control-sidebar -->   */}
        </>
    )
}

export default Footer
