import React, { Component } from 'react'
import ViewRoutes from './ViewRoutes';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverGetTags } from '../../actions/TagsAction';
import { serverLoad, serverKeepRoute, serverGetRoutes, serverDeleteRoute, serverKeepEditRoute } from '../../actions/RoutesAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const $ = require("jquery");

class ViewRoutesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            route_details: [],
            route_list: [],
            single_route: [],
            tags_dropdown: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.newRoute = this.newRoute.bind(this);
        this.deleteRoute = this.deleteRoute.bind(this);
        this.editRoute = this.editRoute.bind(this);
        this.searchRoutes = this.searchRoutes.bind(this);
        this.doCompare = this.doCompare.bind(this);
        this.searchRouteByTags = this.searchRouteByTags.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        // if (!this.props.routes.all_routes.length) {
            this.props.serverGetRoutes(this.props.auth.user.token);
        // } else {
        //     this.setState({ route_list: [...this.props.routes.all_routes] });
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.auth);

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.routes.all_routes !== this.props.routes.all_routes) {

            this.setState({ route_list: [...this.props.routes.all_routes] });
        }

        if (prevProps.routes.single_route !== this.props.routes.single_route) {

            // this.setState({ single_route: [...this.props.routes.single_route] });

            $("#dismiss_new_route_modal").click();

            this.props.history.push("/new_route_steps");
        }

        if (prevProps.auth.clear_data !== this.props.auth.clear_data) {
            if (this.props.auth.clear_data === "true") {


                this.clearModalContents();

                this.props.clearData("false");
            }
        }

        if (prevProps.routes.edit_routes !== this.props.routes.edit_routes) {

            this.props.history.push("/edit_route");
        }

    }

    handleChange = (data) => {
        this.setState(prevState => ({
            route_details: {                   // object that we want to update
                ...prevState.route_details,    // keep all other key-value pairs
                [data.field]: data.value     // update the value of specific key
            }
        }));
    }

    setModalContent = (data) => {
        this.setState({
            route_details: data
        });
    }

    // searchTag = (value) => {
    //     // eslint-disable-next-line array-callback-return
    //     let new_tags = this.props.tags.tags.filter((data, index) => { // loop through state.todo
    //         let keys = Object.keys(data);
    //         let valid = false;
    //         for (let i = 0; i < keys.length; i++) {
    //             let key = keys[i];

    //             if (typeof data[key] !== "string") {
    //                 continue;
    //             }

    //             if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
    //                 valid = true;
    //                 break;
    //             }
    //         }

    //         if (valid) {
    //             return data;
    //         }
    //     });

    //     this.setState({ tag_list: new_tags });
    // }

    clearModalContents = () => {
        this.setState({ route_details: [] });

        return;
    }

    newRoute = () => {

        if (!this.state.route_details.title || !this.state.route_details.category) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverKeepRoute(this.state.route_details);
    }

    deleteRoute = (route_id) => {
        this.props.serverDeleteRoute(route_id, this.props.auth.user.token);
    }

    editRoute = (data) => {
        this.props.serverKeepEditRoute(data);
    }

    searchRoutes = (value) => {
        // eslint-disable-next-line array-callback-return
        let new_routes = this.props.routes.all_routes.filter((data, index) => {
            let keys = Object.keys(data);
            let valid = false;
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (typeof data[key] !== "string") {
                    continue;
                }

                if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    valid = true;
                    break;
                }
            }

            if (valid) {
                return data;
            }
        });

        this.setState({ route_list: new_routes });
    }

    doCompare = (selected_tags, contacts) => {
        let label = {};
        let value = {};
        let results = [];

        for (let index = 0; index < selected_tags.length; index++) {
            const element = selected_tags[index];

            label[element.label] = true;
            value[element.value] = true;

        }

        for (let i = 0; i < contacts.length; i++) {
            const el = contacts[i];
            let isValid = false
            if (el.tags) {
                let tags = JSON.parse(el.tags.replace(/'/g, '"'));

                // check if all selected tags exist in contacts
                //  let myValues = {...value}

                for (let ind = 0; ind < tags.length; ind++) {
                    const elt = tags[ind];
                    if (value[elt.value]) {
                        isValid = true;
                        break;
                    }

                    // check if all selected tags exist in contacts
                    // if (myValues[elt.value]) {
                    //     delete myValues[elt.value]
                    // }
                }
            }

            if (isValid) {
                results.push(el);
            }

            // check if all selected tags exist in contacts
            // if (!Object.keys(myValues).length) {
            //     results.push({"value": el.contact_id, "label": `${el.first_name} ${el.last_name}`});
            // }
        }

        return results;
    }


    searchRouteByTags = () => {
        if (this.state.route_details.search_params) {
            let new_routes = this.doCompare(this.state.route_details.search_params, this.props.routes.all_routes);
            this.setState({ route_list: new_routes });
            $("#dismiss_search_params_modal").click();
        }
    }

    reload = () => {
        this.props.serverGetTags(this.props.auth.user.token);
        this.props.serverGetRoutes(this.props.auth.user.token);

        toast.success("Routes reloaded successfully", {
            position: 'top-center',
            autoClose: 3000
        });
    }

    render() {
        return (
            <>
                <Main>
                    <ViewRoutes
                        c_state={{ ...this.state }}
                        auth={this.props.auth}
                        routes={this.props.routes}
                        handleChange={this.handleChange}
                        set_modal_content={this.setModalContent}
                        search_routes={this.searchRoutes}
                        clear_modal_contents={this.clearModalContents}
                        new_route={this.newRoute}
                        delete_route={this.deleteRoute}
                        search_route_by_tags={this.searchRouteByTags}
                        edit_route={this.editRoute}
                        reload={this.reload}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        routes: { ...state.routes }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        serverGetTags,
        clearData,
        // checkUser,
        serverKeepRoute,
        serverGetRoutes,
        serverDeleteRoute,
        serverKeepEditRoute
    }
)(ViewRoutesContainer)
