import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: "false",
    email_config: {},
    email_config_message: null,
    email_categories: {
        inbox: {},
        sent: {},
        drafts: {},
        spam: {},
        trash: {},
    },
    chosen_category: "Inbox",
    single_email: [],
    email_details: []
}

const Email = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.EMAIL_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.EMAIL_CONFIG:

            if (payload.msg === "Email configurations have been loaded successfully") {
                newState.email_config = payload.data;
            }

            newState.email_config_message = payload.msg;

            return newState;

        case types.EMAIL_SUCCESS:

            if (payload.msg === "Email configurations have been saved successfully") {
                newState.email_config = payload.data;
            }

            if (payload.msg === "Emails loaded successfully") {
                if (payload.data.mail_category === 'Inbox')
                    newState.email_categories.inbox = payload.data;
                if (payload.data.mail_category === 'Sent')
                    newState.email_categories.sent = payload.data;
                if (payload.data.mail_category === 'Drafts')
                    newState.email_categories.drafts = payload.data;
                if (payload.data.mail_category === 'Spam')
                    newState.email_categories.spam = payload.data;
                if (payload.data.mail_category === 'Trash')
                    newState.email_categories.trash = payload.data;

                newState.chosen_category = payload.data.mail_category;
            }

            if (payload.msg === "Email details loaded successfully") {
                newState.email_details = payload.data;
            }

            toast.success(payload.msg, {
                position: 'top-center'
            });

            return newState;

        case types.EMAIL_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        case types.SINGLE_EMAIL:

            newState.single_email = payload;

            return newState

        default:
            return newState;
    }
}

export default Email