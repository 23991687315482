import React, { Component } from 'react'
import Tags from './Tags';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverLoad, serverGetTags, serverDeleteTag, serverAddTag, serverEditTag, serverAddSubTag, serverEditSubTag, serverDeleteSubTag } from '../../actions/TagsAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const $ = require("jquery");

class TagsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tag_details: {},
            sub_tag_details: {},
            tag_list: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.deleteTag = this.deleteTag.bind(this);
        this.addTag = this.addTag.bind(this);
        this.editTag = this.editTag.bind(this);
        this.searchTag = this.searchTag.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.addSubTag = this.addSubTag.bind(this);
        this.reload = this.reload.bind(this);
        this.editSubTag = this.editSubTag.bind(this);
        this.deleteSubTag = this.deleteSubTag.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.auth);

        if (prevProps.tags !== this.props.tags) {

            this.setState({ tag_list: [...this.props.tags.tags] });

            if (this.props.auth.clear_data === "true") {

                $("#dismiss_add_tag_modal").click();
                $("#dismiss_edit_tag_modal").click();
                $("#dismiss_add_sub_tag_modal").click();
                $("#dismiss_edit_sub_tag_modal").click();

                this.props.clearData("false");

                this.setState({ tag_details: {} })
            }
        }


    }

    handleChange = (data, type) => {
        switch (type) {
            case "tag":
                this.setState(prevState => ({
                    tag_details: {                   // object that we want to update
                        ...prevState.tag_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            case "sub_tag":
                this.setState(prevState => ({
                    sub_tag_details: {                   // object that we want to update
                        ...prevState.sub_tag_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            default:
                break;
        }

    }

    setModalContent = (data) => {
        this.setState({
            tag_details: data
        });
    }


    deleteTag = (tid) => {
        this.props.serverDeleteTag(tid, this.props.auth.user.token);
    }

    addTag = () => {
        // check entry
        if (!this.state.tag_details.name || !this.state.tag_details.category) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverAddTag(this.state.tag_details, this.props.auth.user.token)
    }

    editTag = () => {
        // check entry
        if (!this.state.tag_details.name || !this.state.tag_details.category) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverEditTag(this.state.tag_details, this.props.auth.user.token)
    }

    searchTag = (value) => {
        // eslint-disable-next-line array-callback-return
        let new_tags = this.props.tags.tags.filter((data, index) => { // loop through state.todo
            let keys = Object.keys(data);
            let valid = false;
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (typeof data[key] !== "string") {
                    continue;
                }

                if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    valid = true;
                    break;
                }
            }

            if (valid) {
                return data;
            }
        });

        this.setState({ tag_list: new_tags });
    }

    clearModalContents = () => {
        this.setState({ tag_details: {} });

        return;
    }

    reload = () => {
        this.props.serverGetTags(this.props.auth.user.token);

        toast.success("Tags loaded successfully.", {
            position: 'top-center',
            autoClose: 3000
        });
    }

    addSubTag = () => {
        // check entry
        if (!this.state.tag_details.name) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        let tag_details = {...this.state.tag_details};
        tag_details['new_child_name'] = this.state.sub_tag_details.name;

        this.props.serverAddSubTag(tag_details, this.props.auth.user.token)
    }

    editSubTag = () => {
        // check entry
        if (!this.state.tag_details.name) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        let tag_details = {...this.state.tag_details};
        tag_details['new_child_name'] = this.state.sub_tag_details.name;

        this.props.serverEditSubTag(tag_details, this.props.auth.user.token)
    }

    deleteSubTag = (child_id) => {
        this.props.serverDeleteSubTag(child_id, this.props.auth.user.token);
    }

    render() {
        return (
            <>
                <Main>
                    <Tags
                        c_state={{ ...this.state }}
                        tags={this.props.tags}
                        auth={this.props.auth}
                        handleChange={this.handleChange}
                        delete_tag={this.deleteTag}
                        add_tag={this.addTag}
                        edit_tag={this.editTag}
                        edit_sub_tag={this.editSubTag}
                        add_sub_tag={this.addSubTag}
                        set_modal_content={this.setModalContent}
                        search_tag={this.searchTag}
                        clear_modal_contents={this.clearModalContents}
                        reload={this.reload}
                        delete_sub_tag={this.deleteSubTag}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        serverGetTags,
        clearData,
        // checkUser,
        serverDeleteTag,
        serverAddTag,
        serverEditTag,
        serverAddSubTag,
        serverEditSubTag,
        serverDeleteSubTag
    }
)(TagsContainer)
