import React, { Component } from 'react'
import ComposeEmail from './ComposeEmail';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction';
import { serverGetTags } from '../../actions/TagsAction';
import { serverGetContacts } from '../../actions/ContactsAction'
import { serverSendEmail, serverLoad } from '../../actions/EmailAction';
import { serverSaveAttachments, serverClearSavedAttachments, serverGetAllFiles } from '../../actions/AttachmentsAction';
import { serverGetDocuments, serverGetTemplates, serverUseTemplate } from '../../actions/TemplatesAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import types from '../../types';
import fileDownload from 'js-file-download';
import axios from 'axios'

const $ = require("jquery");

class ComposeEmailContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags_dropdown: [],
            contacts_dropdown: [],
            email_details: [],

            // for generic attachments modal
            a_type: "emails",
            document_config: {},
            my_computer: [],
            file_list: [],
            document_list: [],
            template_list: [],
            breadcrumbs: [],
            my_computer_selected: [],
            my_documents_selected: [],
            files_selected: [],
            selected_items: {},
            selected_files: {},
            selected_template: [],
            selected_template_details: [],
            saved_attachments: [],
            answers: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.doCompare = this.doCompare.bind(this);
        this.filterByTags = this.filterByTags.bind(this);
        this.filterByCCTags = this.filterByCCTags.bind(this);

        // attachments
        this.setAttachmentInfo = this.setAttachmentInfo.bind(this);
        this.useTemplate = this.useTemplate.bind(this);
        this.selectMyDocument = this.selectMyDocument.bind(this);
        this.selectTemplate = this.selectTemplate.bind(this);
        this.clearAttachments = this.clearAttachments.bind(this);
        this.saveAttachments = this.saveAttachments.bind(this);
        this.clearAttachmentInfo = this.clearAttachmentInfo.bind(this);
        this.fileType = this.fileType.bind(this);
        this.download = this.download.bind(this);
        this.searchAttachment = this.searchAttachment.bind(this);
        this.enterFolder = this.enterFolder.bind(this);
        this.enterFolderAlt = this.enterFolderAlt.bind(this);
        this.clickBreadCrumbs = this.clickBreadCrumbs.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        this.props.clearData("false");

        // this.props.serverLoad("true");

        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(this.props.auth.user.token);
        }else{
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company_name})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        if (!this.props.templates.documents.length) {
            this.props.serverGetDocuments(this.props.auth.user.token);
        }else{
            this.setState({ document_list: [...this.props.templates.documents] });
        }

        if (!this.props.templates.templates.length) {
            this.props.serverGetTemplates(this.props.auth.user.token);
        }else{
            this.setState({ template_list: [...this.props.templates.templates] });
        }

        if (!this.props.attachments.all_files.length) {
            this.props.serverGetAllFiles(this.props.auth.user.token);
        }else{
            if (!this.state.breadcrumbs.length) {

                let parent = this.props.attachments.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if(parent.length){
                    file_list = this.props.attachments.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.auth.clear_data !== this.props.auth.clear_data) {

            if (this.props.auth.clear_data === "true") {

                this.props.clearData("false");
            }

            this.setState({ email_details: [] })
            this.clearAttachments();
        }

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.contacts !== this.props.contacts) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company_name})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        if (prevProps.templates.use_docs_config !== this.props.templates.use_docs_config) {
            if (this.props.templates.use_docs_config.document) {
                $("#dismiss_use_template_modal").click();
                $("#btn-show-doc-modal").click();

                this.setState({ selected_template_details: [], selected_template: [] });
            }
        }

        // generic attachments
        if (prevProps.templates.use_docs_config !== this.props.templates.use_docs_config) {
            if (this.props.templates.use_docs_config.document) {
                $("#dismiss_use_template_modal").click();
                $("#btn-show-doc-modal").click();

                this.setState({ selected_template_details: [], selected_template: [] });
            }
        }

        if (prevProps.attachments.all_files !== this.props.attachments.all_files) {

            if (!this.state.breadcrumbs.length) {

                let parent = this.props.attachments.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if(parent.length){
                    file_list = this.props.attachments.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
            }
        }

        if (prevProps.templates.documents !== this.props.templates.documents) {
            this.setState({ document_list: [...this.props.templates.documents] });
        }

        if (prevProps.templates.templates !== this.props.templates.templates) {
            this.setState({ template_list: [...this.props.templates.templates] });
        }
    }

    clickBreadCrumbs = (index) => {
        let breadcrumbs = [...this.state.breadcrumbs];
        let selected_breadcrumbs = this.state.breadcrumbs[index];
        let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === selected_breadcrumbs.fid + '');
        breadcrumbs = breadcrumbs.slice(0, index + 1);
        return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
    }

    handleChange = (data, type) => {

        switch (type) {
            case 'email_details':
                this.setState(prevState => ({
                    email_details: {                   // object that we want to update
                        ...prevState.email_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            // generic attachments modal
            case 'answers':
                this.setState(prevState => ({
                    answers: {                   // object that we want to update
                        ...prevState.answers,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            // generic attachments modal
            case 'template':
                this.setState(prevState => ({
                    selected_template_details: {                   // object that we want to update
                        ...prevState.selected_template_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            // generic attachments modal
            case 'my_computer':
                this.setState({ my_computer: data });

                let attachments = [];

                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    attachments.push({
                        "file_name": element.name,
                        "file_size": element.size
                    });
                }

                this.setState({ my_computer_selected: attachments });

                break;
            default:
                break;
        }

        if (data.field === 'attachments') {

            this.setState(prevState => ({
                email_details: {                   // object that we want to update
                    ...prevState.email_details,    // keep all other key-value pairs
                    "attachments": []     // update the value of specific key
                }
            }));

            let attachments = [];

            for (let index = 0; index < data.value.length; index++) {
                const element = data.value[index];
                attachments.push({
                    "file_name": element.name,
                    "file_size": element.size
                });
            }

            this.setState(prevState => ({
                email_details: {                   // object that we want to update
                    ...prevState.email_details,    // keep all other key-value pairs
                    "attachments": attachments     // update the value of specific key
                }
            }));
        }
    }

    sendEmail = () => {

        // check entry
        if (!this.state.email_details.recipients || !this.state.email_details.subject || !this.state.email_details.message) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        // // Create an object of formData
        // const formData = new FormData();

        // if (this.state.email_details.attachments) {

        //     for (let index = 0; index < this.state.email_details.attachments.length; index++) {
        //         const element = this.state.email_details.attachments[index];
        //         formData.append("attachments[]", element);
        //     }

        // }

        // let item = this.state.email_details;

        // for (var key in item) {
        //     if (key === "tags" || key === "recipients") {
        //         formData.append(key, JSON.stringify(item[key]));
        //         // console.log(key, JSON.stringify(item[key]));
        //     } else {
        //         if (key !== "attachments") {
        //             formData.append(key, item[key]);
        //         }
        //     }
        // }

        // Create an object of formData
        const formData = new FormData();

        let email_details = { ...this.state.email_details };

        if (this.state.my_computer.length) {

            for (let index = 0; index < this.state.my_computer.length; index++) {
                const element = this.state.my_computer[index];
                formData.append("my_computer[]", element);
            }
        }

        if (this.state.my_documents_selected.length) {
            formData.append("my_documents", JSON.stringify(this.state.my_documents_selected));
        }

        if (this.state.files_selected.length) {
            formData.append("files", JSON.stringify(this.state.files_selected));
        }

        formData.append("type", this.state.a_type);

        formData.append("email_details", JSON.stringify(email_details));

        this.props.serverLoad("true");

        this.props.serverSendEmail(formData, this.props.auth.user.token);
    }

    doCompare = (selected_tags, contacts) => {
        let label = {};
        let value = {};
        let results = [];

        for (let index = 0; index < selected_tags.length; index++) {
            const element = selected_tags[index];

            label[element.label] = true;
            value[element.value] = true;

        }

        for (let i = 0; i < contacts.length; i++) {
            const el = contacts[i];
            let tags = JSON.parse(el.tags.replace(/'/g, '"'));
            let isValid = false

            // check if all selected tags exist in contacts
            //  let myValues = {...value}

            for (let ind = 0; ind < tags.length; ind++) {
                const elt = tags[ind];
                if (value[elt.value]) {
                    isValid = true;
                    break;
                }

                // check if all selected tags exist in contacts
                // if (myValues[elt.value]) {
                //     delete myValues[elt.value]
                // }
            }

            if (isValid) {
                results.push({ "value": el.contact_id, "label": `${el.first_name} ${el.last_name}` });
            }

            // check if all selected tags exist in contacts
            // if (!Object.keys(myValues).length) {
            //     results.push({"value": el.contact_id, "label": `${el.first_name} ${el.last_name}`});
            // }
        }

        return results;
    }

    filterByTags = () => {

        // console.log(this.state.contacts_dropdown);

        if (this.state.email_details.tags) {

            let result = this.doCompare(this.state.email_details.tags, this.props.contacts.contacts);

            this.setState(prevState => ({
                email_details: {                   // object that we want to update
                    ...prevState.email_details,    // keep all other key-value pairs
                    "recipients": result     // update the value of specific key
                }
            }));
        }

        $("#dismiss_recipients_tag_modal").click();
    }

    filterByCCTags = () => {

        // console.log(this.state.contacts_dropdown);

        if (this.state.email_details.cc_tags) {

            let result = this.doCompare(this.state.email_details.cc_tags, this.props.contacts.contacts);

            this.setState(prevState => ({
                email_details: {                   // object that we want to update
                    ...prevState.email_details,    // keep all other key-value pairs
                    "cc": result     // update the value of specific key
                }
            }));
        }

        $("#dismiss_cc_tag_modal").click();
    }

    clearAttachments = () => {
        this.setState({ my_documents_selected: [], files_selected: [], my_computer_selected: [], selected_items: {}, selected_files: {}, my_computer: [] });
    }

    saveAttachments = () => {
        if (!this.state.my_computer_selected.length && !this.state.my_documents_selected.length && !this.state.files_selected.length) {

            toast.error("Please select files to add as attachments", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        $("#dismiss_attachment_modal").click();
    }

    setAttachmentInfo = (data, type) => {

        let id = "";
        let file_name = "";
        let folder = "";
        let ext = "";

        switch (type) {
            case 'attachment':
                id = data.aid;
                file_name = data.file_name;
                folder = "attachments";
                ext = data.ext;
                break;
            case 'document':
                id = data.did;
                file_name = data.name;
                folder = "documents";
                ext = data.ext;
                break;
            case 'file':
                id = data.fid;
                file_name = data.filename;
                folder = "file_manager/files";
                ext = data.filetype;
                break;
            case 'template':
                id = data.tid;
                file_name = data.template_name;
                folder = "templates";
                ext = data.ext;
                break;
            default:
                break;
        }

        if (ext === ".docx" || ext === ".pptx" || ext === ".xlsx" || ext === '.csv') {

            let kind = "";
            if (ext === '.docx') {
                kind = "word";
            } else if (ext === '.pptx') {
                kind = "slide";
            } else if (ext === '.xlsx' || ext === '.csv') {
                kind = "cell";
            }

            let config = {
                "document": {
                    "fileType": ext.replace('.', ''),
                    "permissions": {
                        "download": false,
                        "edit": false
                    },
                    "key": Math.floor(Math.random() * 999999999999) + "",
                    "title": file_name,
                    "url": `${types.SERVER_URL}static/${folder}/${id.toString()}${ext}`,
                    "user": {
                        "id": this.props.auth.user.contact_id,
                        "name": `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`
                    },
                },
                "documentType": kind,
                "editorConfig": {
                    "callbackUrl": `${types.SERVER_URL}documentserver_callback?file=${folder}:${id.toString()}${ext}`,
                    "customization": {
                        "forcesave": true
                    }
                }
            }

            this.setState({ document_config: config });
        }

        this.setState({ view_attachment_data: {"id": id, "file_name": file_name, "folder": folder, "ext": ext} })
    }

    selectMyDocument = (data) => {

        let newState = { ...this.state.selected_items };

        if (newState[data.did]) {
            delete newState[data.did];
        } else {
            newState[data.did] = data;
        }

        this.setState({
            selected_items: newState
        });

        let results = Object.values(newState);

        // console.log(results);

        this.setState({ my_documents_selected: results })
    }

    selectFiles = (data) => {

        let newState = { ...this.state.selected_files };

        if (newState[data.fid]) {
            delete newState[data.fid];
        } else {
            newState[data.fid] = data;
        }

        this.setState({
            selected_files: newState
        });

        let results = Object.values(newState);

        // console.log(results);

        this.setState({ files_selected: results })
    }

    enterFolder = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === data.fid + '');

            let breadcrumbs = [...this.state.breadcrumbs, data];

            this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    enterFolderAlt = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === data.fid + '');
            this.setState({ file_list: file_list });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    selectTemplate = (data) => {
        this.setState({ selected_template: data });
    }

    useTemplate = () => {
        // console.log("stemp", this.state.selected_template_details);
        // console.log("answers", this.state.answers);

        if (!this.state.selected_template_details.document_name || !this.state.answers) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverLoad("true");
        this.props.serverUseTemplate(
            this.state.selected_template.templates.tid,
            this.state.selected_template_details.document_name,
            this.state.selected_template_details.tags ? this.state.selected_template_details.tags : [],
            this.state.answers,
            this.props.auth.user.token
        );
    }

    searchAttachment = (value, type) => {
        switch (type) {
            case 'documents':
                // eslint-disable-next-line array-callback-return
                let document_list = this.props.templates.documents.filter((data, index) => {
                    if (data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return data;
                    }
                })
                this.setState({ document_list: document_list });
                break;
            case 'files':
                if (value) {
                    // eslint-disable-next-line array-callback-return
                    let file_list = this.props.attachments.all_files.filter((data, index) => {
                        if (data.filename.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                            return data;
                        }
                    })
                    this.setState({ file_list: file_list });
                } else {
                    let file_list = this.state.breadcrumbs[this.state.breadcrumbs.length - 1];
                    if (typeof file_list !== 'undefined') {
                        this.enterFolderAlt(file_list);
                    }
                }
                break;
            case 'templates':
                // eslint-disable-next-line array-callback-return
                let template_list = this.props.templates.templates.filter((data, index) => {
                    if (data.templates.template_name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return data;
                    }
                })
                this.setState({ template_list: template_list });
                break;
            default:
                break;
        }
    }

    clearAttachmentInfo = () => {
        this.setState({ view_attachment_data: {} });
    }

    fileType = (type) => {

        var image_type_list = ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi', '.raw', '.arw', '.cr2', '.nrw', '.k25', '.png', '.gif', '.webp', '.tiff', '.tif', '.bmp', '.dib', '.jp2', '.j2k', '.jpf', '.jpx', '.jpm', '.mj2',
            '.svg', '.svgz', '.ai', '.eps', '.ico'
        ];

        var video_type_list = ['.mp4', '.m4a', '.m4v', '.f4v', '.f4a', '.m4b', '.m4r', '.f4b', '.mov', '.3gp', '.3gp2', '.3g2', '.3gpp', '.3gpp2',
            '.ogg', '.oga', '.ogv', '.ogx', '.webm', '.avi', '.flv', '.mpg', '.wmv', '.mkv'
        ];

        var audio_type_list = ['.mid', '.mp3', '.m4a', '.flac', '.wav', '.amr'];

        var compressed_type_list = ['.001', '.7z', '.arj', '.bin', '.bzip', '.bzip2', '.cab', '.cpio', '.deb', '.ear', '.gz', '.hqx', '.jar', '.lha',
            '.rar', '.rpm', '.sea', '.sit', '.tar', '.war', '.zip', '.epub', '.bz2'
        ];

        var doc_type_list = ['.txt', '.php', '.py', '.html', '.css', '.js', '.sql'];


        if (image_type_list.includes(type)) {

            return 'image';

        } else if (video_type_list.includes(type)) {

            return 'video';

        } else if (audio_type_list.includes(type)) {

            return 'audio';

        } else if (compressed_type_list.includes(type)) {

            return 'zip';

        } else if (doc_type_list.includes(type)) {

            return 'docs';

        } else {

            return '';
        }
    }

    download = async (folder, id, ext, file_name) => {

        axios.get(`${types.SERVER_URL}static/${folder}/${id}${ext}`, {
            responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, `${file_name}${ext}`);
        });
    }

    render() {
        return (
            <>
                <Main>
                    <ComposeEmail
                        c_state={{ ...this.state }}
                        isLoading={this.props.email.isLoading}
                        auth={this.props.auth}
                        handleChange={this.handleChange}
                        send_email={this.sendEmail}
                        filter_by_tags={this.filterByTags}
                        filter_by_cc_tags={this.filterByCCTags}

                        // attachments component
                        templates={this.props.templates}
                        attachments={this.props.attachments}
                        select_my_document={this.selectMyDocument}
                        select_files={this.selectFiles}
                        enter_folder={this.enterFolder}
                        click_breadcrumbs={this.clickBreadCrumbs}
                        select_template={this.selectTemplate}
                        use_template={this.useTemplate}
                        save_attachments={this.saveAttachments}
                        clear_attachments={this.clearAttachments}
                        search_attachment={this.searchAttachment}
                        // view attachments
                        set_attachment_info={this.setAttachmentInfo}
                        clear_attachment_info={this.clearAttachmentInfo}
                        file_type={this.fileType}
                        download={this.download}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        email: { ...state.email },
        contacts: { ...state.contacts },
        templates: { ...state.templates },
        attachments: { ...state.attachments }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        clearData,
        // checkUser,
        serverGetTags,
        serverGetContacts,
        serverSendEmail,
        serverUseTemplate,
        serverGetDocuments,
        serverGetTemplates,
        serverSaveAttachments,
        serverClearSavedAttachments,
        serverGetAllFiles
    }
)(ComposeEmailContainer)
