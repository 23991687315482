/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import { Link } from 'react-router-dom';
import types from '../../types';

const CompanyContactDetailsModal = (props) => {

    // console.log(props);

    return (
        <div className="modal fade" id="view_company_contact_details_modal">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">View Contact Details</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {/* <p>{JSON.stringify(props.c_state.contact_details)}&hellip;</p> */}
                        <div className="card card-primary card-outline">
                            <div className="card-body box-profile">
                                <div className="text-center">
                                    <img className="profile-user-img img-fluid img-circle"
                                        src={props.c_state.contact_details.photo !== '' ? types.SERVER_URL + 'static/contacts/' + props.c_state.contact_details.photo : "static/assets/dist/img/user1-128x128.jpg"}
                                        alt="User profile picture" />
                                </div>

                                <h3 className="profile-username text-center">{props.c_state.contact_details.title} {props.c_state.contact_details.first_name} {props.c_state.contact_details.last_name}</h3>

                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>Email</b> <Link to="#" className="float-right">{props.c_state.contact_details.email}</Link>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Phone</b> <Link to="#" className="float-right">{props.c_state.contact_details.phone}</Link>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Address</b> <Link to="#" className="float-right">{props.c_state.contact_details.address}</Link>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Tags</b> <Link to="#" className="float-right">{props.fixTags(props.c_state.contact_details.tags)}</Link>
                                    </li>
                                </ul>

                                {/* <Link to="#" className="btn btn-primary btn-block"><b>Follow</b></Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CompanyContactDetailsModal;
