import React, { Component } from 'react'
import Login from './Login'
import { connect } from 'react-redux'
import { serverLogin, serverLoad } from './../../actions/AuthAction'

class LoginContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }

        this.doLogin = this.doLogin.bind(this);
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {

        if(this.props.auth.user && this.props.auth.user.token && this.props.auth.user.token.trim().length){
            this.props.history.push("/dashboard");
        }
    }

    componentDidUpdate(prevProps, prevState) { // this is called anytime there is changes in the props/state

        // console.log(this.props.auth)

        if (prevProps.auth.user !== this.props.auth.user) {
            if(this.props.auth.user !== null){
                this.props.history.push("/dashboard");
            }
        }

        if (this.props.auth.success === "true") {
            this.props.history.push("/dashboard");
        } else if ((typeof this.props.auth.msg === 'string') && this.props.auth.msg.trim() > 0 && this.props.auth.success === "false") {
            alert(this.props.auth.msg);
        }
    }

    doLogin = () => {
        let obj = {
            password: this.state.password,
            email: this.state.email
        }

        this.props.serverLoad("true");

        this.props.serverLogin(obj);
    }
    
    handleChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        return (
            <>
                <Login {...this.state} handleChange={this.handleChange} login_func={this.doLogin} isLoading={this.props.auth.isLoading} />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps, { serverLogin, serverLoad })(LoginContainer)
