import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'

const RouteSteps = (props) => {

    // console.log("steps", props.c_state.route_step_details.recipient);

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Route Steps ({props.routes.single_route.title})</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Route Steps</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Define Route Steps</h3>

                                    <div className="card-tools">
                                        <button type="button" className="btn btn-sm btn-warning" title="Add New Route Step" data-toggle="modal" data-target="#new_route_step_modal" onClick={props.clear_modal_contents}>
                                            <i className="fas fa-plus"></i> Add New Step
                                        </button>
                                        <button type="button" className="btn btn-sm btn-outline-default">
                                            <div className="input-group input-group-sm" style={{ width: 200 }}>
                                                <input type="search" className="form-control form-control-sm" placeholder="Type your keywords here" onChange={(event) => props.search_tag(event.target.value)} />
                                            </div>
                                        </button>&nbsp;
                                    </div>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Recipient</th>
                                                <th>Deadline</th>
                                                <th>Auto Forward</th>
                                                <th>Can Close</th>
                                                <th>Expected Response Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.routes.single_route_step ?
                                                // eslint-disable-next-line array-callback-return
                                                props.routes.single_route_step.map((data, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{data.recipient.label}</td>
                                                            <td>{data.deadline}</td>
                                                            <td>{data.autoforward}</td>
                                                            <td>{data.can_close}</td>
                                                            <td>{data.expected_response_date}</td>
                                                            <td className="text-right py-0 align-middle">
                                                                <div className="btn-group btn-group-sm">
                                                                    <button className="btn btn-info" title="Edit Route Step" data-toggle="modal" data-target="#edit_route_step_modal" onClick={() => props.set_modal_content(data, i)}><i className="fas fa-edit"></i></button>&nbsp;
                                                                    <button className="btn btn-danger" title="Delete Route Step" onClick={() => window.confirm(`Are you sure you want to delete?`) ? props.delete_route_step(i) : console.log(i)}><i className="fas fa-trash"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.routes.single_route_step.length ?
                        <div align="center">
                            <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}</span>
                            <button className="btn btn-primary" title="Save route" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.save_route}><i className="fas fa-save"></i> Finish</button>
                        </div>
                        : null}
                </section>

                {/* Modals */}

                <div className="modal fade" id="new_route_step_modal" style={{ overflow: "auto" }}>
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">New Route Step</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group col-12">
                                    <label htmlFor="recipient">Recipient *</label>
                                    <Select
                                        options={props.c_state.contacts_dropdown}
                                        name="recipient"
                                        value={props.c_state.route_step_details.recipient ? typeof props.c_state.route_step_details.recipient !== "string" ? props.c_state.route_step_details.recipient : JSON.parse(props.c_state.route_step_details.recipient.replace(/'/g, '"')) : ''}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "recipient", "value": value })}
                                        // isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="To"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="cc">FYI</label>
                                    <Select
                                        options={props.c_state.contacts_dropdown}
                                        name="cc"
                                        value={props.c_state.route_step_details.cc ? typeof props.c_state.route_step_details.cc !== "string" ? props.c_state.route_step_details.cc : JSON.parse(props.c_state.route_step_details.cc.replace(/'/g, '"')) : ''}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "cc", "value": value })}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="CC"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="deadline">Deadline (hours) *</label>
                                    <input type="text" value={props.c_state.route_step_details.deadline ? props.c_state.route_step_details.deadline : ''} onChange={(event) => props.handleChange({ "field": "deadline", "value": event.target.value })} className="form-control" name="deadline" placeholder="Deadline" />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="expected_response_date">Expected Response Date *</label>
                                    <input type="date" value={props.c_state.route_step_details.expected_response_date ? props.c_state.route_step_details.expected_response_date : ''} onChange={(event) => props.handleChange({ "field": "expected_response_date", "value": event.target.value })} className="form-control" name="expected_response_date" placeholder="Expected Response Date" />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="autoforward">Auto Forward *</label>
                                    <select className="form-control" onChange={(event) => props.handleChange({ "field": "autoforward", "value": event.target.value })} value={props.c_state.route_step_details.autoforward ? props.c_state.route_step_details.autoforward : ""}>
                                        <option value=""></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="can_close">Can Close *</label>
                                    <select className="form-control" onChange={(event) => props.handleChange({ "field": "can_close", "value": event.target.value })} value={props.c_state.route_step_details.can_close ? props.c_state.route_step_details.can_close : ""}>
                                        <option value=""></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_new_route_step_modal" onClick={props.clear_modal_contents}>Close</button>
                                <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.new_route_step}>Save</button></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="edit_route_step_modal" style={{ overflow: "auto" }}>
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Edit Route Step</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group col-12">
                                    <label htmlFor="recipient">Recipient *</label>
                                    <Select
                                        options={props.c_state.contacts_dropdown}
                                        name="recipient"
                                        value={props.c_state.route_step_details.recipient}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "recipient", "value": value })}
                                        // isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="To"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="cc">FYI</label>
                                    <Select
                                        options={props.c_state.contacts_dropdown}
                                        name="cc"
                                        value={props.c_state.route_step_details.cc}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "cc", "value": value })}
                                        // isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="CC"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="deadline">Deadline (hours) *</label>
                                    <input type="text" value={props.c_state.route_step_details.deadline} onChange={(event) => props.handleChange({ "field": "deadline", "value": event.target.value })} className="form-control" name="deadline" placeholder="Deadline" />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="expected_response_date">Expected Response Date *</label>
                                    <input type="date" value={props.c_state.route_step_details.expected_response_date} onChange={(event) => props.handleChange({ "field": "expected_response_date", "value": event.target.value })} className="form-control" name="expected_response_date" placeholder="Expected Response Date" />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="autoforward">Auto Forward *</label>
                                    <select className="form-control" onChange={(event) => props.handleChange({ "field": "autoforward", "value": event.target.value })} value={props.c_state.route_step_details.autoforward}>
                                        <option value=""></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="can_close">Can Close *</label>
                                    <select className="form-control" onChange={(event) => props.handleChange({ "field": "can_close", "value": event.target.value })} value={props.c_state.route_step_details.can_close}>
                                        <option value=""></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_route_step_modal" onClick={props.clear_modal_contents}>Close</button>
                                <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.edit_route_step}>Save</button></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RouteSteps
