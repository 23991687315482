import React, { Component } from 'react'
import CorrespondenceDetails from './CorrespondenceDetails';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverGetTags } from '../../actions/TagsAction';
import { serverGetContacts } from '../../actions/ContactsAction';
import { serverLoad, serverGetCorrespondenceDetails, serverNullifyAction, serverNullifyTrail, serverAddAction, serverForwardTrail, serverArchiveTrail, serverCloseCorrespondence, serverSendEmail } from '../../actions/CorrespondenceAction';
import { serverGetDocuments, serverGetTemplates, serverUseTemplate } from '../../actions/TemplatesAction';
import { serverSaveAttachments, serverClearSavedAttachments, serverGetAllFiles, serverUploadToFm } from '../../actions/AttachmentsAction';
import { serverGetRoutes } from '../../actions/RoutesAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import types from '../../types';
import fileDownload from 'js-file-download';
import axios from 'axios'

const $ = require("jquery");

let today = new Date();
let month = (today.getMonth() + 1) < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1;
let day = (today.getDate()) < 10 ? `0${today.getDate()}` : today.getDate();
let hour = (today.getHours()) < 10 ? `0${today.getHours()}` : today.getHours();
let minute = (today.getMinutes()) < 10 ? `0${today.getMinutes()}` : today.getMinutes();
let current_date = today.getFullYear() + '-' + month + '-' + day;
let current_time = hour + ":" + minute;

class CorrespondenceDetailsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags_dropdown: [],
            contacts_dropdown: [],
            single_correspondence: {},
            correspondence_details: {},
            action_details: {},
            email_details: {},
            trail_info: {},
            trail_details: {
                "schedule_date": current_date,
                "schedule_time": current_time
            },
            route_list: [],
            trail_to_show: '',

            // for generic attachments modal
            a_type: "",
            document_config: {},
            my_computer: [],
            file_list: [],
            document_list: [],
            template_list: [],
            breadcrumbs: [],
            my_computer_selected: [],
            my_documents_selected: [],
            files_selected: [],
            selected_items: {},
            selected_files: {},
            selected_template: [],
            selected_template_details: [],
            saved_attachments: [],
            answers: {},
            view_attachment_data: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.reloadCorrespondence = this.reloadCorrespondence.bind(this);
        this.contactDetails = this.contactDetails.bind(this);
        this.nullifyAction = this.nullifyAction.bind(this);
        this.nullifyTrail = this.nullifyTrail.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.setTrailInfo = this.setTrailInfo.bind(this);
        this.addAction = this.addAction.bind(this);
        this.setAType = this.setAType.bind(this);
        this.forwardTrail = this.forwardTrail.bind(this);
        this.filterByCCTags = this.filterByCCTags.bind(this);
        this.filterByToTags = this.filterByToTags.bind(this);
        this.filterByEmailToTags = this.filterByEmailToTags.bind(this);
        this.filterByEmailCCTags = this.filterByEmailCCTags.bind(this);
        this.doCompare = this.doCompare.bind(this);
        this.archiveTrail = this.archiveTrail.bind(this);
        this.closeCorrespondence = this.closeCorrespondence.bind(this);
        this.forwardToContacts = this.forwardToContacts.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.nextRouteStep = this.nextRouteStep.bind(this);
        this.trailToShow = this.trailToShow.bind(this);
        this.checkCurrentStepOrder = this.checkCurrentStepOrder.bind(this);

        // attachments
        this.setAttachmentInfo = this.setAttachmentInfo.bind(this);
        this.useTemplate = this.useTemplate.bind(this);
        this.selectMyDocument = this.selectMyDocument.bind(this);
        this.selectTemplate = this.selectTemplate.bind(this);
        this.clearAttachments = this.clearAttachments.bind(this);
        this.saveAttachments = this.saveAttachments.bind(this);
        this.clearAttachmentInfo = this.clearAttachmentInfo.bind(this);
        this.fileType = this.fileType.bind(this);
        this.download = this.download.bind(this);
        this.uploadToFm = this.uploadToFm.bind(this);
        this.searchAttachment = this.searchAttachment.bind(this);
        this.enterFolder = this.enterFolder.bind(this);
        this.enterFolderAlt = this.enterFolderAlt.bind(this);
        this.clickBreadCrumbs = this.clickBreadCrumbs.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        this.props.clearData("false");

        this.props.serverGetCorrespondenceDetails(this.props.correspondence.single_correspondence.single_correspondence.correspondence_id, this.props.correspondence.single_correspondence.correspondence_category, this.props.auth.user.token);

        if (!this.props.routes.all_routes.length) {
            this.props.serverGetRoutes(this.props.auth.user.token);
        } else {
            this.setState({ route_list: [...this.props.routes.all_routes] });
        }

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (!this.props.contacts.contacts.length) {
            this.props.serverGetContacts(this.props.auth.user.token);
        } else {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company_name})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        if (!this.props.attachments.all_files.length) {
            this.props.serverGetAllFiles(this.props.auth.user.token);
        } else {
            if (!this.state.breadcrumbs.length) {

                let parent = this.props.attachments.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if (parent.length) {
                    file_list = this.props.attachments.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
            }
        }

        if (!this.props.templates.documents.length) {
            this.props.serverGetDocuments(this.props.auth.user.token);
        } else {
            this.setState({ document_list: [...this.props.templates.documents] });
        }

        if (!this.props.templates.templates.length) {
            this.props.serverGetTemplates(this.props.auth.user.token);
        } else {
            this.setState({ template_list: [...this.props.templates.templates] });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.auth);

        if (prevProps.correspondence.reload !== this.props.correspondence.reload) {
            if (this.props.correspondence.reload === "true") {
                this.props.serverGetCorrespondenceDetails(this.props.correspondence.single_correspondence.single_correspondence.correspondence_id, this.props.correspondence.single_correspondence.correspondence_category, this.props.auth.user.token);

                // this.setState({ isLoading: "false" });
                $("#dismiss_add_action_modal").click();
                $("#dismiss_forward_trail_modal").click();
                $("#dismiss_reply_trail_modal").click();
                $("dismiss_send_email_modal").click();
                this.clearAttachments();

                this.clearModalContents();
            }
        }

        if (prevProps.tags !== this.props.tags) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.tags.tags.forEach((data, index) => {
                data.children.forEach((d, i) => {
                    if (d.active === "1") {
                        let v = { "value": d.child_id, "label": `${data.name} : ${d.name}` };
                        arr.push(v);
                    }
                })
            });

            this.setState({ tags_dropdown: arr });
        }

        if (prevProps.contacts !== this.props.contacts) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company_name})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        if (prevProps.correspondence !== this.props.correspondence) {
            // if (this.props.correspondence.correspondence_details.trails) {
            // this.props.serverLoad("false");
            this.setState({ correspondence_details: this.props.correspondence.correspondence_details });
            // }
        }

        if (prevProps.routes.all_routes !== this.props.routes.all_routes) {

            this.setState({ route_list: [...this.props.routes.all_routes] });
        }

        // generic attachments
        if (prevProps.templates.use_docs_config !== this.props.templates.use_docs_config) {
            if (this.props.templates.use_docs_config.document) {
                $("#dismiss_use_template_modal").click();
                $("#btn-show-doc-modal").click();

                this.setState({ selected_template_details: [], selected_template: [] });
            }
        }

        if (prevProps.attachments.all_files !== this.props.attachments.all_files) {

            if (!this.state.breadcrumbs.length) {

                let parent = this.props.attachments.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if (parent.length) {
                    file_list = this.props.attachments.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
            }
        }

        if (prevProps.templates.documents !== this.props.templates.documents) {
            this.setState({ document_list: [...this.props.templates.documents] });
        }

        if (prevProps.templates.templates !== this.props.templates.templates) {
            this.setState({ template_list: [...this.props.templates.templates] });
        }
    }

    clickBreadCrumbs = (index) => {
        let breadcrumbs = [...this.state.breadcrumbs];
        let selected_breadcrumbs = this.state.breadcrumbs[index];
        let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === selected_breadcrumbs.fid + '');
        breadcrumbs = breadcrumbs.slice(0, index + 1);
        return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
    }

    selectMyDocument = (data) => {

        let newState = { ...this.state.selected_items };

        if (newState[data.did]) {
            delete newState[data.did];
        } else {
            newState[data.did] = data;
        }

        this.setState({
            selected_items: newState
        });

        let results = Object.values(newState);

        // console.log(results);

        this.setState({ my_documents_selected: results })
    }

    selectTemplate = (data) => {
        this.setState({ selected_template: data });
    }

    useTemplate = () => {

        if (!this.state.selected_template_details.document_name || !this.state.answers) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverUseTemplate(
            this.state.selected_template.templates.tid,
            this.state.selected_template_details.document_name,
            this.state.selected_template_details.tags ? this.state.selected_template_details.tags : [],
            this.state.answers,
            this.props.auth.user.token
        );
    }

    saveAttachments = () => {

        if (!this.state.my_computer_selected.length && !this.state.my_documents_selected.length && !this.state.files_selected.length) {

            toast.error("Please select files to add as attachments", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        $("#dismiss_attachment_modal").click();
    }

    clearAttachments = () => {
        this.setState({ my_documents_selected: [], files_selected: [], my_computer_selected: [], selected_items: {}, selected_files: {}, my_computer: [] });
    }

    handleChange = (data, type) => {
        switch (type) {
            case 'action':
                this.setState(prevState => ({
                    action_details: {                   // object that we want to update
                        ...prevState.action_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;

            // generic attachments modal
            case 'answers':
                this.setState(prevState => ({
                    answers: {                   // object that we want to update
                        ...prevState.answers,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            // generic attachments modal
            case 'template':
                this.setState(prevState => ({
                    selected_template_details: {                   // object that we want to update
                        ...prevState.selected_template_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            // generic attachments modal
            case 'my_computer':
                this.setState({ my_computer: data });

                let attachments = [];

                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    attachments.push({
                        "file_name": element.name,
                        "file_size": element.size
                    });
                }

                this.setState({ my_computer_selected: attachments });

                break;
            case "trail_details":
                this.setState(prevState => ({
                    trail_details: {                   // object that we want to update
                        ...prevState.trail_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;
            case "email_details":
                this.setState(prevState => ({
                    email_details: {                   // object that we want to update
                        ...prevState.email_details,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break
            default:
                break;
        }
    }

    reloadCorrespondence = () => {
        this.props.serverGetCorrespondenceDetails(this.props.correspondence.single_correspondence.single_correspondence.correspondence_id, this.props.correspondence.single_correspondence.correspondence_category, this.props.auth.user.token);
    }

    contactDetails = (contact_id) => {
        // eslint-disable-next-line array-callback-return
        let contact_details = this.props.contacts.contacts.filter((data, index) => {
            if (data.contact_id === contact_id) {
                return data;
            }
        })

        if (contact_details.length) {
            contact_details = contact_details[0];
            return `${contact_details.first_name} ${contact_details.last_name}`;
        } else {
            return '';
        }
    }

    nullifyAction = (action, action_id) => {
        this.props.serverNullifyAction(action, action_id, this.props.auth.user.token)
    }

    clearModalContents = () => {
        this.setState({ action_details: {}, trail_details: {}, email_details: {} });

        return;
    }

    setTrailInfo = (data) => {
        this.setState(prevState => ({
            action_details: {                   // object that we want to update
                ...prevState.action_details,    // keep all other key-value pairs
                "trail_id": data.trail_id     // update the value of specific key
            }
        }));

        this.setState({ trail_info: data });

        // console.log("trail_info", data);
    }

    setAType = (a_type) => {
        this.setState({ a_type: a_type })
    }

    addAction = () => {
        let action_details = { ...this.state.action_details };

        if (!action_details.title || !action_details.date_started || !action_details.date_completed ||
            !action_details.status || !action_details.description) {
            toast.error("Please fill all required fields", {
                position: 'top-center',
                autoClose: 5000
            });

            return;
        }

        action_details['correspondence_id'] = this.props.correspondence.single_correspondence.single_correspondence.correspondence_id;

        // Create an object of formData
        const formData = new FormData();

        if (this.state.my_computer.length) {

            for (let index = 0; index < this.state.my_computer.length; index++) {
                const element = this.state.my_computer[index];
                formData.append("my_computer[]", element);
            }
        }

        if (this.state.my_documents_selected.length) {
            formData.append("my_documents", JSON.stringify(this.state.my_documents_selected));
        }

        if (this.state.files_selected.length) {
            formData.append("files", JSON.stringify(this.state.files_selected));
        }

        formData.append("type", this.state.a_type);

        formData.append("action_details", JSON.stringify(action_details));

        this.props.serverAddAction(formData, this.props.auth.user.token);
    }

    sendEmail = () => {
        let email_details = { ...this.state.email_details };

        // check entry
        if (!email_details.recipients || !email_details.subject || !email_details.message) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        email_details['correspondence_id'] = this.props.correspondence.single_correspondence.single_correspondence.correspondence_id;

        email_details['trail_id'] = this.state.trail_info.trail_id;

        // Create an object of formData
        const formData = new FormData();

        if (this.state.my_computer.length) {

            for (let index = 0; index < this.state.my_computer.length; index++) {
                const element = this.state.my_computer[index];
                formData.append("my_computer[]", element);
            }
        }

        if (this.state.my_documents_selected.length) {
            formData.append("my_documents", JSON.stringify(this.state.my_documents_selected));
        }

        if (this.state.files_selected.length) {
            formData.append("files", JSON.stringify(this.state.files_selected));
        }

        formData.append("type", "emails");

        formData.append("email_details", JSON.stringify(email_details));

        console.log(formData);

        this.props.serverSendEmail(formData, this.props.auth.user.token);
    }

    forwardToContacts = (contacts) => {

        if (contacts) {
            let final_contacts = [];

            for (let index = 0; index < contacts.length; index++) {
                const element = contacts[index];
                let label = this.contactDetails(element);
                final_contacts.push({ "label": label, "value": element });
            }

            return final_contacts;
        }
    }

    forwardTrail = (action) => {
        let trail_details = { ...this.state.trail_details };

        if (action === 'reply') {
            trail_details['to'] = [{ "value": this.state.trail_info.c_from, "label": this.state.trail_info.c_from }];
        }

        if (!trail_details.to && !trail_details.cc && !this.props.correspondence.single_correspondence.single_correspondence.current_step_order) {
            toast.error("Please select your recipients", {
                position: 'top-center',
                autoClose: 5000
            });

            return;
        }

        if (!trail_details.message) {
            toast.error("Please type your message", {
                position: 'top-center',
                autoClose: 5000
            });

            return;
        }

        trail_details['correspondence_details'] = this.props.correspondence.single_correspondence.single_correspondence;
        trail_details['parent'] = this.state.trail_info.trail_id;

        const formData = new FormData();

        if (this.state.my_computer.length) {

            for (let index = 0; index < this.state.my_computer.length; index++) {
                const element = this.state.my_computer[index];
                formData.append("my_computer[]", element);
            }
        }

        if (this.state.my_documents_selected.length) {
            formData.append("my_documents", JSON.stringify(this.state.my_documents_selected));
        }

        if (this.state.files_selected.length) {
            formData.append("files", JSON.stringify(this.state.files_selected));
        }

        formData.append("type", this.state.a_type);

        formData.append("trail_details", JSON.stringify(trail_details));

        this.props.serverForwardTrail(formData, this.props.auth.user.token);
    }

    doCompare = (selected_tags, contacts) => {
        let label = {};
        let value = {};
        let results = [];

        for (let index = 0; index < selected_tags.length; index++) {
            const element = selected_tags[index];

            label[element.label] = true;
            value[element.value] = true;

        }

        for (let i = 0; i < contacts.length; i++) {
            const el = contacts[i];
            let isValid = false
            if (el.tags) {
                let tags = JSON.parse(el.tags.replace(/'/g, '"'));
                // check if all selected tags exist in contacts
                //  let myValues = {...value}

                for (let ind = 0; ind < tags.length; ind++) {
                    const elt = tags[ind];
                    if (value[elt.value]) {
                        isValid = true;
                        break;
                    }

                    // check if all selected tags exist in contacts
                    // if (myValues[elt.value]) {
                    //     delete myValues[elt.value]
                    // }
                }
            }

            if (isValid) {
                results.push({ "value": el.contact_id, "label": `${el.first_name} ${el.last_name}` });
            }

            // check if all selected tags exist in contacts
            // if (!Object.keys(myValues).length) {
            //     results.push({"value": el.contact_id, "label": `${el.first_name} ${el.last_name}`});
            // }
        }

        return results;
    }

    filterByToTags = () => {
        if (this.state.trail_details.to_tags) {

            let result = this.doCompare(this.state.trail_details.to_tags, this.props.contacts.contacts);

            this.setState(prevState => ({
                trail_details: {                   // object that we want to update
                    ...prevState.trail_details,    // keep all other key-value pairs
                    "to": result     // update the value of specific key
                }
            }));
        }

        $("#dismiss_to_tag_modal").click();
    }

    filterByEmailToTags = () => {
        if (this.state.email_details.to_tags) {

            let result = this.doCompare(this.state.email_details.to_tags, this.props.contacts.contacts);

            this.setState(prevState => ({
                email_details: {                   // object that we want to update
                    ...prevState.email_details,    // keep all other key-value pairs
                    "recipients": result     // update the value of specific key
                }
            }));
        }

        $("#dismiss_email_to_tag_modal").click();
    }

    filterByEmailCCTags = () => {
        if (this.state.email_details.cc_tags) {

            let result = this.doCompare(this.state.email_details.cc_tags, this.props.contacts.contacts);

            this.setState(prevState => ({
                email_details: {                   // object that we want to update
                    ...prevState.email_details,    // keep all other key-value pairs
                    "cc": result     // update the value of specific key
                }
            }));
        }

        $("#dismiss_email_cc_tag_modal").click();
    }

    filterByCCTags = () => {
        if (this.state.trail_details.cc_tags) {

            let result = this.doCompare(this.state.trail_details.cc_tags, this.props.contacts.contacts);

            this.setState(prevState => ({
                trail_details: {                   // object that we want to update
                    ...prevState.trail_details,    // keep all other key-value pairs
                    "cc": result     // update the value of specific key
                }
            }));
        }

        $("#dismiss_cc_tag_modal").click();
    }

    archiveTrail = (data) => {
        // console.log(data);
        // this.setState({isLoading: "true"});
        this.props.serverArchiveTrail(data.trail_id, this.props.auth.user.token);
    }

    closeCorrespondence = () => {
        // console.log(this.props.correspondence.single_correspondence);
        this.props.serverCloseCorrespondence(this.props.correspondence.single_correspondence.single_correspondence.correspondence_id, this.props.auth.user.token);
    }

    setAttachmentInfo = (data, type) => {

        let id = "";
        let file_name = "";
        let folder = "";
        let ext = "";

        switch (type) {
            case 'attachment':
                id = data.aid;
                file_name = data.file_name;
                folder = "attachments";
                ext = data.ext;
                break;
            case 'document':
                id = data.did;
                file_name = data.name;
                folder = "documents";
                ext = data.ext;
                break;
            case 'file':
                id = data.fid;
                file_name = data.filename;
                folder = "file_manager/files";
                ext = data.filetype;
                break;
            case 'template':
                id = data.tid;
                file_name = data.template_name;
                folder = "templates";
                ext = data.ext;
                break;
            default:
                break;
        }

        if (ext === ".docx" || ext === ".pptx" || ext === ".xlsx" || ext === ".csv") {

            let kind = "";
            if (ext === '.docx') {
                kind = "word";
            } else if (ext === '.pptx') {
                kind = "slide";
            } else if (ext === '.xlsx' || ext === ".csv") {
                kind = "cell";
            }

            let config = {
                "document": {
                    "fileType": ext.replace('.', ''),
                    "permissions": {
                        "download": false,
                        "edit": false
                    },
                    "key": Math.floor(Math.random() * 999999999999) + "",
                    "title": file_name,
                    "url": `${types.SERVER_URL}static/${folder}/${id.toString()}${ext}`,
                    "user": {
                        "id": this.props.auth.user.contact_id,
                        "name": `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`
                    },
                },
                "documentType": kind,
                "editorConfig": {
                    "callbackUrl": `${types.SERVER_URL}documentserver_callback?file=${folder}:${id.toString()}${ext}`,
                    "customization": {
                        "forcesave": true
                    }
                }
            }

            this.setState({ document_config: config });
        }

        this.setState({ view_attachment_data: { "id": id, "file_name": file_name, "folder": folder, "ext": ext } })
    }


    clearAttachmentInfo = () => {
        this.setState({ view_attachment_data: {} });
    }

    selectFiles = (data) => {

        let newState = { ...this.state.selected_files };

        if (newState[data.fid]) {
            delete newState[data.fid];
        } else {
            newState[data.fid] = data;
        }

        this.setState({
            selected_files: newState
        });

        let results = Object.values(newState);

        // console.log(results);

        this.setState({ files_selected: results })
    }

    enterFolder = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === data.fid + '');

            let breadcrumbs = [...this.state.breadcrumbs, data];

            this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    enterFolderAlt = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === data.fid + '');
            this.setState({ file_list: file_list });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    searchAttachment = (value, type) => {
        switch (type) {
            case 'documents':
                // eslint-disable-next-line array-callback-return
                let document_list = this.props.templates.documents.filter((data, index) => {
                    if (data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return data;
                    }
                })
                this.setState({ document_list: document_list });
                break;
            case 'files':
                if (value) {
                    // eslint-disable-next-line array-callback-return
                    let file_list = this.props.attachments.all_files.filter((data, index) => {
                        if (data.filename.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                            return data;
                        }
                    })
                    this.setState({ file_list: file_list });
                } else {
                    let file_list = this.state.breadcrumbs[this.state.breadcrumbs.length - 1];
                    if (typeof file_list !== 'undefined') {
                        this.enterFolderAlt(file_list);
                    }
                }
                break;
            case 'templates':
                // eslint-disable-next-line array-callback-return
                let template_list = this.props.templates.templates.filter((data, index) => {
                    if (data.templates.template_name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return data;
                    }
                })
                this.setState({ template_list: template_list });
                break;
            default:
                break;
        }
    }


    fileType = (type) => {

        var image_type_list = ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi', '.raw', '.arw', '.cr2', '.nrw', '.k25', '.png', '.gif', '.webp', '.tiff', '.tif', '.bmp', '.dib', '.jp2', '.j2k', '.jpf', '.jpx', '.jpm', '.mj2',
            '.svg', '.svgz', '.ai', '.eps', '.ico'
        ];

        var video_type_list = ['.mp4', '.m4a', '.m4v', '.f4v', '.f4a', '.m4b', '.m4r', '.f4b', '.mov', '.3gp', '.3gp2', '.3g2', '.3gpp', '.3gpp2',
            '.ogg', '.oga', '.ogv', '.ogx', '.webm', '.avi', '.flv', '.mpg', '.wmv', '.mkv'
        ];

        var audio_type_list = ['.mid', '.mp3', '.m4a', '.flac', '.wav', '.amr'];

        var compressed_type_list = ['.001', '.7z', '.arj', '.bin', '.bzip', '.bzip2', '.cab', '.cpio', '.deb', '.ear', '.gz', '.hqx', '.jar', '.lha',
            '.rar', '.rpm', '.sea', '.sit', '.tar', '.war', '.zip', '.epub', '.bz2'
        ];

        var doc_type_list = ['.txt', '.php', '.py', '.html', '.css', '.js', '.sql'];


        if (image_type_list.includes(type)) {

            return 'image';

        } else if (video_type_list.includes(type)) {

            return 'video';

        } else if (audio_type_list.includes(type)) {

            return 'audio';

        } else if (compressed_type_list.includes(type)) {

            return 'zip';

        } else if (doc_type_list.includes(type)) {

            return 'docs';

        } else {

            return '';
        }
    }

    download = async (folder, id, ext, file_name) => {

        axios.get(`${types.SERVER_URL}static/${folder}/${id}${ext}`, {
            responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, `${file_name}${ext}`);
        });
    }

    nullifyTrail = (action, trail_id) => {
        this.props.serverNullifyTrail(action, trail_id, this.props.auth.user.token)
    }

    nextRouteStep = (type) => {

        let selected_route = this.state.route_list.filter(item => item.route_id === this.props.correspondence.single_correspondence.single_correspondence.route_id);

        let next_step_order = parseInt(this.props.correspondence.single_correspondence.single_correspondence.current_step_order) + 1;

        if (selected_route[0]) {
            let step = selected_route[0].route_steps.filter(item => parseInt(item.ordering) === next_step_order);

            if (step[0]) {

                if (type === "user") {
                    return this.contactDetails(step[0].user);
                } else if (type === "cc") {
                    if (step[0].cc) {
                        let cc = JSON.parse(step[0].cc.replace(/'/g, '"'));
                        let cc_arr = "";

                        // eslint-disable-next-line array-callback-return
                        cc.filter((data, index) => {
                            cc_arr = cc_arr + this.contactDetails(data) + ", ";
                        });

                        return cc_arr;
                    }
                }
            }
        }

        return ""

        // return this.props.auth.user.contact_id;
    }

    trailToShow = (trail_id) => {
        this.setState({ trail_to_show: trail_id });
        // document.getElementById(`ama${trail_id}`).scrollIntoView({ behavior: 'smooth' });
        $("#dismiss_view_structure_modal").click();
        // console.log(`ama${trail_id}`);
    }

    checkCurrentStepOrder = (route_step) => {
        let valid = 0;

        if (typeof this.state.correspondence_details.route !== "undefined") {
            if (typeof this.state.correspondence_details.route.route_steps !== "undefined") {
                // eslint-disable-next-line array-callback-return
                this.state.correspondence_details.route.route_steps.filter((data, i) => {
                    // console.log(this.props.correspondence.single_correspondence.single_correspondence);
                    if (data.step_id + '' === route_step + '' && this.props.correspondence.single_correspondence.single_correspondence.current_step_order + '' === data.ordering + '') {
                        if (data.user === this.props.auth.user.contact_id) {
                            valid = 1;
                            return;
                        }
                    }
                });
            }
        }

        return valid;
    }

    uploadToFm = (category, id) => {
        let obj = {
            "category": category,
            "id": id
        }
        this.props.serverUploadToFm(obj, this.props.auth.user.token);
    }

    render() {
        return (
            <>
                <Main>
                    <CorrespondenceDetails
                        c_state={{ ...this.state }}
                        auth={this.props.auth}
                        isLoading={this.props.correspondence.isLoading}
                        handleChange={this.handleChange}
                        reload_correspondence={this.reloadCorrespondence}
                        single_correspondence={this.props.correspondence.single_correspondence}
                        contact_details={this.contactDetails}
                        nullify_action={this.nullifyAction}
                        nullify_trail={this.nullifyTrail}
                        clear_modal_contents={this.clearModalContents}
                        set_trail_info={this.setTrailInfo}
                        set_a_type={this.setAType}
                        add_action={this.addAction}
                        forward_trail={this.forwardTrail}
                        forward_to_contacts={this.forwardToContacts}
                        filter_by_to_tags={this.filterByToTags}
                        filter_by_cc_tags={this.filterByCCTags}
                        filter_by_email_to_tags={this.filterByEmailToTags}
                        filter_by_email_cc_tags={this.filterByEmailCCTags}
                        archive_trail={this.archiveTrail}
                        close_correspondence={this.closeCorrespondence}
                        send_email={this.sendEmail}
                        next_route_step={this.nextRouteStep}
                        trailToShow={this.trailToShow}
                        check_current_step_user={this.checkCurrentStepOrder}

                        // attachments component
                        templates={this.props.templates}
                        attachments={this.props.attachments}
                        select_my_document={this.selectMyDocument}
                        select_files={this.selectFiles}
                        enter_folder={this.enterFolder}
                        click_breadcrumbs={this.clickBreadCrumbs}
                        select_template={this.selectTemplate}
                        use_template={this.useTemplate}
                        save_attachments={this.saveAttachments}
                        clear_attachments={this.clearAttachments}
                        search_attachment={this.searchAttachment}
                        // view attachments
                        set_attachment_info={this.setAttachmentInfo}
                        clear_attachment_info={this.clearAttachmentInfo}
                        file_type={this.fileType}
                        download={this.download}
                        upload_to_fm={this.uploadToFm}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        correspondence: { ...state.correspondence },
        contacts: { ...state.contacts },
        templates: { ...state.templates },
        routes: { ...state.routes },
        attachments: { ...state.attachments }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        clearData,
        // checkUser,
        serverGetTags,
        serverGetContacts,
        serverGetCorrespondenceDetails,
        serverGetTemplates,
        serverGetDocuments,
        serverUseTemplate,
        serverSaveAttachments,
        serverClearSavedAttachments,
        serverNullifyAction,
        serverNullifyTrail,
        serverAddAction,
        serverForwardTrail,
        serverArchiveTrail,
        serverCloseCorrespondence,
        serverSendEmail,
        serverGetRoutes,
        serverGetAllFiles,
        serverUploadToFm
    }
)(CorrespondenceDetailsContainer)
