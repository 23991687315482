import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'

const ViewCorrespondence = (props) => {

    // console.log("correspondence", props.c_state.correspondence);

    return (
        <div className="content-wrapper">
            {/* <!-- Content Header (Page header) --> */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Correspondence</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                <li className="breadcrumb-item active">Correspondence</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
            </section>

            {/* <!-- Main content --> */}
            <section className="content">
                <div className="row">
                    <div className="col-md-3">
                        {
                            props.auth.user ?
                                props.auth.user.privileges.includes("compose_correspondence") ?
                                    <Link to="/compose_correspondence" className="btn btn-primary btn-block mb-3">Compose</Link>
                                    : null
                                : null
                        }
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Folders</h3>

                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <ul className="nav nav-pills flex-column">
                                    <li className="nav-item active">
                                        <Link to="#" onClick={() => props.load_correspondence('Inbox')} className="nav-link" style={{ color: props.c_state.correspondence_category === 'Inbox' ? 'red' : null }}>
                                            <i className="fas fa-inbox"></i> Incoming
                                            {props.c_state.correspondence_category === 'Inbox' ?
                                                <span className="badge bg-primary float-right">{props.c_state.correspondence.limit}</span>
                                                : null}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" onClick={() => props.load_correspondence('Sent')} className="nav-link" style={{ color: props.c_state.correspondence_category === 'Sent' ? 'red' : null }}>
                                            <i className="far fa-envelope"></i> Outgoing
                                            {props.c_state.correspondence_category === 'Sent' ?
                                                <span className="badge bg-info float-right">{props.c_state.correspondence.limit ? props.c_state.correspondence.limit : ''}</span>
                                                : null}
                                        </Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to="#" onClick={() => props.load_correspondence('Drafts')} className="nav-link" style={{ color: props.c_state.correspondence_category === 'Drafts' ? 'red' : null }}>
                                            <i className="far fa-file-alt"></i> Drafts
                                            {props.c_state.correspondence_category === 'Drafts' ?
                                                <span className="badge bg-success float-right">{props.c_state.correspondence.limit ? props.c_state.correspondence.limit : ''}</span>
                                                : null}
                                        </Link>
                                    </li> */}
                                    <li className="nav-item">
                                        <Link to="#" onClick={() => props.load_correspondence('Archived')} className="nav-link" style={{ color: props.c_state.correspondence_category === 'Archived' ? 'red' : null }}>
                                            <i className="fa fa-archive"></i> Worked On
                                            {props.c_state.correspondence_category === 'Archived' ?
                                                <span className="badge bg-danger float-right">{props.c_state.correspondence.limit ? props.c_state.correspondence.limit : ''}</span>
                                                : null}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Labels</h3>

                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <ul className="nav nav-pills flex-column">
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link">
                                            <i className="far fa-circle text-danger"></i>
                                            Important
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link">
                                            <i className="far fa-circle text-warning"></i> Promotions
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="#" className="nav-link">
                                            <i className="far fa-circle text-primary"></i>
                                            Social
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-9">
                        <div className="card card-primary card-outline">
                            <div className="card-header">
                                <h3 className="card-title">{props.c_state.correspondence_category ? props.c_state.correspondence_category : "Inbox"}</h3>
                                <div align="right">
                                    <button type="button" className="btn btn-sm btn-outline-primary" onClick={props.reload_correspondence}><i className="fa fa-sync"></i> Reload</button>&nbsp;
                                    <button type="button" className="btn btn-sm btn-outline-success" data-toggle="modal" data-target="#search_tag_modal"><i className="fa fa-filter"></i> Filter By Tags</button>
                                    <button type="button" className="btn btn-sm btn-outline-default">
                                        <div className="input-group input-group-sm" style={{ width: 200 }}>
                                            <input type="search" className="form-control form-control-sm" placeholder="Type your keywords here" onChange={(event) => props.search_correspondence(event.target.value)} />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card card-body p-0">
                            <div className="mailbox-controls">
                                {/* <button type="button" className="btn btn-default btn-sm checkbox-toggle"><i className="far fa-square"></i>
                                </button>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="far fa-trash-alt"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="fas fa-reply"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="fas fa-share"></i>
                                    </button>
                                </div> 
                                <button type="button" className="btn btn-outline-info btn-sm" title="Reload your correspondence" onClick={props.reload_correspondence}>
                                    <i className="fas fa-sync-alt"> Reload</i>
                                </button>*/}
                                <div className="float-right">
                                    {props.c_state.start}-{props.c_state.finish}/{props.c_state.correspondence.limit}
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-default btn-sm">
                                            <i className="fas fa-chevron-left"></i>
                                        </button>
                                        <button type="button" className="btn btn-default btn-sm">
                                            <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive mailbox-messages">
                                {
                                    props.isLoading === "true" ?
                                        <div align="center">
                                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>We are loading your correspondences. Please wait...</p></span>
                                        </div>
                                        :
                                        props.c_state.correspondence.correspondence ?
                                            <table className="table table-hover table-striped" style={{ width: '100%' }}>
                                                <tbody>
                                                    {
                                                        props.c_state.correspondence.correspondence.map((data) => {
                                                            return (
                                                                <tr key={data.trail_id}>
                                                                    {/* <td>
                                                                        <div className="icheck-primary">
                                                                            <input type="checkbox" value="" id="check4" />
                                                                            <label htmlFor="check4"></label>
                                                                        </div>
                                                                    </td> */}
                                                                    {/* <td className="mailbox-star"><Link to="#"><i className="fas fa-star text-warning"></i></Link></td> */}
                                                                    <td className="mailbox-star"><Link to="#" onClick={() => props.single_correspondence(data)}>#{data.correspondence[0].correspondence_id}#</Link></td>
                                                                    <td className="mailbox-name"><Link to="#" onClick={() => props.single_correspondence(data)}>{props.c_state.correspondence_category === "Sent" || props.c_state.correspondence_category === "Drafts" ? `To: ${props.contact_details(data.c_to)} ${data.number_of_recipients > 1 ? '(' + data.number_of_recipients + ')' : ''}` : props.contact_details(data.c_from)}</Link></td>
                                                                    <td className="mailbox-subject">{data.seen === "No" ? <b>{data.correspondence[0].subject}</b> : <>{data.correspondence[0].subject}</>}</td>
                                                                    <td className="mailbox-attachment">
                                                                        {data.correspondence[0].confidential === "true" ?
                                                                            <span className="badge float-right bg-info" style={{ marginLeft: 10 }}> Confidential</span>
                                                                            : ''}
                                                                        <span className={`badge float-right ${data.correspondence[0].priority === "High Priority" ? 'bg-danger' : data.correspondence[0].priority === "Medium Priority" ? 'bg-warning' : 'bg-primary'}`}>{data.correspondence[0].priority}</span>
                                                                    </td>
                                                                    <td className="mailbox-date">{data.date_created}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : <div align="center"><br /><br /><p>Sorry! Your correspondence failed to load due to poor internet connection.<br />Click the reload icon to reload.</p></div>
                                }
                            </div>
                        </div>
                        {/* <div className="card-footer p-0">
                            <div className="mailbox-controls">
                                <button type="button" className="btn btn-default btn-sm checkbox-toggle">
                                    <i className="far fa-square"></i>
                                </button>
                                <div className="btn-group">
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="far fa-trash-alt"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="fas fa-reply"></i>
                                    </button>
                                    <button type="button" className="btn btn-default btn-sm">
                                        <i className="fas fa-share"></i>
                                    </button>
                                </div>
                                <button type="button" className="btn btn-default btn-sm" title="Reload your correspondence" onClick={props.reload_correspondence}>
                                    <i className="fas fa-sync-alt"></i>
                                </button>
                                <div className="float-right">
                                    {props.c_state.start}-{props.c_state.finish}/{props.c_state.correspondence.limit}
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-default btn-sm">
                                            <i className="fas fa-chevron-left"></i>
                                        </button>
                                        <button type="button" className="btn btn-default btn-sm">
                                            <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* Modals */}
                <div className="modal fade" id="search_tag_modal">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Select Tags</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group col-12">
                                    <Select
                                        options={props.c_state.tags_dropdown}
                                        name="tags"
                                        // value={props.c_state.task_details.tags ? props.c_state.task_details.tags : []}
                                        id="custom_select"
                                        value={typeof props.c_state.correspondence_details.search_params === "string" ? JSON.parse(props.c_state.correspondence_details.search_params.replace(/'/g, '"')) : props.c_state.correspondence_details.search_params}
                                        onChange={(value) => props.handleChange({ "field": "search_params", "value": value })}
                                        isMulti style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select" />
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_search_params_modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={props.search_correspondence_by_tags}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}

export default ViewCorrespondence
