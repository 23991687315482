import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'

const EditRoute = (props) => {

    // console.log("route_steps", props.contact_tags(2));

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Edit Route</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/view_routes">View Routes</Link></li>
                                    <li className="breadcrumb-item active">Edit Route</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="col-md-12">
                        <div className="card" >
                            <div className="card-header">
                                <h3 className="card-title">Make changes to {props.routes.edit_routes.title} </h3>

                                <div className="card-tools">
                                    {props.routes.edit_route_steps ?
                                        <div align="center">
                                            <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}</span>
                                            <button className="btn btn-sm btn-outline-primary" title="Save route" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.finish}><i className="fas fa-save"></i> Finish</button> &nbsp;&nbsp;
                                        </div>
                                        : null}
                                </div>
                            </div>
                            <div className="card-body">
                                {
                                    props.c_state.route_details.correspondence_id ?
                                        <>
                                            <p>Correspondence Subject: <strong>{props.c_state.route_details.correspondence_details.subject}</strong></p>
                                            <p>Correspondence ID: <Link to="#">#{props.c_state.route_details.correspondence_id}#</Link></p>
                                        </>
                                        : null
                                }
                                <div className="form-group col-12">
                                    <label htmlFor="title">Route Name *</label>
                                    <input type="text" value={props.c_state.route_details.title ? props.c_state.route_details.title : ''} onChange={(event) => props.handleChange({ "field": "title", "value": event.target.value }, "routes")} className="form-control" name="title" placeholder="Route Name" />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="title">Category *</label>
                                    <select className="form-control" value={props.c_state.route_details.category ? props.c_state.route_details.category : ''} onChange={(event) => props.handleChange({ "field": "category", "value": event.target.value }, "routes")}>
                                        <option value="" disabled>Select Category</option>
                                        <option value="Private">Private</option>
                                        {
                                            props.auth.user ?
                                                props.auth.user.privileges.includes("add_public_routes") ?
                                                    <option value="Public">Public</option>
                                                    : null
                                                : null
                                        }
                                    </select>
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="tags">Tags</label>
                                    <Select
                                        options={props.c_state.tags_dropdown}
                                        name="tags"
                                        value={props.c_state.route_details.tags ? typeof props.c_state.route_details.tags !== "string" ? props.c_state.route_details.tags : JSON.parse(props.c_state.route_details.tags.replace(/'/g, '"')) : ''}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "tags", "value": value }, "routes")}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select" />
                                </div>
                                <br />
                                <hr />
                                <div className="card-header">
                                    <h3 className="card-title">Route Steps </h3>

                                    <div className="card-tools">
                                        <button className="btn btn-sm btn-outline-info" title="Add Route Step" data-toggle="modal" data-target="#add_route_step_modal" onClick={props.clear_modal_contents}><i className="fas fa-plus"></i> Add Route Step</button>
                                    </div>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Recipient</th>
                                            <th>Deadline (Hours)</th>
                                            <th>Auto Forward</th>
                                            <th>Can Close</th>
                                            {/* <th>Expected Response Date</th> */}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.routes.edit_route_steps ?
                                            // eslint-disable-next-line array-callback-return
                                            props.routes.edit_route_steps.map((data, i) => {
                                                return (
                                                    <tr key={i} style={props.c_state.route_details.correspondence_details ? props.c_state.route_details.correspondence_details.current_step_order === data.ordering ? { color: 'red', fontWeight: 'bolder' } : null : null}>
                                                        <td>{i + 1}</td>
                                                        <td>{props.contact_details(data.user)}</td>
                                                        <td>{data.deadline}</td>
                                                        <td>{data.auto_forward}</td>
                                                        <td>{data.can_close}</td>
                                                        {/* <td>{data.expected_response_date}</td> */}
                                                        <td className="align-middle">
                                                            <div className="btn-group btn-group-sm">
                                                                <button className="btn btn-info" title="Edit Route Step" data-toggle="modal" data-target="#edit_route_step_modal" onClick={() => props.set_modal_content(data)}><i className="fas fa-edit"></i></button>&nbsp;
                                                                <button className="btn btn-danger" title="Delete Route Step" onClick={() => window.confirm(`Are you sure you want to delete?`) ? props.delete_route_step(data.step_id) : console.log(i)}><i className="fas fa-trash"></i></button>&nbsp;
                                                                {i !== 0 ?
                                                                    <><button className="btn btn-default" title="Move Up" onClick={() => props.move_up(data)}><i className="fas fa-arrow-up"></i></button>&nbsp;</>
                                                                    : null}
                                                                {props.routes.edit_route_steps.length - 1 !== i ?
                                                                    <><button className="btn btn-default" title="Move Down" onClick={() => props.move_down(data)}><i className="fas fa-arrow-down"></i></button>&nbsp;</>
                                                                    : null}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            : null
                                        }
                                    </tbody>
                                </table>
                                <hr />
                                {props.routes.edit_route_steps ?
                                    <div align="center">
                                        <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}</span>
                                        <button className="btn btn-primary" title="Save route" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.finish}><i className="fas fa-save"></i> Finish</button>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>

                    {/* Modals */}

                    <div className="modal fade" id="add_route_step_modal" style={{ overflow: "auto" }}>
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">New Route Step</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <label htmlFor="user">Recipient *</label>
                                        <Select
                                            options={props.c_state.contacts_dropdown}
                                            name="user"
                                            value={props.c_state.route_step_details.user ? typeof props.c_state.route_step_details.user !== "string" ? props.c_state.route_step_details.user : JSON.parse(props.c_state.route_step_details.user.replace(/'/g, '"')) : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "user", "value": value }, "route_steps")}
                                            // isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="To"
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="cc">FYI</label>
                                        <Select
                                            options={props.c_state.contacts_dropdown}
                                            name="cc"
                                            value={props.c_state.route_step_details.cc ? typeof props.c_state.route_step_details.cc !== "string" ? props.c_state.route_step_details.cc : JSON.parse(props.c_state.route_step_details.cc.replace(/'/g, '"')) : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "cc", "value": value }, "route_steps")}
                                            // isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="CC"
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="deadline">Deadline (hours) *</label>
                                        <input type="text" value={props.c_state.route_step_details.deadline ? props.c_state.route_step_details.deadline : ''} onChange={(event) => props.handleChange({ "field": "deadline", "value": event.target.value }, "route_steps")} className="form-control" name="deadline" placeholder="Deadline" />
                                    </div>
                                    {/* <div className="form-group col-12">
                                        <label htmlFor="expected_response_date">Expected Response Date *</label>
                                        <input type="date" value={props.c_state.route_step_details.expected_response_date ? props.c_state.route_step_details.expected_response_date : ''} onChange={(event) => props.handleChange({ "field": "expected_response_date", "value": event.target.value }, "route_steps")} className="form-control" name="expected_response_date" placeholder="Expected Response Date" />
                                    </div> */}
                                    <div className="form-group col-12">
                                        <label htmlFor="auto_forward">Auto Forward *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "auto_forward", "value": event.target.value }, "route_steps")} value={props.c_state.route_step_details.auto_forward ? props.c_state.route_step_details.auto_forward : ""}>
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="can_close">Can Close *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "can_close", "value": event.target.value }, "route_steps")} value={props.c_state.route_step_details.can_close ? props.c_state.route_step_details.can_close : ""}>
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_route_step_modal" onClick={props.clear_modal_contents}>Close</button>
                                    <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.add_route_step}>Save</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit_route_step_modal" style={{ overflow: "auto" }}>
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Edit Route Step</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <label htmlFor="user">Recipient *</label>
                                        <Select
                                            options={props.c_state.contacts_dropdown}
                                            name="user"
                                            value={props.c_state.route_step_details.user ? props.contact_tags(props.c_state.route_step_details.user, "user") : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "user", "value": value }, "route_steps")}
                                            // isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="To"
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="cc">FYI</label>
                                        <Select
                                            options={props.c_state.contacts_dropdown}
                                            name="cc"
                                            value={props.c_state.route_step_details.cc ? props.contact_tags(props.c_state.route_step_details.cc, "cc") : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "cc", "value": value }, "route_steps")}
                                            isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="CC"
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="deadline">Deadline (hours) *</label>
                                        <input type="text" value={props.c_state.route_step_details.deadline} onChange={(event) => props.handleChange({ "field": "deadline", "value": event.target.value }, "route_steps")} className="form-control" name="deadline" placeholder="Deadline" />
                                    </div>
                                    {/* <div className="form-group col-12">
                                        <label htmlFor="expected_response_date">Expected Response Date *</label>
                                        <input type="date" value={props.c_state.route_step_details.expected_response_date} onChange={(event) => props.handleChange({ "field": "expected_response_date", "value": event.target.value }, "route_steps")} className="form-control" name="expected_response_date" placeholder="Expected Response Date" />
                                    </div> */}
                                    <div className="form-group col-12">
                                        <label htmlFor="auto_forward">Auto Forward *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "auto_forward", "value": event.target.value }, "route_steps")} value={props.c_state.route_step_details.auto_forward}>
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="can_close">Can Close *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "can_close", "value": event.target.value }, "route_steps")} value={props.c_state.route_step_details.can_close}>
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_route_step_modal" onClick={props.clear_modal_contents}>Close</button>
                                    <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.edit_route_step}>Save</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>

    )
}

export default EditRoute