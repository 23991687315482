import React, { Component } from 'react'

import Conversions from './Conversions';
import Main from './../LayoutComponent/Main';
import { connect } from 'react-redux';
// import { checkUser } from '../../actions/AuthAction';
import { serverLoad, serverConvert } from '../../actions/ConversionsAction';
import { serverGetAllFiles } from '../../actions/AttachmentsAction';
import { serverGetDocuments } from '../../actions/TemplatesAction';
import fileDownload from 'js-file-download';
import axios from 'axios'
import types from '../../types';
// const $ = require("jquery");

class ConversionsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: "my_computer",
            my_computer: {},
            my_documents: {},
            file_list: [],
            breadcrumbs: [],
            document_list: [],
            document_config: {},
            view_attachment_data: {}
        }

        this.changeTab = this.changeTab.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.convert = this.convert.bind(this);
        this.searchAttachment = this.searchAttachment.bind(this);
        this.enterFolder = this.enterFolder.bind(this);
        this.enterFolderAlt = this.enterFolderAlt.bind(this);
        this.clickBreadCrumbs = this.clickBreadCrumbs.bind(this);
        this.searchAttachment = this.searchAttachment.bind(this);
        this.setAttachmentInfo = this.setAttachmentInfo.bind(this);
        this.clearAttachmentInfo = this.clearAttachmentInfo.bind(this);
        this.fileType = this.fileType.bind(this);
        this.download = this.download.bind(this);
        this.enterFolder = this.enterFolder.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user === null) {
            this.props.history.push("/")
        }

        if (!this.props.templates.documents.length) {
            this.props.serverGetDocuments(this.props.auth.user.token);
        } else {
            this.setState({ document_list: [...this.props.templates.documents] });
        }


        if (!this.props.attachments.all_files.length) {
            this.props.serverGetAllFiles(this.props.auth.user.token);
        } else {
            if (!this.state.breadcrumbs.length) {

                let parent = this.props.attachments.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if (parent.length) {
                    file_list = this.props.attachments.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.conversions.conversions.file_link !== this.props.conversions.conversions.file_link) {

            //$("#converted_file").click();
            if (window.document.getElementById("converted_file")) {
                window.document.getElementById("converted_file").click()
            }
            // console.log(prevProps.conversions.conversions.file_link, this.props.conversions.conversions.file_link);

            // axios.get(this.props.conversions.conversions.file_link, {
            //     responseType: 'blob',
            // }).then(res => {
            //     fileDownload(res.data, this.props.conversions.conversions.file_name);
            // });
        }

        if (prevProps.attachments.all_files !== this.props.attachments.all_files) {

            if (!this.state.breadcrumbs.length) {

                let parent = this.props.attachments.all_files.filter(item => item.filename === '/');

                let file_list = [];
                let breadcrumbs = [];

                if (parent.length) {
                    file_list = this.props.attachments.all_files.filter(item => item.parent + '' === parent[0].fid + '');

                    breadcrumbs = [...this.state.breadcrumbs, parent[0]];
                }

                return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
            }
        }

        if (prevProps.templates.documents !== this.props.templates.documents) {
            this.setState({ document_list: [...this.props.templates.documents] });
        }
    }

    changeTab = (tab) => {
        this.setState({ tab: tab });
    }

    handleChange = (data, type) => {
        switch (type) {
            // generic attachments modal
            case 'my_computer':

                this.setState({ my_computer: data });

                break;

            default:
                break;
        }

        // console.log(data);
    }

    convert = (type, method, data) => {

        // Create an object of formData
        const formData = new FormData();

        switch (type) {
            case "my_computer":

                if (this.state.my_computer.name) {
                    formData.append("my_computer", this.state.my_computer);
                    formData.append("method", method);
                    this.props.serverConvert(formData, this.props.auth.user.token)
                }

                break;

            case "file_manager":

                if (data) {
                    formData.append("file_manager", JSON.stringify(data));
                    formData.append("method", method);
                    this.props.serverConvert(formData, this.props.auth.user.token);
                }

                break;

            case "my_documents":

                if (data) {
                    formData.append("my_documents", JSON.stringify(data));
                    formData.append("method", method);
                    this.props.serverConvert(formData, this.props.auth.user.token);
                }

                break;

            default:
                break;
        }
    }

    clickBreadCrumbs = (index) => {
        let breadcrumbs = [...this.state.breadcrumbs];
        let selected_breadcrumbs = this.state.breadcrumbs[index];
        let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === selected_breadcrumbs.fid + '');
        breadcrumbs = breadcrumbs.slice(0, index + 1);
        return this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
    }

    searchAttachment = (value, type) => {
        switch (type) {
            case 'documents':
                // eslint-disable-next-line array-callback-return
                let document_list = this.props.templates.documents.filter((data, index) => {
                    if (data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return data;
                    }
                })
                this.setState({ document_list: document_list });
                break;
            case 'files':
                if (value) {
                    // eslint-disable-next-line array-callback-return
                    let file_list = this.props.attachments.all_files.filter((data, index) => {
                        if (data.filename.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                            return data;
                        }
                    })
                    this.setState({ file_list: file_list });
                } else {
                    let file_list = this.state.breadcrumbs[this.state.breadcrumbs.length - 1];
                    if (typeof file_list !== 'undefined') {
                        this.enterFolderAlt(file_list);
                    }
                }
                break;
            case 'templates':
                // eslint-disable-next-line array-callback-return
                let template_list = this.props.templates.templates.filter((data, index) => {
                    if (data.templates.template_name.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                        return data;
                    }
                })
                this.setState({ template_list: template_list });
                break;
            default:
                break;
        }
    }

    enterFolder = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === data.fid + '');

            let breadcrumbs = [...this.state.breadcrumbs, data];

            this.setState({ file_list: file_list, breadcrumbs: breadcrumbs });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    enterFolderAlt = (data) => {
        if (data.type === "folder") {
            let file_list = this.props.attachments.all_files.filter(item => item.parent + '' === data.fid + '');
            this.setState({ file_list: file_list });
        } else if (data.type === "file") {
            this.selectFiles(data);
        }
    }

    setAttachmentInfo = (data, type) => {

        let id = "";
        let file_name = "";
        let folder = "";
        let ext = "";

        switch (type) {
            case 'attachment':
                id = data.aid;
                file_name = data.file_name;
                folder = "attachments";
                ext = data.ext;
                break;
            case 'document':
                id = data.did;
                file_name = data.name;
                folder = "documents";
                ext = data.ext;
                break;
            case 'file':
                id = data.fid;
                file_name = data.filename;
                folder = "file_manager/files";
                ext = data.filetype;
                break;
            case 'template':
                id = data.tid;
                file_name = data.template_name;
                folder = "templates";
                ext = data.ext;
                break;
            default:
                break;
        }

        if (ext === ".docx" || ext === ".pptx" || ext === ".xlsx" || ext === ".csv") {

            let kind = "";
            if (ext === '.docx') {
                kind = "word";
            } else if (ext === '.pptx') {
                kind = "slide";
            } else if (ext === '.xlsx' || ext === ".csv") {
                kind = "cell";
            }

            let config = {
                "document": {
                    "fileType": ext.replace('.', ''),
                    "permissions": {
                        "download": false,
                        "edit": false
                    },
                    "key": Math.floor(Math.random() * 999999999999) + "",
                    "title": file_name,
                    "url": `${types.SERVER_URL}static/${folder}/${id.toString()}${ext}`,
                    "user": {
                        "id": this.props.auth.user.contact_id,
                        "name": `${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`
                    },
                },
                "documentType": kind,
                "editorConfig": {
                    "callbackUrl": `${types.SERVER_URL}documentserver_callback?file=${folder}:${id.toString()}${ext}`,
                    "customization": {
                        "forcesave": true
                    }
                }
            }

            this.setState({ document_config: config });
        }

        this.setState({ view_attachment_data: { "id": id, "file_name": file_name, "folder": folder, "ext": ext } })
    }

    clearAttachmentInfo = () => {
        this.setState({ view_attachment_data: {} });
    }

    fileType = (type) => {

        var image_type_list = ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.jfi', '.raw', '.arw', '.cr2', '.nrw', '.k25', '.png', '.gif', '.webp', '.tiff', '.tif', '.bmp', '.dib', '.jp2', '.j2k', '.jpf', '.jpx', '.jpm', '.mj2',
            '.svg', '.svgz', '.ai', '.eps', '.ico'
        ];

        var video_type_list = ['.mp4', '.m4a', '.m4v', '.f4v', '.f4a', '.m4b', '.m4r', '.f4b', '.mov', '.3gp', '.3gp2', '.3g2', '.3gpp', '.3gpp2',
            '.ogg', '.oga', '.ogv', '.ogx', '.webm', '.avi', '.flv', '.mpg', '.wmv', '.mkv'
        ];

        var audio_type_list = ['.mid', '.mp3', '.m4a', '.flac', '.wav', '.amr'];

        var compressed_type_list = ['.001', '.7z', '.arj', '.bin', '.bzip', '.bzip2', '.cab', '.cpio', '.deb', '.ear', '.gz', '.hqx', '.jar', '.lha',
            '.rar', '.rpm', '.sea', '.sit', '.tar', '.war', '.zip', '.epub', '.bz2'
        ];

        var doc_type_list = ['.txt', '.php', '.py', '.html', '.css', '.js', '.sql'];


        if (image_type_list.includes(type)) {

            return 'image';

        } else if (video_type_list.includes(type)) {

            return 'video';

        } else if (audio_type_list.includes(type)) {

            return 'audio';

        } else if (compressed_type_list.includes(type)) {

            return 'zip';

        } else if (doc_type_list.includes(type)) {

            return 'docs';

        } else {

            return '';
        }
    }

    download = async (folder, id, ext, file_name) => {

        axios.get(`${types.SERVER_URL}static/${folder}/${id}${ext}`, {
            responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, `${file_name}${ext}`);
        });
    }

    render() {
        return (
            <>
                <Main>
                    <Conversions
                        c_state={{ ...this.state }}
                        conversions={this.props.conversions}
                        auth={this.props.auth}
                        change_tab={this.changeTab}
                        handleChange={this.handleChange}
                        convert={this.convert}
                        click_breadcrumbs={this.clickBreadCrumbs}
                        search_attachment={this.searchAttachment}
                        set_attachment_info={this.setAttachmentInfo}
                        clear_attachment_info={this.clearAttachmentInfo}
                        file_type={this.fileType}
                        download={this.download}
                        enter_folder={this.enterFolder}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        conversions: { ...state.conversions },
        templates: { ...state.templates },
        attachments: { ...state.attachments }
    }
}

export default connect(mapStateToProps, {
    // checkUser,
    serverLoad,
    serverConvert,
    serverGetAllFiles,
    serverGetDocuments
})(ConversionsContainer)


