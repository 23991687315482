import React from 'react'
import { Link } from "react-router-dom";
// import types from '../../types';
import Select from 'react-select'

const Roles = (props) => {

    // console.log(props.privileges.contact_privileges);

    return (
        <>
            {/* <!-- Content Wrapper. Contains page content --> */}
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Create / Assign User Roles</h1>
                            </div>
                            {/* <!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item active">Roles</li>
                                </ol>
                            </div>
                            {/* <!-- /.col --> */}
                        </div>
                        {/* <!-- /.row --> */}
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </div>
                {/* <!-- /.content-header --> */}

                {/* <!-- Main content --> */}
                <section className="content">
                    {
                        props.roles.isLoading === "true" ?
                            <div align="center">
                                <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>We are loading some data. Please wait...</p></span>
                            </div>
                            :
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="callout callout-info">
                                            <h5><i className="fas fa-info"></i> Note:</h5>
                                            Create or assign pre-defined roles to software users
                                        </div>

                                        <div className="invoice p-3 mb-3">
                                            <div className="card card-primary card-outline card-outline-tabs">
                                                <div className="card-header p-0 border-bottom-0">
                                                    <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                                        {
                                                            props.auth.user ?
                                                                props.auth.user.privileges.includes("create_user_roles") ?
                                                                    <li className="nav-item">
                                                                        <a className="nav-link active" id="custom-tabs-four-create-user-roles-tab" data-toggle="pill" href="#custom-tabs-four-create-user-roles" role="tab" aria-controls="custom-tabs-four-create-user-roles" aria-selected="true">Create User Roles</a>
                                                                    </li>
                                                                    : null
                                                                : null
                                                        }
                                                        {
                                                            props.auth.user ?
                                                                props.auth.user.privileges.includes("update_user_roles") ?
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="custom-tabs-four-update-user-roles-tab" data-toggle="pill" href="#custom-tabs-four-update-user-roles" role="tab" aria-controls="custom-tabs-four-update-user-roles" aria-selected="false">Update User Roles</a>
                                                                    </li>
                                                                    : null
                                                                : null
                                                        }
                                                        {
                                                            props.auth.user ?
                                                                props.auth.user.privileges.includes("assign_user_roles") ?
                                                                    <li className="nav-item">
                                                                        <a className="nav-link" id="custom-tabs-four-assign-user-roles-tab" data-toggle="pill" href="#custom-tabs-four-assign-user-roles" role="tab" aria-controls="custom-tabs-four-assign-user-roles" aria-selected="false">Assign User Roles</a>
                                                                    </li>
                                                                    : null
                                                                : null
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="card-body">
                                                    <div className="tab-content" id="custom-tabs-four-tabContent">
                                                        {
                                                            props.auth.user ?
                                                                props.auth.user.privileges.includes("create_user_roles") ?
                                                                    <div className={`tab-pane fade ${props.auth.user.privileges.includes("create_user_roles") ? 'show active' : ''}`} id="custom-tabs-four-create-user-roles" role="tabpanel" aria-labelledby="custom-tabs-four-create-user-roles-tab">
                                                                        {
                                                                            props.roles.isLoading === "true" ?
                                                                                <div align="center">
                                                                                    <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>We are loading your contact list. Please wait...</p></span>
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-12">
                                                                                            &nbsp;&nbsp;<label htmlFor="users">Role * </label>
                                                                                            <div className="form-group col-md-12">
                                                                                                <input type="text" value={props.c_state.role_details.new_role ? props.c_state.role_details.new_role : ''} onChange={(event) => props.handleChange({ "field": "new_role", "value": event.target.value })} className="form-control" name="role" placeholder="E.g. Administrator" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row">
                                                                                        <div class="col-12 table-responsive">
                                                                                            <table class="table table-striped">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>#</th>
                                                                                                        <th>Privilege</th>
                                                                                                        {/* <th>Status</th> */}
                                                                                                        <th>Action</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        props.roles.privileges.map((data, i) => {
                                                                                                            return (
                                                                                                                <tr key={data.id}>
                                                                                                                    <td><span>{i + 1}</span></td>
                                                                                                                    <td>{data.description}</td>
                                                                                                                    {/* <td></td> */}
                                                                                                                    <td>
                                                                                                                        <div className="icheck-primary">
                                                                                                                            <input type="checkbox" id="check4" checked={props.c_state.selected_privileges[data.id] ? true : false} onChange={() => props.select_privilege(data)} />
                                                                                                                            <label htmlFor="check4"></label>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div align="center">
                                                                                        <button type="button" class="btn btn-primary btn-md" onClick={() => props.create_user_role()}><i class="fa fa-save"></i> Save</button>
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                    : null
                                                                : null
                                                        }
                                                        {
                                                            props.auth.user ?
                                                                props.auth.user.privileges.includes("update_user_roles") ?
                                                                    <div className={`tab-pane fade ${props.auth.user.privileges.includes("update_user_roles") ? 'show' : ''}`} id="custom-tabs-four-update-user-roles" role="tabpanel" aria-labelledby="custom-tabs-four-update-user-roles-tab">
                                                                        {
                                                                            props.roles.isLoading === "true" ?
                                                                                <div align="center">
                                                                                    <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>We are loading your contact list. Please wait...</p></span>
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <div className="row">
                                                                                        <div className="col-12">
                                                                                            &nbsp;&nbsp;<label htmlFor="users">User Roles * </label>
                                                                                            <div className="form-group col-md-12">
                                                                                                <Select
                                                                                                    options={props.c_state.roles_dropdown}
                                                                                                    name="users"
                                                                                                    value={props.c_state.role_details.existing_role ? typeof props.c_state.role_details.existing_role !== "string" ? props.c_state.role_details.existing_role : JSON.parse(props.c_state.role_details.existing_role.replace(/'/g, '"')) : ''}
                                                                                                    id="custom_select"
                                                                                                    onChange={(value) => props.handleChange({ "field": "existing_role", "value": value })}
                                                                                                    // isMulti
                                                                                                    style={{ width: '100%' }}
                                                                                                    className="basic-multi-select"
                                                                                                    classNamePrefix="select"
                                                                                                    placeholder="User Roles"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        props.c_state.role_details.existing_role ?
                                                                                            <>
                                                                                                <div class="row">
                                                                                                    <div class="col-12 table-responsive">
                                                                                                        <table class="table table-striped">
                                                                                                            <thead>
                                                                                                                <tr>
                                                                                                                    <th>#</th>
                                                                                                                    <th>Privilege</th>
                                                                                                                    {/* <th>Status</th> */}
                                                                                                                    <th>Action</th>
                                                                                                                </tr>
                                                                                                            </thead>
                                                                                                            <tbody>
                                                                                                                {
                                                                                                                    props.roles.privileges.map((data, i) => {
                                                                                                                        return (
                                                                                                                            <tr key={data.id}>
                                                                                                                                <td><span>{i + 1}</span></td>
                                                                                                                                <td>{data.description}</td>
                                                                                                                                {/* <td></td> */}
                                                                                                                                <td>
                                                                                                                                    <div className="icheck-primary">
                                                                                                                                        <input type="checkbox" id="check4" checked={props.c_state.selected_roles[data.id] ? true : false} onChange={() => props.select_role(data)} />
                                                                                                                                        <label htmlFor="check4"></label>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        )
                                                                                                                    })
                                                                                                                }
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div align="center">
                                                                                                    <button type="button" class="btn btn-info btn-md" onClick={() => props.update_user_role()}><i class="fa fa-save"></i> Update</button>
                                                                                                </div>
                                                                                            </>
                                                                                            : null
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </div>
                                                                    : null
                                                                : null
                                                        }
                                                        {
                                                            props.auth.user ?
                                                                props.auth.user.privileges.includes("assign_user_roles") ?
                                                                    <div className={`tab-pane fade ${props.auth.user.privileges.includes("assign_user_roles") ? 'show' : ''}`} id="custom-tabs-four-assign-user-roles" role="tabpanel" aria-labelledby="custom-tabs-four-assign-user-roles-tab">
                                                                        {
                                                                            props.roles.isLoading === "true" ?
                                                                                <div align="center">
                                                                                    <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>We are loading your company list. Please wait...</p></span>
                                                                                </div>
                                                                                :
                                                                                <div className="col-12">
                                                                                    <div className="row">
                                                                                        <div className="col-6">
                                                                                            &nbsp;&nbsp;<label htmlFor="users">User * </label>
                                                                                            <div className="form-group col-md-12">
                                                                                                <Select
                                                                                                    options={props.c_state.contacts_dropdown}
                                                                                                    name="users"
                                                                                                    value={props.c_state.role_details.users ? typeof props.c_state.role_details.users !== "string" ? props.c_state.role_details.users : JSON.parse(props.c_state.role_details.users.replace(/'/g, '"')) : ''}
                                                                                                    id="custom_select"
                                                                                                    onChange={(value) => props.handleChange({ "field": "users", "value": value })}
                                                                                                    // isMulti
                                                                                                    style={{ width: '100%' }}
                                                                                                    className="basic-multi-select"
                                                                                                    classNamePrefix="select"
                                                                                                    placeholder="Users"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6">
                                                                                            &nbsp;&nbsp;<label htmlFor="users">User Roles * </label>
                                                                                            <div className="form-group col-md-12">
                                                                                                <Select
                                                                                                    options={props.c_state.roles_dropdown}
                                                                                                    name="users"
                                                                                                    value={props.c_state.role_details.new_existing_role ? typeof props.c_state.role_details.new_existing_role !== "string" ? props.c_state.role_details.new_existing_role : JSON.parse(props.c_state.role_details.new_existing_role.replace(/'/g, '"')) : ''}
                                                                                                    id="custom_select"
                                                                                                    onChange={(value) => props.handleChange({ "field": "new_existing_role", "value": value })}
                                                                                                    // isMulti
                                                                                                    style={{ width: '100%' }}
                                                                                                    className="basic-multi-select"
                                                                                                    classNamePrefix="select"
                                                                                                    placeholder="User Roles"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        props.c_state.role_details.users && props.c_state.role_details.new_existing_role ?
                                                                                            <div align="center" className='mt-5'>
                                                                                                <button type="button" class="btn btn-success btn-md" onClick={() => props.assign_user_role()}><i class="fa fa-save"></i> Update</button>
                                                                                            </div>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    : null
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                    {/* <!-- /.container-fluid --> */}
                </section>
                {/* <!-- /.content --> */}

                {/* modals */}

            </div>
            {/* <!-- /.content-wrapper --> */}
        </>
    )
}

export default Roles
