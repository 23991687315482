import React, { Component } from 'react'
import Email from './Email';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverGetTags } from '../../actions/TagsAction';
import { serverLoad, serverGetEmailConfig, serverSaveEmailConfig, serverGetEmails, serverSingleEmail, serverSyncEmails } from '../../actions/EmailAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const $ = require("jquery");

class ViewEmailsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email_config: [],
            email_list: [],
            emails: [],
            start: 1,
            finish: 20,
            mail_category: "Inbox"
        }

        this.handleChange = this.handleChange.bind(this);
        this.searchEmail = this.searchEmail.bind(this);
        this.reloadEmails = this.reloadEmails.bind(this);
        this.singleEmail = this.singleEmail.bind(this);
        this.setModalContent = this.setModalContent.bind(this);
        this.clearModalContents = this.clearModalContents.bind(this);
        this.saveEmailConfig = this.saveEmailConfig.bind(this);
        this.loadEmails = this.loadEmails.bind(this);
        this.syncEmails = this.syncEmails.bind(this);
    }

    componentDidMount() {

        // console.log(this.props);

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        if (!this.props.tags.tags.length) {
            this.props.serverGetTags(this.props.auth.user.token);
        } else {
            this.setState({ tag_list: [...this.props.tags.tags] });
        }

        if (!this.props.email.email_config.email) {
            this.props.serverGetEmailConfig(this.props.auth.user.token);
        }

        if (this.props.email.single_email.mail_category) {
            this.setState({ mail_category: this.props.email.single_email.mail_category });
        }

        $(function () {
            //Enable check and uncheck all functionality
            $('.checkbox-toggle').click(function () {
                var clicks = $(this).data('clicks')
                if (clicks) {
                    //Uncheck all checkboxes
                    $('.mailbox-messages input[type=\'checkbox\']').prop('checked', false)
                    $('.checkbox-toggle .far.fa-check-square').removeClass('fa-check-square').addClass('fa-square')
                } else {
                    //Check all checkboxes
                    $('.mailbox-messages input[type=\'checkbox\']').prop('checked', true)
                    $('.checkbox-toggle .far.fa-square').removeClass('fa-square').addClass('fa-check-square')
                }
                $(this).data('clicks', !clicks)
            })

            //Handle starring for font awesome
            $('.mailbox-star').click(function (e) {
                e.preventDefault()
                //detect type
                var $this = $(this).find('a > i')
                var fa = $this.hasClass('fa')

                //Switch states
                if (fa) {
                    $this.toggleClass('fa-star')
                    $this.toggleClass('fa-star-o')
                }
            })
        })

    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.auth);

        if (prevProps.tags !== this.props.tags) {
            this.setState({ tag_list: [...this.props.tags.tags] });
        }

        if (prevProps.email !== this.props.email) {
            if (this.props.email.email_config_message === "Email configurations have not been defined") {
                $("#show_email_config_modal").click();
            } else if (this.props.email.email_config_message === "Email configurations have been loaded successfully") {
                this.setState({ email_config: this.props.email.email_config });
            }

            let data = [];

            switch (this.props.email.chosen_category) {
                case 'Inbox':
                    data = this.props.email.email_categories.inbox;
                    break;

                case 'Sent':
                    data = this.props.email.email_categories.sent;
                    break;

                case 'Drafts':
                    data = this.props.email.email_categories.drafts;
                    break;

                case 'Spam':
                    data = this.props.email.email_categories.spam;
                    break;

                case 'Trash':
                    data = this.props.email.email_categories.trash;
                    break;

                default:
                    break;
            }

            this.setState({ emails: data, mail_category: this.props.email.chosen_category });
        }

        if (prevProps.email.email_config !== this.props.email.email_config) {
            this.setState({ email_config: this.props.email.email_config });

            // load the emails
            this.props.serverGetEmails(this.state.mail_category, this.state.start, this.state.finish, this.props.email.email_config, this.props.auth.user.token);
        }

        if (prevProps.email.single_email !== this.props.email.single_email) {
            this.props.history.push("/email_details");
        }
    }

    handleChange = (data, type) => {
        switch (type) {
            case 'email_config':
                this.setState(prevState => ({
                    email_config: {                   // object that we want to update
                        ...prevState.email_config,    // keep all other key-value pairs
                        [data.field]: data.value     // update the value of specific key
                    }
                }));
                break;

            default:
                break;
        }
    }

    setModalContent = (data) => {
        this.setState({
            email_config: data
        });
    }

    searchEmail = (value) => {
        // eslint-disable-next-line array-callback-return
        let new_emails = this.props.tags.tags.filter((data, index) => { // loop through state.todo
            let keys = Object.keys(data);
            let valid = false;
            for (let i = 0; i < keys.length; i++) {
                let key = keys[i];

                if (typeof data[key] !== "string") {
                    continue;
                }

                if (data[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    valid = true;
                    break;
                }
            }

            if (valid) {
                return data;
            }
        });

        this.setState({ email_list: new_emails });
    }

    clearModalContents = () => {
        this.setState({ email_config: {} });

        return;
    }

    saveEmailConfig = () => {
        if (!this.state.email_config.email || !this.state.email_config.password || !this.state.email_config.server_name) {
            toast.error("Please fill all required fields.", {
                position: 'top-center',
                autoClose: 3000
            });

            return;
        }

        this.props.serverSaveEmailConfig(this.state.email_config, this.props.auth.user.token)
    }

    reloadEmails = () => {
        // load the emails
        this.props.serverGetEmails(this.state.mail_category, this.state.start, this.state.finish, this.props.email.email_config, this.props.auth.user.token);
    }

    singleEmail = (data) => {
        // console.log(this.state.mail_category);
        this.props.serverSingleEmail(data, this.state.mail_category, this.props.email.email_config)
    }

    loadEmails = (category) => {

        // console.log(category);

        // check if category emails have already been loaded
        switch (category) {
            case 'Inbox':
                if (this.props.email.email_categories.inbox.emails) {
                    this.setState({ emails: this.props.email.email_categories.inbox, mail_category: category });
                } else {
                    this.setState({ mail_category: category, start: 1, finish: 20 })

                    // load the emails
                    this.props.serverGetEmails(category, 1, 20, this.props.email.email_config, this.props.auth.user.token);
                }
                break;

            case 'Sent':
                if (this.props.email.email_categories.sent.emails) {
                    this.setState({ emails: this.props.email.email_categories.sent, mail_category: category });
                } else {
                    this.setState({ mail_category: category, start: 1, finish: 20 })

                    // // load the emails
                    this.props.serverGetEmails(category, 1, 20, this.props.email.email_config, this.props.auth.user.token);
                }
                break;

            case 'Drafts':
                if (this.props.email.email_categories.drafts.emails) {
                    this.setState({ emails: this.props.email.email_categories.drafts, mail_category: category });
                } else {
                    this.setState({ mail_category: category, start: 1, finish: 20 })

                    // // load the emails
                    this.props.serverGetEmails(category, 1, 20, this.props.email.email_config, this.props.auth.user.token);
                }
                break;

            case 'Spam':
                if (this.props.email.email_categories.spam.emails) {
                    this.setState({ emails: this.props.email.email_categories.spam, mail_category: category });
                } else {
                    this.setState({ mail_category: category, start: 1, finish: 20 })

                    // // load the emails
                    this.props.serverGetEmails(category, 1, 20, this.props.email.email_config, this.props.auth.user.token);
                }
                break;

            case 'Trash':
                if (this.props.email.email_categories.trash.emails) {
                    this.setState({ emails: this.props.email.email_categories.trash, mail_category: category });
                } else {
                    this.setState({ mail_category: category, start: 1, finish: 20 })

                    // load the emails
                    this.props.serverGetEmails(category, 1, 20, this.props.email.email_config, this.props.auth.user.token);
                }
                break;

            default:
                break;
        }
    }

    syncEmails = () => {
        this.props.serverSyncEmails(this.state.start, this.state.finish, this.props.email.email_config, this.props.auth.user.token)
    }

    render() {
        return (
            <>
                <Main>
                    <Email
                        c_state={{ ...this.state }}
                        isLoading={this.props.email.isLoading}
                        auth={this.props.auth}
                        handleChange={this.handleChange}
                        set_modal_content={this.setModalContent}
                        search_email={this.searchEmail}
                        clear_modal_contents={this.clearModalContents}
                        save_email_config={this.saveEmailConfig}
                        reload_emails={this.reloadEmails}
                        single_email={this.singleEmail}
                        load_emails={this.loadEmails}
                        sync_emails={this.syncEmails}
                    />
                </Main>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        tags: { ...state.tags },
        email: { ...state.email }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        serverGetTags,
        clearData,
        // checkUser,
        serverGetEmailConfig,
        serverSaveEmailConfig,
        serverGetEmails,
        serverSingleEmail,
        serverSyncEmails
    }
)(ViewEmailsContainer)
