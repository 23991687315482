/* eslint-disable array-callback-return */
import React from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import AddAttachmentModal from '../AttachmentComponent/AddAttachmentModal';
import ViewAttachmentModal from '../AttachmentComponent/ViewAttachmentModal';

const badges = ['badge badge-danger', 'badge badge-primary', 'badge badge-warning', 'badge badge-info', 'badge badge-success', 'badge badge-default'];
let random = 0;

const Task = (props) => {

    // console.log(props.auth.user);

    return (
        <div className="content-wrapper kanban">
            {/* <!-- Content Header (Page header) --> */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Task Manager</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                <li className="breadcrumb-item active">Tasks</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* <!-- /.container-fluid --> */}
            </section>

            {/* <!-- Main content --> */}
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Manage Your Tasks</h3>
                    <div align="right">
                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={props.reload}><i className="fa fa-sync"></i> Reload</button>&nbsp;
                        {
                            props.auth.user ?
                                props.auth.user.privileges.includes("add_tasks") ?
                                    <>
                                        <button type="button" className="btn btn-sm btn-outline-danger" title="Add New" data-toggle="modal" data-target="#add_task_modal" onClick={props.clear_modal_contents}>
                                            <i className="fas fa-plus"></i> Add New
                                        </button>&nbsp;
                                    </>
                                    : null
                                : null
                        }
                        <button type="button" className="btn btn-sm btn-outline-success" data-toggle="modal" data-target="#search_tag_modal"><i className="fa fa-filter"></i> Filter By Tags</button>&nbsp;
                        <button type="button" className="btn btn-sm btn-outline-info" data-toggle="modal" data-target="#search_recipients_modal"><i className="fa fa-filter"></i> Filter By Users</button>&nbsp;
                        <button type="button" className="btn btn-sm btn-outline-default">
                            <div className="input-group input-group-sm" style={{ width: 200 }}>
                                <input type="search" className="form-control form-control-sm" placeholder="Type your keywords here" onChange={(event) => props.search_tasks(event.target.value)} />
                                <div className="input-group-append">
                                    <Link to="#" type="submit" disabled className="btn btn-sm btn-default">
                                        <i className="fa fa-search"></i>
                                    </Link>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <section className="content pb-3">
                {
                    props.isLoading === "true" ?
                        <div align="center">
                            <span><img src="static/assets/dist/img/loader.gif" alt="loader" /><br /><p>We are loading your tasks. Please wait...</p></span>
                        </div>
                        :
                        <div className="container-fluid h-100">
                            <div className="card card-row card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        To Do
                                    </h3>
                                    {/* <div className="card-tools">
                                <button style={{ color: 'white' }} type="button" className="btn btn-tool" title="Add New" data-toggle="modal" data-target="#add_task_modal" onClick={props.clear_modal_contents}>
                                    <i className="fas fa-plus"></i> Add New
                                </button>
                                <button style={{ color: 'white' }} type="button" className="btn btn-tool" onClick={props.reload}>
                                    <i className="fa fa-sync"></i> Reload
                                </button>
                            </div> */}
                                </div>
                                <div className="card-body">
                                    {
                                        props.c_state.task_list.map((data, index) => {
                                            if (data.task.state && data.task.state === "no") {
                                                return (<div key={index} className="card card-primary card-outline">
                                                    <div className="card-header">
                                                        <span style={{ fontSize: 12 }} className="card-title">From: {props.getContactName(data.task.user)} | To: {props.getContactName(data.task.recipient)}</span><br />
                                                        <Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_note_modal" onClick={() => props.set_modal_content_alt(data.task)}><h5 className="card-title">{data.task.text}</h5></Link>
                                                        <br />
                                                        <span className="text-success" style={{ fontSize: 10 }}><i className="fa fa-clock"></i>&nbsp;{data.task.date_created}</span>
                                                        <div className="card-tools">
                                                            <Link to="#" className="btn btn-tool btn-link">{data.task.priority === '1' ? '#high priority' : data.task.priority === '2' ? '#medium priority' : data.task.priority === '3' ? '#low priority' : ''}</Link>
                                                            {/* <Link to="#" className="btn btn-tool">
                                                        <i className="fas fa-tag"></i>
                                                    </Link> */}
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        {
                                                            !data.task.cc ?
                                                                <>
                                                                    <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("yes", data.task.task_id)}><span className="text-success font-weight-bold">done</span></Link>
                                                                    <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("progress", data.task.task_id)}><span className="text-info font-weight-bold">in progress</span></Link>
                                                                </>
                                                                : data.task.cc !== "" && data.task.cc !== null ?
                                                                    !JSON.parse(data.task.cc.replace(/'/g, '"')).includes(props.auth.user.contact_id) ?
                                                                        <>
                                                                            <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("yes", data.task.task_id)}><span className="text-success font-weight-bold">done</span></Link>
                                                                            <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("progress", data.task.task_id)}><span className="text-info font-weight-bold">in progress</span></Link>
                                                                        </>
                                                                        : null
                                                                    : null
                                                        }
                                                        <span className="text-danger" style={{ fontSize: 10 }}><i className="fa fa-clock"></i>&nbsp;{data.task.deadline_date} {data.task.deadline_time}</span>
                                                    </div>

                                                    <div className="card-footer">
                                                        {
                                                            props.auth.user ?
                                                                props.auth.user.privileges.includes("task_logs") ?
                                                                    <>
                                                                        <Link to="#" data-toggle="modal" data-target="#task_logs_modal" onClick={() => props.task_logs(data.task_logs)}><i className="fas fa-ellipsis-v text-info"></i></Link>&nbsp;&nbsp;&nbsp;
                                                                        {
                                                                            data.attachments.length ?
                                                                                <Link to="#" data-toggle="modal" data-target="#task_attachments_modal" onClick={() => props.selected_attachments(data.attachments)}><i className="fas fa-paperclip text-danger"></i></Link>
                                                                                : null
                                                                        }
                                                                    </>
                                                                    : null
                                                                : null
                                                        }
                                                        {
                                                            data.task.user + '' === props.auth.user.contact_id + '' ?
                                                                <span className="float-right">
                                                                    {
                                                                        props.auth.user ?
                                                                            props.auth.user.privileges.includes("edit_tasks") ?
                                                                                <>
                                                                                    <button type="button" className="btn btn-xs btn-outline-success" title="Edit Task" data-toggle="modal" data-target="#edit_task_modal" onClick={() => props.set_modal_content(data)}><i className="fa fa-edit"></i> Edit</button>&nbsp;&nbsp;
                                                                                </>
                                                                                : null
                                                                            : null
                                                                    }
                                                                    {
                                                                        props.auth.user ?
                                                                            props.auth.user.privileges.includes("delete_tasks") ?
                                                                                <button type="button" className="btn btn-xs btn-outline-danger" title="Delete Task" onClick={() => window.confirm(`Are you sure you want to delete "${data.task.text}" ?`) ? props.delete_task(data.task.task_id) : ''}><i className="fa fa-trash"></i> Delete</button>
                                                                                : null
                                                                            : null
                                                                    }
                                                                </span>
                                                                : null
                                                        }
                                                        <span className="float-right">{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" width="30" height="30" /> : ''}</span>
                                                    </div>
                                                </div>
                                                )
                                            }
                                        })}
                                </div>
                            </div>
                            <div className="card card-row card-default">
                                <div className="card-header bg-info">
                                    <h3 className="card-title">
                                        In Progress
                                    </h3>
                                </div>
                                <div className="card-body">
                                    {props.c_state.task_list.map((data, index) => {
                                        if (data.task.state && data.task.state === "progress") {
                                            return (<div key={index} className="card card-info card-outline">
                                                <div className="card-header">
                                                    <span style={{ fontSize: 12 }} className="card-title">From: {props.getContactName(data.task.user)} | To: {props.getContactName(data.task.recipient)}</span><br />
                                                    <Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_note_modal" onClick={() => props.set_modal_content_alt(data.task)}><h5 className="card-title">{data.task.text}</h5></Link>
                                                    <br />
                                                    <span className="text-success" style={{ fontSize: 10 }}><i className="fa fa-clock"></i>&nbsp;{data.task.date_created}</span>
                                                    <div className="card-tools">
                                                        <Link to="#" className="btn btn-tool btn-link">{data.task.priority === '1' ? '#high priority' : data.task.priority === '2' ? '#medium priority' : data.task.priority === '3' ? '#low priority' : ''}</Link>
                                                        {/* <Link to="#" className="btn btn-tool">
                                                        <i className="fas fa-tag"></i>
                                                    </Link> */}
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        !data.task.cc ?
                                                            <>
                                                                <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("yes", data.task.task_id)}><span className="text-success font-weight-bold">done</span></Link>
                                                                <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("no", data.task.task_id)}><span className="text-primary font-weight-bold">to do</span></Link>
                                                            </>
                                                            : data.task.cc !== "" && data.task.cc !== null ?
                                                                !JSON.parse(data.task.cc.replace(/'/g, '"')).includes(props.auth.user.contact_id) ?
                                                                    <>
                                                                        <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("yes", data.task.task_id)}><span className="text-success font-weight-bold">done</span></Link>
                                                                        <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("no", data.task.task_id)}><span className="text-primary font-weight-bold">to do</span></Link>
                                                                    </>
                                                                    : null
                                                                : null
                                                    }
                                                    <span className="text-danger" style={{ fontSize: 10 }}><i className="fa fa-clock"></i>&nbsp;{data.task.deadline_date} {data.task.deadline_time}</span>
                                                </div>
                                                <div className="card-footer">
                                                    {
                                                        props.auth.user ?
                                                            props.auth.user.privileges.includes("task_logs") ?
                                                                <>
                                                                    <Link to="#" data-toggle="modal" data-target="#task_logs_modal" onClick={() => props.task_logs(data.task_logs)}><i className="fas fa-ellipsis-v text-info"></i></Link>&nbsp;&nbsp;&nbsp;
                                                                    {
                                                                        data.attachments.length ?
                                                                            <Link to="#" data-toggle="modal" data-target="#task_attachments_modal" onClick={() => props.selected_attachments(data.attachments)}><i className="fas fa-paperclip text-danger"></i></Link>
                                                                            : null
                                                                    }
                                                                </>
                                                                : null
                                                            : null
                                                    }
                                                    {
                                                        data.task.user + '' === props.auth.user.contact_id + '' ?
                                                            <span className="float-right">
                                                                {
                                                                    props.auth.user ?
                                                                        props.auth.user.privileges.includes("edit_tasks") ?
                                                                            <>
                                                                                <button type="button" className="btn btn-xs btn-outline-success" data-toggle="modal" data-target="#edit_task_modal" onClick={() => props.set_modal_content(data)}><i className="fa fa-edit"></i> Edit</button>&nbsp;&nbsp;
                                                                            </>
                                                                            : null
                                                                        : null
                                                                }
                                                                {
                                                                    props.auth.user ?
                                                                        props.auth.user.privileges.includes("delete_tasks") ?
                                                                            <button type="button" className="btn btn-xs btn-outline-danger" title="Delete Task" onClick={() => window.confirm(`Are you sure you want to delete "${data.task.text}" ?`) ? props.delete_task(data.task.task_id) : ''}><i className="fa fa-trash"></i> Delete</button>
                                                                            : null
                                                                        : null
                                                                }
                                                            </span>
                                                            : null
                                                    }
                                                    <span className="float-right">{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" width="30" height="30" /> : ''}</span>
                                                </div>

                                            </div>)
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="card card-row card-success">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Done
                                    </h3>
                                    {/* <div className="card-tools">
                                <button type="button" className="btn btn-tool">
                                    <div className="input-group input-group-sm" style={{ width: 200 }}>
                                        <input type="search" className="form-control form-control-sm" placeholder="Type your keywords here" onChange={(event) => props.search_tasks(event.target.value)} />
                                    </div>
                                </button>
                            </div> */}
                                </div>
                                <div className="card-body">
                                    {
                                        props.c_state.task_list.map((data, index) => {
                                            if (data.task.state && data.task.state === "yes") {
                                                return (<div key={index} className="card card-success card-outline">
                                                    <div className="card-header">
                                                        <span style={{ fontSize: 12 }} className="card-title">From: {props.getContactName(data.task.user)} | To: {props.getContactName(data.task.recipient)}</span><br />
                                                        <Link to="#" style={{ color: 'black' }} data-toggle="modal" data-target="#view_note_modal" onClick={() => props.set_modal_content_alt(data.task)}><h5 className="card-title">{data.task.text}</h5></Link>
                                                        <br />
                                                        <span className="text-success" style={{ fontSize: 10 }}><i className="fa fa-clock"></i>&nbsp;{data.task.date_created}</span>
                                                        <div className="card-tools">
                                                            <Link to="#" className="btn btn-tool btn-link">{data.task.priority === '1' ? '#high priority' : data.task.priority === '2' ? '#medium priority' : data.task.priority === '3' ? '#low priority' : ''}</Link>
                                                            {/* <Link to="#" className="btn btn-tool">
                                                        <i className="fas fa-tag"></i>
                                                    </Link> */}
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        {
                                                            !data.task.cc ?
                                                                <>
                                                                    <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("progress", data.task.task_id)}><span className="text-info font-weight-bold">in progress</span></Link>
                                                                    <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("no", data.task.task_id)}><span className="text-primary font-weight-bold">to do</span></Link>
                                                                </>
                                                                : data.task.cc !== "" && data.task.cc !== null ?
                                                                    !JSON.parse(data.task.cc.replace(/'/g, '"')).includes(props.auth.user.contact_id) ?
                                                                        <>
                                                                            <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("progress", data.task.task_id)}><span className="text-info font-weight-bold">in progress</span></Link>
                                                                            <Link to="#" className="float-right btn-white btn-xs" onClick={() => props.change_task_state("no", data.task.task_id)}><span className="text-primary font-weight-bold">to do</span></Link>
                                                                        </>
                                                                        : null
                                                                    : null
                                                        }
                                                        <span className="text-danger" style={{ fontSize: 10 }}><i className="fa fa-clock"></i>&nbsp;{data.task.deadline_date} {data.task.deadline_time}</span>
                                                    </div>
                                                    <div className="card-footer">
                                                        {
                                                            props.auth.user ?
                                                                props.auth.user.privileges.includes("task_logs") ?
                                                                    <>
                                                                        <Link to="#" data-toggle="modal" data-target="#task_logs_modal" onClick={() => props.task_logs(data.task_logs)}><i className="fas fa-ellipsis-v text-info"></i></Link>&nbsp;&nbsp;&nbsp;
                                                                        {
                                                                            data.attachments.length ?
                                                                                <Link to="#" data-toggle="modal" data-target="#task_attachments_modal" onClick={() => props.selected_attachments(data.attachments)}><i className="fas fa-paperclip text-danger"></i></Link>
                                                                                : null
                                                                        }
                                                                    </>
                                                                    : null
                                                                : null
                                                        }
                                                        {
                                                            data.task.user + '' === props.auth.user.contact_id + '' ?
                                                                <span className="float-right">
                                                                    {
                                                                        props.auth.user ?
                                                                            props.auth.user.privileges.includes("edit_tasks") ?
                                                                                <>
                                                                                    <button type="button" className="btn btn-xs btn-outline-success" data-toggle="modal" data-target="#edit_task_modal" onClick={() => props.set_modal_content(data)}><i className="fa fa-edit"></i> Edit</button>&nbsp;&nbsp;
                                                                                </>
                                                                                : null
                                                                            : null
                                                                    }
                                                                    {
                                                                        props.auth.user ?
                                                                            props.auth.user.privileges.includes("delete_tasks") ?
                                                                                <button type="button" className="btn btn-xs btn-outline-danger" title="Delete Task" onClick={() => window.confirm(`Are you sure you want to delete "${data.task.text}" ?`) ? props.delete_task(data.task.task_id) : ''}><i className="fa fa-trash"></i> Delete</button>
                                                                                : null
                                                                            : null
                                                                    }
                                                                </span>
                                                                : null
                                                        }
                                                        <span className="float-right">{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" width="30" height="30" /> : ''}</span>
                                                    </div>
                                                </div>
                                                )
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                }
            </section>

            {/* Modals */}

            <div className="modal fade" id="search_tag_modal">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Select Tags</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group col-12">
                                <Select
                                    options={props.c_state.tags_dropdown}
                                    name="tags"
                                    // value={props.c_state.task_details.tags ? props.c_state.task_details.tags : []}
                                    id="custom_select"
                                    value={typeof props.c_state.task_details.search_params === "string" ? JSON.parse(props.c_state.task_details.search_params.replace(/'/g, '"')) : props.c_state.task_details.search_params}
                                    onChange={(value) => props.handleChange({ "field": "search_params", "value": value }, 'task')}
                                    isMulti style={{ width: '100%' }}
                                    className="basic-multi-select"
                                    classNamePrefix="select" />
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_search_params_modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={props.search_task_by_tags}>Search</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="search_recipients_modal">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Select Users</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group col-12">
                                <label htmlFor="action_type">Select Category</label>
                                <select className="form-control" onChange={(event) => props.handleChange({ "field": "search_category", "value": event.target.value }, 'task')} value={props.c_state.task_details.search_category ? props.c_state.task_details.search_category : ""}>
                                    <option value=""></option>
                                    <option value="From">From</option>
                                    <option value="To">To</option>
                                </select>
                            </div>
                            <div className="form-group col-12">
                                <label htmlFor="action_type">Select Users</label>
                                <Select
                                    options={props.c_state.contact_list}
                                    name="search_users"
                                    id="custom_select"
                                    value={typeof props.c_state.task_details.search_users === "string" ? JSON.parse(props.c_state.task_details.search_users.replace(/'/g, '"')) : props.c_state.task_details.search_users}
                                    onChange={(value) => props.handleChange({ "field": "search_users", "value": value }, 'task')}
                                    isMulti
                                    style={{ width: '100%' }}
                                    className="basic-multi-select"
                                    classNamePrefix="select" />
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_search_recipients_modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={props.search_task_by_users}>Search</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="add_task_modal" style={{ overflow: "auto" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add New Task</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="recipients">For *</label>
                                    <Select options={props.c_state.contact_list} name="recipients" value={props.c_state.task_details.recipients ? props.c_state.task_details.recipients : []} id="custom_select" onChange={(value) => props.handleChange({ "field": "recipients", "value": value }, 'task')} isMulti style={{ width: '100%' }} className="basic-multi-select" classNamePrefix="select" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="cc">CC *</label>
                                    <Select options={props.c_state.contact_list} name="cc" value={props.c_state.task_details.cc ? props.c_state.task_details.cc : []} id="custom_select" onChange={(value) => props.handleChange({ "field": "cc", "value": value }, 'task')} isMulti style={{ width: '100%' }} className="basic-multi-select" classNamePrefix="select" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="text">Subject *</label>
                                    <input type="text" onChange={(event) => props.handleChange({ "field": "text", "value": event.target.value }, 'task')} className="form-control" name="text" placeholder="Subject" value={props.c_state.task_details ? props.c_state.task_details.text : ''} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="note">Task *</label>
                                    <textarea style={{ resize: 'none' }} type="text" onChange={(event) => props.handleChange({ "field": "note", "value": event.target.value }, 'task')} className="form-control" name="note" placeholder="Task" value={props.c_state.task_details.note ? props.c_state.task_details.note : ''}>{props.c_state.task_details.note ? props.c_state.task_details.note : ''}</textarea>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="deadline_date">Deadline Date *</label>
                                    <TextField
                                        id="date"
                                        type="date"
                                        // defaultValue={props.c_state.task_details.deadline_date}
                                        value={props.c_state.task_details.deadline_date ? props.c_state.task_details.deadline_date : ''}
                                        className="form-control"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(event) => props.handleChange({ "field": "deadline_date", "value": event.target.value }, 'task')}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="deadline_time">Deadline Time *</label>
                                    <TextField
                                        id="time"
                                        type="time"
                                        // defaultValue={props.c_state.task_details.deadline_time}
                                        value={props.c_state.task_details.deadline_time ? props.c_state.task_details.deadline_time : ''}
                                        className="form-control"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={(event) => props.handleChange({ "field": "deadline_time", "value": event.target.value }, 'task')}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="tags">Tags</label>
                                    <Select
                                        options={props.c_state.tags_dropdown}
                                        name="tags"
                                        value={props.c_state.task_details.tags ? props.c_state.task_details.tags : ''}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'task')}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="priority">Priority *</label>
                                    <select className="form-control" name="priority" onChange={(event) => props.handleChange({ "field": "priority", "value": event.target.value }, 'task')} value={props.c_state.task_details.priority ? props.c_state.task_details.priority : ''}>
                                        <option value="" disabled>--Select--</option>
                                        <option value="3">Low Priority</option>
                                        <option value="2">Medium Priority</option>
                                        <option value="1">High Priority</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-5">
                                    <label htmlFor="reminder_date">Reminder Date *</label>
                                </div>
                                <div className="form-group col-5">
                                    <label htmlFor="reminder_time">Reminder Time *</label>
                                </div>
                            </div>

                            {props.c_state.reminders.map((data, index) => {
                                return (
                                    <div className="row" key={index}>
                                        <div className="form-group col-5">
                                            <TextField
                                                id="date"
                                                type="date"
                                                // defaultValue={data.r_date}
                                                value={data.r_date ? data.r_date : ''}
                                                className="form-control"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => props.set_reminder_date(event.target.value, index)}
                                            />
                                        </div>
                                        <div className="form-group col-5">
                                            <TextField
                                                id="time"
                                                type="time"
                                                // defaultValue={data.r_time}
                                                value={data.r_time ? data.r_time : ''}
                                                className="form-control"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                }}
                                                onChange={(event) => props.set_reminder_time(event.target.value, index)}
                                            />
                                        </div>
                                        {index === 0 ?
                                            <div className="form-group col-2">
                                                <button className="btn btn-sm btn-outline-info" onClick={props.add_reminder}><i className="fa fa-plus"></i> Add New</button>
                                            </div>

                                            :
                                            <div className="form-group col-2">
                                                <button className="btn btn-sm btn-outline-danger" onClick={() => props.remove_reminder(index)}><i className="fa fa-times"></i> Remove</button>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                            <div className="row">
                                <div className="form-group">
                                    <div className="btn btn-default btn-file fileinput-button" data-toggle="modal" data-target="#attachments_modal">
                                        <i className="fas fa-paperclip"></i> Add Attachments
                                    </div>
                                </div>
                                {
                                    props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                        <div className="form-group">
                                            <div className="btn btn-default btn-file fileinput-button" onClick={props.clear_attachments}>
                                                <i className="fas fa-times"></i> Clear Attachments
                                                {/* <input type="button" onClick={props.clear_attachments} /> */}
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="row">
                                {
                                    props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                        props.c_state.a_type === "tasks" ?
                                            <div className="table table-striped files" id="previews">
                                                {/* <strong className="lead" style={{ fontWeight: 'bold' }}>Selected Documents: </strong> */}
                                                {
                                                    props.c_state.my_computer_selected.map((data, index) => {
                                                        return (
                                                            <span key={index} className="lead" data-dz-name> {data.file_name}, </span>
                                                        )
                                                    })
                                                }
                                                {
                                                    props.c_state.my_documents_selected.map((data, index) => {
                                                        return (
                                                            <span key={index} className="lead" data-dz-name> {data.name}{data.ext}, </span>
                                                        )
                                                    })
                                                }
                                                {
                                                    props.c_state.files_selected.map((data, index) => {
                                                        return (
                                                            <span key={index} className="lead" data-dz-name> {data.filename}{data.filetype}, </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : null
                                        : null
                                }
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_task_modal" onClick={props.clear_modal_contents}>Close</button>
                            <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.add_task}>Save changes</button></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="edit_task_modal" style={{ overflow: "auto" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Task</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="recipients">For *</label>
                                    <Select
                                        options={props.c_state.contact_list}
                                        name="recipients"
                                        value={typeof props.c_state.task_details.recipients === "string" ? JSON.parse(props.c_state.task_details.recipients.replace(/'/g, '"')) : props.c_state.task_details.recipients}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "recipients", "value": value }, 'task')}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="cc">CC *</label>
                                    <Select
                                        options={props.c_state.contact_list}
                                        name="cc"
                                        value={props.c_state.task_details.ccs ? typeof props.c_state.task_details.ccs === "string" ? JSON.parse(props.c_state.task_details.ccs.replace(/'/g, '"')) : props.c_state.task_details.ccs : ''}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "ccs", "value": value }, 'task')}
                                        isMulti
                                        style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isDisabled={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="text">Subject *</label>
                                    <input type="text" onChange={(event) => props.handleChange({ "field": "text", "value": event.target.value }, 'task')} className="form-control" name="text" placeholder="Subject" value={props.c_state.task_details ? props.c_state.task_details.text : ''} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="note">Task *</label>
                                    <textarea style={{ resize: 'none' }} type="text" onChange={(event) => props.handleChange({ "field": "note", "value": event.target.value }, 'task')} className="form-control" name="note" placeholder="Task" value={props.c_state.task_details ? props.c_state.task_details.note : ''}></textarea>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="deadline_date">Deadline Date *</label>
                                    <TextField
                                        id="date"
                                        type="date"
                                        value={props.c_state.task_details ? props.c_state.task_details.deadline_date : ''}
                                        className="form-control"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(event) => props.handleChange({ "field": "deadline_date", "value": event.target.value }, 'task')}
                                    />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="deadline_time">Deadline Time *</label>
                                    <TextField
                                        id="time"
                                        type="time"
                                        value={props.c_state.task_details ? props.c_state.task_details.deadline_time : ''}
                                        className="form-control"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        onChange={(event) => props.handleChange({ "field": "deadline_time", "value": event.target.value }, 'task')}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="tags">Tags</label>
                                    <Select
                                        options={props.c_state.tags_dropdown}
                                        name="tags"
                                        value={props.c_state.task_details.tags && typeof props.c_state.task_details.tags === "string" ? JSON.parse(props.c_state.task_details.tags.replace(/'/g, '"')) : props.c_state.task_details.tags}
                                        id="custom_select"
                                        onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'task')}
                                        isMulti style={{ width: '100%' }}
                                        className="basic-multi-select"
                                        classNamePrefix="select" />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="priority">Priority *</label>
                                    <select className="form-control" name="priority" onChange={(event) => props.handleChange({ "field": "priority", "value": event.target.value }, 'task')} value={props.c_state.task_details ? props.c_state.task_details.priority : ''}>
                                        <option value="" disabled>--Select--</option>
                                        <option value="3">Low Priority</option>
                                        <option value="2">Medium Priority</option>
                                        <option value="1">High Priority</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-5">
                                    <label htmlFor="reminder_date">Reminder Date *</label>
                                </div>
                                <div className="form-group col-5">
                                    <label htmlFor="reminder_time">Reminder Time *</label>
                                </div>
                            </div>

                            {props.c_state.reminders.map((data, index) => {
                                return (
                                    <div className="row" key={index}>
                                        <div className="form-group col-5">
                                            <TextField
                                                id="date"
                                                type="date"
                                                value={data.r_date}
                                                className="form-control"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => props.set_reminder_date(event.target.value, index)}
                                            />
                                        </div>
                                        <div className="form-group col-5">
                                            <TextField
                                                id="time"
                                                type="time"
                                                value={data.r_time}
                                                className="form-control"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                }}
                                                onChange={(event) => props.set_reminder_time(event.target.value, index)}
                                            />
                                        </div>
                                        {index === 0 ?
                                            <div className="form-group col-2">
                                                <button className="btn btn-sm btn-outline-info" onClick={props.add_reminder}><i className="fa fa-plus"></i> Add New</button>
                                            </div>

                                            :
                                            <div className="form-group col-2">
                                                <button className="btn btn-sm btn-outline-danger" onClick={() => props.remove_reminder(index)}><i className="fa fa-times"></i> Remove</button>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                            <div className="row">
                                <div className="form-group">
                                    <div className="btn btn-default btn-file fileinput-button" data-toggle="modal" data-target="#attachments_modal">
                                        <i className="fas fa-paperclip"></i> Add Attachments
                                    </div>
                                </div>
                                {
                                    props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                        <div className="form-group">
                                            <div className="btn btn-default btn-file fileinput-button" onClick={props.clear_attachments}>
                                                <i className="fas fa-times"></i> Clear Attachments
                                                {/* <input type="button" onClick={props.clear_attachments} /> */}
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className="row">
                                {
                                    props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                        props.c_state.a_type === "tasks" ?
                                            <div className="table table-striped files" id="previews">
                                                {/* <strong className="lead" style={{ fontWeight: 'bold' }}>Selected Documents: </strong> */}
                                                {
                                                    props.c_state.my_computer_selected.map((data, index) => {
                                                        return (
                                                            <span key={index} className="lead" data-dz-name> {data.file_name}, </span>
                                                        )
                                                    })
                                                }
                                                {
                                                    props.c_state.my_documents_selected.map((data, index) => {
                                                        return (
                                                            <span key={index} className="lead" data-dz-name> {data.name}{data.ext}, </span>
                                                        )
                                                    })
                                                }
                                                {
                                                    props.c_state.files_selected.map((data, index) => {
                                                        return (
                                                            <span key={index} className="lead" data-dz-name> {data.filename}{data.filetype}, </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                            : null
                                        : null
                                }
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_task_modal" onClick={props.clear_modal_contents}>Close</button>
                            <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.edit_task}>Save changes</button></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="view_note_modal" style={{ overflow: "auto" }}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Task Details</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="card card-primary card-outline">
                                <div className="card-body">
                                    <h5 className="card-title mb-2">{props.c_state.task_info.text}</h5>
                                    <p className="card-text">
                                        {props.c_state.task_info.note}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="task_logs_modal" style={{ overflow: "scroll" }}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Task Logs</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="card card-primary card-outline">
                                <div className="card-body">
                                    <ul className="todo-list ui-sortable" data-widget="todo-list">
                                        {
                                            props.c_state.task_logs.map((data, index) => {
                                                random = Math.floor(Math.random() * badges.length);
                                                return (
                                                    <li className="">
                                                        <span className="text">{`${props.getContactName(data.moved_by)} moved this task to "${data.state === 'no' ? 'To Do' : data.state === 'progress' ? 'In Progress' : data.state === 'yes' ? 'Completed' : data.state}"`}</span>
                                                        <small className={badges[random]}><i className="far fa-clock"></i> {data.date_created}</small>
                                                        {/* <div className="tools">
                                                            <i className="fas fa-edit"></i>
                                                            <i className="fas fa-trash-o"></i>
                                                        </div> */}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="task_attachments_modal" style={{ overflow: "auto" }}>
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Attachments</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                                {
                                    props.c_state.selected_attachments.map((data, index) => {
                                        return (
                                            <li key={index}>
                                                {/* <span className="mailbox-attachment-icon"><i className="far fa-file"></i></span> */}

                                                <div className="mailbox-attachment-info col-12">
                                                    <Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(data, 'attachment')}><i className="fas fa-paperclip"></i> {data.file_name}{data.ext}</Link>
                                                    {/* <span className="mailbox-attachment-size clearfix mt-1">
                                                        <Link to="#" className="btn btn-default btn-sm float-right" onClick={() => props.download('attachments', data.aid, data.ext, data.file_name)}><i className="fas fa-download"></i></Link>
                                                    </span> */}
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_task_attachments_modal" onClick={props.clear_modal_contents}>Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <AddAttachmentModal
                handleChange={props.handleChange}
                templates={props.templates}
                attachments={props.attachments}
                set_attachment_info={props.set_attachment_info}
                set_template_modal_content={props.set_template_modal_content}
                select_my_document={props.select_my_document}
                select_template={props.select_template}
                select_files={props.select_files}
                enter_folder={props.enter_folder}
                click_breadcrumbs={props.click_breadcrumbs}
                c_state={props.c_state}
                use_template={props.use_template}
                auth={props.auth}
                save_attachments={props.save_attachments}
                search_attachment={props.search_attachment}
            />

            <ViewAttachmentModal
                c_state={props.c_state}
                clear_attachment_info={props.clear_attachment_info}
                file_type={props.file_type}
                download={props.download}
            />
        </div>
    )
}

export default Task
