/* eslint-disable array-callback-return */
import React from 'react'
import { Link } from 'react-router-dom'
// import types from '../../types';
// import TextField from '@material-ui/core/TextField';
import Select from 'react-select'
import AddAttachmentModal from '../AttachmentComponent/AddAttachmentModal';
import ViewAttachmentModal from '../AttachmentComponent/ViewAttachmentModal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ComposeEmail = (props) => {

    // console.log(props.c_state.email_details.attachments);

    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Compose Email</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                <li className="breadcrumb-item active">Compose Email</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">Compose New Message</h3>

                                    <div className="card-tools">
                                        <Link to="/emails" className="btn btn-sm btn-outline-danger">Back to Inbox</Link>&nbsp;
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                &nbsp;&nbsp;<label htmlFor="recipients">To * &nbsp;&nbsp;<button className="btn btn-xs btn-success" data-toggle="modal" data-target="#recipients_tag_modal"> Use Tags</button> </label>
                                                <div className="form-group col-md-12">
                                                    <Select
                                                        options={props.c_state.contacts_dropdown}
                                                        name="recipients"
                                                        value={props.c_state.email_details.recipients ? typeof props.c_state.email_details.recipients !== "string" ? props.c_state.email_details.recipients : JSON.parse(props.c_state.email_details.recipients.replace(/'/g, '"')) : ''}
                                                        id="custom_select"
                                                        onChange={(value) => props.handleChange({ "field": "recipients", "value": value }, 'email_details')}
                                                        isMulti
                                                        style={{ width: '100%' }}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="To"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="row">
                                                &nbsp;&nbsp;<label htmlFor="recipients">CC &nbsp;&nbsp;<button className="btn btn-xs btn-success" data-toggle="modal" data-target="#cc_tag_modal"> Use Tags</button> </label>
                                                <div className="form-group col-md-12">
                                                    <Select
                                                        options={props.c_state.contacts_dropdown}
                                                        name="cc"
                                                        value={props.c_state.email_details.cc ? typeof props.c_state.email_details.cc !== "string" ? props.c_state.email_details.cc : JSON.parse(props.c_state.email_details.cc.replace(/'/g, '"')) : ''}
                                                        id="custom_select"
                                                        onChange={(value) => props.handleChange({ "field": "cc", "value": value }, 'email_details')}
                                                        isMulti
                                                        style={{ width: '100%' }}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="CC"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input className="form-control" placeholder="Subject:" onChange={(event) => props.handleChange({ "field": "subject", "value": event.target.value }, 'email_details')} value={props.c_state.email_details.subject ? props.c_state.email_details.subject : ''} />
                                    </div>
                                    <div className="form-group">
                                        {/* <textarea id="compose-textarea" className="form-control" style={{ height: 300 }} onChange={(event) => props.handleChange({ "field": "message", "value": event.target.value })} value={props.c_state.email_details.message ? props.c_state.email_details.message : ''} placeholder="Type your message here..."> </textarea> */}
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={props.c_state.email_details.message ? props.c_state.email_details.message : "<p>Type your message here...</p>"}
                                            onReady={editor => {
                                                if (editor) {
                                                    // You can store the "editor" and use when it is needed.
                                                    editor.ui.view.editable.element.style.minHeight = "300px";
                                                }
                                            }}
                                            onChange={(event, editor) => {
                                                if (editor) {
                                                    const data = editor.getData();
                                                    // console.log({ event, editor, data });
                                                    props.handleChange({ "field": "message", "value": data }, 'email_details')
                                                }
                                            }}
                                            onBlur={(event, editor) => {
                                                if (editor) {
                                                    // console.log('Blur.', editor);
                                                    editor.ui.view.editable.element.style.minHeight = "300px";
                                                }
                                            }}
                                            onFocus={(event, editor) => {
                                                if (editor) {
                                                    // console.log('Focus.', editor);
                                                    editor.ui.view.editable.element.style.minHeight = "300px";
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="form-group">
                                            <div className="btn btn-default btn-file fileinput-button" data-toggle="modal" data-target="#attachments_modal">
                                                <i className="fas fa-paperclip"></i> Add Attachments
                                                {/* <input type="file" name="attachments[]" multiple onChange={(event) => props.handleChange({ "field": "attachments", "value": event.target.files })} /> */}
                                            </div>
                                        </div>
                                        {
                                            props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                                <div className="form-group">
                                                    <div className="btn btn-default btn-file fileinput-button" onClick={props.clear_attachments}>
                                                        <i className="fas fa-times"></i> Clear Attachments
                                                        {/* <input type="button" onClick={props.clear_attachments} /> */}
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <div className="row">
                                        {
                                            props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                                props.c_state.a_type === "emails" ?
                                                    <div className="table table-striped files" id="previews">
                                                        {/* <strong className="lead" style={{ fontWeight: 'bold' }}>Selected Documents: </strong> */}
                                                        {
                                                            props.c_state.my_computer_selected.map((data, index) => {
                                                                return (
                                                                    <span key={index} className="lead" data-dz-name> {data.file_name}, </span>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            props.c_state.my_documents_selected.map((data, index) => {
                                                                return (
                                                                    <span key={index} className="lead" data-dz-name> {data.name}{data.ext}, </span>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            props.c_state.files_selected.map((data, index) => {
                                                                return (
                                                                    <span key={index} className="lead" data-dz-name> {data.filename}{data.filetype}, </span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    : null
                                                : null
                                        }
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="float-right">
                                        {/* <button type="button" className="btn btn-default"><i className="fas fa-pencil-alt"></i> Draft</button> */}
                                        <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="submit" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.send_email}><i className="far fa-envelope"></i> Send</button></span>
                                    </div>
                                    <Link to="/emails" type="reset" className="btn btn-default"><i className="fas fa-times"></i> Discard</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* modals */}

                    <div className="modal fade" id="recipients_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Select Tags</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <Select
                                            options={props.c_state.tags_dropdown}
                                            name="tags"
                                            value={props.c_state.email_details.tags ? typeof props.c_state.email_details.tags !== "string" ? props.c_state.email_details.tags : JSON.parse(props.c_state.email_details.tags.replace(/'/g, '"')) : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'email_details')}
                                            isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Tags"
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_recipients_tag_modal">Close</button>
                                    <button type="button" className="btn btn-primary" onClick={props.filter_by_tags}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="cc_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Select Tags</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <Select
                                            options={props.c_state.tags_dropdown}
                                            name="cc_tags"
                                            value={props.c_state.email_details.cc_tags ? typeof props.c_state.email_details.cc_tags !== "string" ? props.c_state.email_details.cc_tags : JSON.parse(props.c_state.email_details.cc_tags.replace(/'/g, '"')) : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "cc_tags", "value": value }, 'email_details')}
                                            isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Tags"
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_cc_tag_modal">Close</button>
                                    <button type="button" className="btn btn-primary" onClick={props.filter_by_cc_tags}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <AddAttachmentModal
                        handleChange={props.handleChange}
                        templates={props.templates}
                        attachments={props.attachments}
                        set_attachment_info={props.set_attachment_info}
                        set_template_modal_content={props.set_template_modal_content}
                        select_my_document={props.select_my_document}
                        select_template={props.select_template}
                        select_files={props.select_files}
                        enter_folder={props.enter_folder}
                        click_breadcrumbs={props.click_breadcrumbs}
                        c_state={props.c_state}
                        use_template={props.use_template}
                        auth={props.auth}
                        save_attachments={props.save_attachments}
                        search_attachment={props.search_attachment}
                    />

                    <ViewAttachmentModal
                        c_state={props.c_state}
                        clear_attachment_info={props.clear_attachment_info}
                        file_type={props.file_type}
                        download={props.download}
                    />
                </div>
            </section>
        </div>
    )
}

export default ComposeEmail
