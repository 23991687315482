import types from '../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.DASHBOARD_LOADING,
            payload: action
        })
    }
}

export const serverGetDashboardInfo = (token) => {
    return async (dispatch) => {

        dispatch({
            type: types.DASHBOARD_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {};

        await axios({
            headers,
            url: 'get_dashboard_info',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.DASHBOARD,
                    payload: response.data
                }); 
                dispatch({
                    type: types.DASHBOARD_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.DASHBOARD_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.DASHBOARD_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.DASHBOARD_LOADING,
                    payload: "false"
                });
                return;
            }
        }).catch((err) => {
            dispatch({
                type: types.DASHBOARD_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.DASHBOARD_LOADING,
                payload: "false"
            });
        })
    }
}
