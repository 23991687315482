import types from '../types'
import axios from 'axios'

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.PRIVILEGES_LOADING,
            payload: action
        })
    }
}

export const serverGetPrivileges = (token) => {
    return (dispatch) => {

        dispatch({
            type: types.PRIVILEGES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'get_privileges',
            data: {},
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.PRIVILEGES,
                    payload: response.data
                }); 
                dispatch({
                    type: types.PRIVILEGES_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.PRIVILEGES_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.PRIVILEGES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.PRIVILEGES_LOADING,
                    payload: "false"
                });
                return;
            }
        }).catch((err) => {
            // console.log(err);
            dispatch({
                type: types.PRIVILEGES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.PRIVILEGES_LOADING,
                payload: "false"
            });
            return;
        })
    }
}


export const serverSavePrivileges = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.PRIVILEGES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'save_privileges',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.PRIVILEGES_SUCCESS,
                    payload: response.data
                }); 
                dispatch({
                    type: types.REASSIGN_PRIVILEGES,
                    payload: response.data
                }); 
                dispatch({
                    type: types.PRIVILEGES_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.PRIVILEGES_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.PRIVILEGES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.PRIVILEGES_LOADING,
                    payload: "false"
                });
                return;
            }
        }).catch((err) => {
            // console.log(err);
            dispatch({
                type: types.PRIVILEGES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.PRIVILEGES_LOADING,
                payload: "false"
            });
            return;
        })
    }
}

export const serverGetContactPrivileges = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.PRIVILEGES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'get_contact_privileges',
            data: {
                user: data
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.PRIVILEGES_SUCCESS,
                    payload: response.data
                }); 
                dispatch({
                    type: types.PRIVILEGES_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" &&(response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.PRIVILEGES_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.PRIVILEGES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.PRIVILEGES_LOADING,
                    payload: "false"
                });
                return;
            }
        }).catch((err) => {
            // console.log(err);
            dispatch({
                type: types.PRIVILEGES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.PRIVILEGES_LOADING,
                payload: "false"
            });
            return;
        })
    }
}


export const serverDisableAccount = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.PRIVILEGES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'disable_account',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.PRIVILEGES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.PRIVILEGES_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
            response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                });
                dispatch({
                    type: types.PRIVILEGES_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.PRIVILEGES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.PRIVILEGES_LOADING,
                    payload: "false"
                });
                return;
            }
        }).catch((err) => {
            // console.log(err);
            dispatch({
                type: types.PRIVILEGES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.PRIVILEGES_LOADING,
                payload: "false"
            });
            return;
        })
    }
}