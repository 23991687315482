import types from './../types'
import axios from 'axios'


export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.LOADING,
            payload: action
        })
    }
}

export const clearData = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.CLEAR_DATA,
            payload: action
        })
    }
}

export const serverLogin = (data) => {
    return async (dispatch) => {

        const headers = {
            "Content-type": "application/json"
        }

        await axios({
            headers,
            url: 'login',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                return dispatch({
                    type: types.LOGIN_SUCCESS,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.LOGIN_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                });

                return
            }
        }).catch((err) => {
            dispatch({
                type: types.LOGIN_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.LOADING,
                payload: "false"
            });
        })
    }
}

export const serverVerify = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.LOADING,
            payload: "true"
        })

        const headers = {
            "Content-type": "application/json"
        }

        await axios({
            headers,
            url: 'verify',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.LOGIN_VERIFY,
                    payload: response.data
                })
                dispatch({
                    type: types.LOADING,
                    payload: "flase"
                })
                return
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.LOGIN_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                });

                return
            }
        }).catch((err) => {
            dispatch({
                type: types.LOGIN_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.LOADING,
                payload: "false"
            });

            return
        })
    }
}

export const serverResetPassword = (data) => {
    return async (dispatch) => {

        dispatch({
            type: types.LOADING,
            payload: "true"
        })

        const headers = {
            "Content-type": "application/json"
        }

        await axios({
            headers,
            url: 'reset_password',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.LOGIN_VERIFY,
                    payload: response.data
                })
                dispatch({
                    type: types.LOADING,
                    payload: "flase"
                })
                return
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.LOGIN_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                });

                return
            }
        }).catch((err) => {
            dispatch({
                type: types.LOGIN_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.LOADING,
                payload: "false"
            });

            return
        })
    }
}

export const serverSwitchAccount = (data, token) => {
    return async (dispatch) => {

        const headers = {
            "Content-type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: 'switch_account',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                return dispatch({
                    type: types.LOGIN_SUCCESS,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.LOGIN_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                });

                return
            }
        }).catch((err) => {
            dispatch({
                type: types.LOGIN_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.LOADING,
                payload: "false"
            });
        })
    }
}


export const checkUser = (token) => {
    return async (dispatch) => {

        const headers = {
            "Content-type": "application/json",
            "Authorization": token
        }

        await axios({
            headers,
            url: 'check_user',
            data: {},
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                return dispatch({
                    type: types.LOGIN_SUCCESS,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
                dispatch({
                    type: types.LOADING,
                    payload: "false"
                });

                return
            }
        }).catch((err) => {
            dispatch({
                type: types.DO_LOGOUT,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.LOADING,
                payload: "false"
            });
        })
    }
}

// export const checkUser = () => {
//     return (dispatch) => {
//         if (localStorage.getItem("user")) {
//             return dispatch({
//                 type: types.CHECK_USER,
//                 payload: JSON.parse(localStorage.getItem("user"))
//             })
//         } else {
//             return dispatch({
//                 type: types.DO_LOGOUT,
//                 payload: {
//                     "sucess": "false",
//                     "msg": "Your request failed! Please try again",
//                     "data": []
//                 }
//             })
//         }
//     }
// };