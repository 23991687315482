import React, { Component } from 'react'
import Profile from './Profile';
import Main from '../LayoutComponent/Main';
import { connect } from 'react-redux';
import { clearData } from '../../actions/AuthAction'
import { serverEditProfile, serverGetUserActivity, serverLoad } from '../../actions/ProfileAction';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const $ = require("jquery");

class ProfileContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profile: [],
            tab: this.props.auth.user ? this.props.auth.user.privileges.includes("view_timeline") ? 'timeline' : this.props.auth.user.privileges.includes("update_profile") ? 'settings' : '' : '' ,
            activities: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.editProfile = this.editProfile.bind(this);
        this.changeTab = this.changeTab.bind(this);
    }

    componentDidMount() {

        // console.log(this.props);

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/");
            return
        }

        this.setState({ profile: this.props.auth.user });

        this.props.serverGetUserActivity(this.props.auth.user.token);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.profile.activities !== this.props.profile.activities) {
            this.setState({ activities: [...this.props.profile.activities] });
        }
    }

    handleChange = (data) => {
        this.setState(prevState => ({
            profile: {                   // object that we want to update
                ...prevState.profile,    // keep all other key-value pairs
                [data.field]: data.value     // update the value of specific key
            }
        }));
    }

    editProfile = () => {

    }

    changeTab = (tab) => {
        this.setState({tab: tab});
    }

    render() {
        return (
            <>
                <Main>
                    <Profile
                        c_state={{ ...this.state }}
                        auth={this.props.auth}
                        profile={this.props.profile}
                        handleChange={this.handleChange}
                        edit_profile={this.editProfile}
                        change_tab={this.changeTab}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        profile: { ...state.profile }
    }
}

export default connect(mapStateToProps,
    {
        serverLoad,
        clearData,
        // checkUser,
        serverEditProfile,
        serverGetUserActivity
    }
)(ProfileContainer)
