import types from './../types';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let defaultState = {
    isLoading: "false",
    correspondence_id: null,
    saved_attachments: [],
    correspondence_categories: {
        inbox: {},
        sent: {},
        drafts: {},
        archived: {},
    },
    chosen_category: "Inbox",
    single_correspondence: [],
    correspondence_details: {},
    reload: "false",
    email_details: [],
    clear_send_correspondence: "false"
}

const Correspondence = (state = defaultState, action) => {

    let newState = { ...state }

    let { type, payload } = action;

    switch (type) {

        case types.CORRESPONDENCE_LOADING:

            newState.isLoading = payload;

            return newState;

        case types.CORRESPONDENCE_ID:

            newState.correspondence_id = payload.data;

            return newState;

        case types.CORRESPONDENCE_SUCCESS:

            if (payload.msg === "Attachments saved successfully") {
                newState.saved_attachments = payload.data;
            }

            if (payload.msg === "Attachments cleared successfully") {
                newState.saved_attachments = [];
            }

            if (payload.msg === "Correspondence loaded successfully") {
                if (payload.data.correspondence_category === 'Inbox')
                    newState.correspondence_categories.inbox = payload.data;
                if (payload.data.correspondence_category === 'Sent')
                    newState.correspondence_categories.sent = payload.data;
                if (payload.data.correspondence_category === 'Drafts')
                    newState.correspondence_categories.drafts = payload.data;
                if (payload.data.correspondence_category === 'Archived')
                    newState.correspondence_categories.archived = payload.data;

                newState.chosen_category = payload.data.correspondence_category;
            }

            if (payload.msg === "Correspondence details loaded successfully") {
                newState.correspondence_details = payload.data;
                newState.reload = "false";
            }

            if (payload.msg === "Correspondence closed successfully" || payload.msg === "Correspondence re-opened successfully") {
                newState.reload = "true";
                newState.single_correspondence.single_correspondence.closed = payload.data;
            }

            if (payload.msg === "Action updated successfully" || payload.msg === "Action nullified successfully" || payload.msg === "Action added successfully" ||
                payload.msg === "Message forwarded successfully" || payload.msg === "Trail archived successfully" || payload.msg === "Trail nullified successfully") {
                newState.reload = "true";
            }

            if (payload.msg === "Correspondence sent successfully" || payload.msg === "Draft saved successfully") {
                newState.clear_send_correspondence = Math.random();
            }

            if (payload.msg !== "Correspondence loaded successfully" && payload.msg !== "Correspondence details loaded successfully" &&
                payload.msg !== "Trail seen") {
                toast.success(payload.msg, {
                    position: 'top-center'
                });
            }

            // if (newState.reload === "true") {
            //     newState.reload = "false";
            // }

            return newState;

        case types.CORRESPONDENCE_FAIL:

            toast.error(payload.msg, {
                position: 'top-center',
                autoClose: 5000
            });

            return newState;

        case types.SINGLE_CORRESPONDENCE:

            newState.single_correspondence = payload;

            return newState;

        case types.EMAIL_DETAILS:

            newState.email_details = payload;

            return newState;

        default:
            return newState;
    }
}

export default Correspondence