import React from 'react';
import { Link } from 'react-router-dom';

const Login = (props) => {

    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <Link tp="#" className="h1"><b>i</b>Cubicle</Link>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Sign in to start your session</p>

                        <div className="input-group mb-3">
                            <input type="email" value={props.email} onChange={props.handleChange} name="email" className="form-control" placeholder="Email" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-envelope"></span>
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" value={props.password} onChange={props.handleChange} name="password" className="form-control" placeholder="Password" />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock"></span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-7">
                                <div className="icheck-primary">
                                    <input type="checkbox" id="remember" />
                                    <label htmlFor="remember">
                                        &nbsp;Remember Me
                                    </label>
                                </div>
                            </div>
                            <div className="col-5" align="right">
                                <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}
                                    <button className="btn btn-sm btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={() => props.login_func()}>Sign In</button></span>
                            </div>
                        </div>
                        {/* <div className="social-auth-links text-center mt-2 mb-3">
                            <button className="btn btn-block btn-primary"><i className="fab fa-facebook mr-2"></i> Sign in using Facebook</button>
                            <button className="btn btn-block btn-danger"><i className="fab fa-google-plus mr-2"></i> Sign in using Google+</button>
                        </div> */}

                        <p className="mb-1">
                            <Link to="/reset_password">I forgot my password</Link>
                        </p>
                        {/* <p className="mb-0">
                            <Link to="#" className="text-center">Register a new membership</Link>
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Login