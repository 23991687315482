const types = {
    LOADING: 'LOADING',
    CLEAR_DATA: 'CLEAR_DATA',
    CHECK_USER: 'CHECK_USER',
    SERVER_URL: 'https://correspondence.icubicle.net/',
    SOCKET_URL: 'https://io.icubicle.net/',

    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    DO_LOGOUT: 'DO_LOGOUT',
    REASSIGN_PRIVILEGES: 'REASSIGN_PRIVILEGES',
    LOGIN_VERIFY: 'LOGIN_VERIFY',

    DASHBOARD_LOADING: 'DASHBOARD_LOADING',
    DASHBOARD_FAIL: 'DASHBOARD_FAIL',
    DASHBOARD: 'DASHBOARD',
    DASHBOARD_SUCCESS: 'DASHBOARD_SUCCESS',

    CONTACTS_LOADING: 'CONTACTS_LOADING',
    CONTACTS_FAIL: 'CONTACTS_FAIL',
    ALL_CONTACTS: 'ALL_CONTACTS',
    CONTACTS_SUCCESS: 'CONTACTS_SUCCESS',

    COMPANY_FAIL: 'COMPANY_FAIL',
    ALL_COMPANIES: 'ALL_COMPANIES',
    COMPANY_SUCCESS: 'COMPANY_SUCCESS',

    GROUP_FAIL: 'GROUP_FAIL',
    ALL_GROUPS: 'ALL_GROUPS',
    GROUP_SUCCESS: 'GROUP_SUCCESS',

    TAGS_LOADING: 'TAGS_LOADING',
    TAGS_FAIL: 'TAGS_FAIL',
    TAGS: 'TAGS',
    TAGS_SUCCESS: 'TAGS_SUCCESS',

    PROFILE_FAIL: 'PROFILE_FAIL',
    PROFILE_SUCCESS: 'PROFILE_SUCCESS',
    PROFILE_LOADING: 'PROFILE_LOADING',

    TASKS_LOADING: 'TASKS_LOADING',
    TASKS_FAIL: 'TASKS_FAIL',
    TASKS: 'TASKS',
    TASKS_SUCCESS: 'TASKS_SUCCESS',

    TEMPLATES_FAIL: 'TEMPLATES_FAIL',
    TEMPLATES: 'TEMPLATES',
    TEMPLATES_SUCCESS: 'TEMPLATES_SUCCESS',
    TEMPLATES_EDIT: 'TEMPLATES_EDIT',
    TEMPLATES_LOADING: 'TEMPLATES_LOADING',
    DOCUMENTS: 'DOCUMENTS',
    DOCUMENTS_EDIT: 'DOCUMENTS_EDIT',
    ALL_FILES: 'ALL_FILES',

    EMAIL_LOADING: 'EMAIL_LOADING',
    EMAIL_FAIL: 'EMAIL_FAIL',
    EMAIL_CONFIG: 'EMAIL_CONFIG',
    EMAIL_SUCCESS: 'EMAIL_SUCCESS',
    SINGLE_EMAIL: 'SINGLE_EMAIL',

    CORRESPONDENCE_LOADING: 'CORRESPONDENCE_LOADING',
    CORRESPONDENCE_ID: 'CORRESPONDENCE_ID',
    CORRESPONDENCE_FAIL: 'CORRESPONDENCE_FAIL',
    CORRESPONDENCE_SUCCESS: 'CORRESPONDENCE_SUCCESS',
    SINGLE_CORRESPONDENCE: 'SINGLE_CORRESPONDENCE',
    EMAIL_DETAILS: 'EMAIL_DETAILS',

    ATTACHMENTS_FAIL: 'ATTACHMENTS_FAIL',
    ATTACHMENTS_SUCCESS: 'ATTACHMENTS_SUCCESS',

    ROUTES_LOADING: 'ROUTES_LOADING',
    ROUTES_FAIL: 'ROUTES_FAIL',
    ALL_ROUTES: 'ALL_ROUTES',
    ROUTES_SUCCESS: 'ROUTES_SUCCESS',

    CONVERSIONS_LOADING: 'CONVERSIONS_LOADING',
    CONVERSIONS_FAIL: 'CONVERSIONS_FAIL',
    CONVERSIONS: 'CONVERSIONS',
    CONVERSIONS_SUCCESS: 'CONVERSIONS_SUCCESS',

    PRIVILEGES_LOADING: 'PRIVILEGES_LOADING',
    PRIVILEGES_FAIL: 'PRIVILEGES_FAIL',
    PRIVILEGES: 'PRIVILEGES',
    PRIVILEGES_SUCCESS: 'PRIVILEGES_SUCCESS',

    ROLES_LOADING: 'ROLES_LOADING',
    ROLES_FAIL: 'ROLES_FAIL',
    ROLES: 'ROLES',
    ROLES_SUCCESS: 'ROLES_SUCCESS',
}

export default types
