import React, { Component } from 'react'

import Roles from './Roles';
import Main from './../LayoutComponent/Main';
import { connect } from 'react-redux';
// import { checkUser } from '../../actions/AuthAction';
import { serverLoad, serverGetAllPrivileges, serverCreateUserRole, serverUpdateUserRole, serverAssignUserRole } from '../../actions/RolesAction';
import { serverGetContacts } from '../../actions/ContactsAction'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class RolesContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            role_details: {},
            contacts_dropdown: [],
            roles_dropdown: [],
            selected_privilege_category: [],
            selected_privileges: [],
            selected_roles: [],
            final_selected_privileges: [],
            final_selected_roles: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.selectPrivilege = this.selectPrivilege.bind(this);
        this.selectRole = this.selectRole.bind(this);
        this.createUserRole = this.createUserRole.bind(this);
        this.updateUserRole = this.updateUserRole.bind(this);
    }

    componentDidMount() {

        // this.props.checkUser();

        if (this.props.auth.user == null) {
            this.props.history.push("/")
        }

        if (this.props.auth.user) {
            if (!this.props.contacts.contacts.length) {
                this.props.serverGetContacts(this.props.auth.user.token);
            } else {
                let arr = []

                // eslint-disable-next-line array-callback-return
                this.props.contacts.contacts.filter((data, index) => {
                    let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company_name})` };
                    arr.push(v);
                });

                this.setState({ contacts_dropdown: arr });
            }

            this.props.serverGetAllPrivileges(this.props.auth.user.token);
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.contacts !== this.props.contacts) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.contacts.contacts.filter((data, index) => {
                let v = { "value": data.contact_id, "label": `${data.first_name} ${data.last_name} (${data.company_name})` };
                arr.push(v);
            });

            this.setState({ contacts_dropdown: arr });
        }

        if (prevProps.roles.rand !== this.props.roles.rand) {
            this.setState({ role_details: {}, final_selected_privileges: [], selected_privileges: [], final_selected_roles: [], selected_roles: [] });
        }

        if (prevProps.roles.roles !== this.props.roles.roles) {
            let arr = []

            // eslint-disable-next-line array-callback-return
            this.props.roles.roles.filter((data, index) => {
                let v = { "value": data.role, "label": data.role };
                arr.push(v);
            });

            this.setState({ roles_dropdown: arr, role_details: {}, final_selected_privileges: [], selected_privileges: [], final_selected_roles: [], selected_roles: [] });
        }
    }

    handleChange = (data) => {

        let role_details = { ...this.state.role_details };

        role_details[data.field] = data.value;

        this.setState({ role_details: role_details });

        if (data.field === "existing_role") {

            // this.setState({ selected_roles: [], final_selected_roles: []});

            let chosen_roles = [];

            // eslint-disable-next-line array-callback-return
            this.props.roles.roles.filter((d, i) => {
                if (d.role === data.value.value) {
                    d.privileges.filter((da, ia) => {
                        chosen_roles.push(da.privilege);
                    });

                }
            });

            // let selected_roles = { ...this.state.selected_roles };
            let selected_roles = [];
            // eslint-disable-next-line array-callback-return
            this.props.roles.privileges.filter((data, index) => {
                if (chosen_roles.includes(data.id + "")) {
                    selected_roles[data.id] = data;
                }
            });

            this.setState({ selected_roles: selected_roles, final_selected_roles: selected_roles });

        }

    }

    selectPrivilege = (data) => {

        let newState = { ...this.state.selected_privileges };

        if (newState[data.id]) {
            delete newState[data.id];
        } else {
            newState[data.id] = data;
        }

        this.setState({
            selected_privileges: newState
        });

        let results = Object.values(newState);

        // console.log(results);

        this.setState({ final_selected_privileges: results })
    }

    selectRole = (data) => {

        let newState = { ...this.state.selected_roles };

        if (newState[data.id]) {
            delete newState[data.id];
        } else {
            newState[data.id] = data;
        }

        this.setState({
            selected_roles: newState
        });

        let results = Object.values(newState);

        this.setState({ final_selected_roles: results })
    }

    createUserRole = () => {

        if (!this.state.role_details.new_role) {
            toast.error("Request failed! Please enter a role", {
                position: 'top-center',
                autoClose: 5000
            });

            return
        }

        if (!this.state.final_selected_privileges.length) {
            toast.error("Request failed! Please select at least a single privilege", {
                position: 'top-center',
                autoClose: 5000
            });

            return
        }

        let obj = {
            new_role: this.state.role_details.new_role,
            selected_privileges: this.state.final_selected_privileges
        }

        this.props.serverCreateUserRole(obj, this.props.auth.user.token);

        return
    }

    updateUserRole = () => {

        if (!this.state.role_details.existing_role) {
            toast.error("Request failed! Please select a role", {
                position: 'top-center',
                autoClose: 5000
            });

            return
        }

        if (!this.state.final_selected_roles.length) {
            toast.error("Request failed! Please select at least a single privilege", {
                position: 'top-center',
                autoClose: 5000
            });

            return
        }

        let obj = {
            existing_role: this.state.role_details.existing_role.value,
            selected_privileges: this.state.final_selected_roles
        }

        this.props.serverUpdateUserRole(obj, this.props.auth.user.token);
    }

    assignUserRole = () => {
        if (!this.state.role_details.new_existing_role || !this.state.role_details.users) {
            toast.error("Request failed! Please fill all required fields", {
                position: 'top-center',
                autoClose: 5000
            });

            return
        }

        let obj = {
            new_existing_role: this.state.role_details.new_existing_role.value,
            users: this.state.role_details.users.value
        }

        this.props.serverAssignUserRole(obj, this.props.auth.user.token);
    }

    render() {
        return (
            <>
                <Main>
                    <Roles
                        c_state={{ ...this.state }}
                        roles={this.props.roles}
                        auth={this.props.auth}
                        handleChange={this.handleChange}
                        select_privilege={this.selectPrivilege}
                        select_role={this.selectRole}
                        create_user_role={this.createUserRole}
                        update_user_role={this.updateUserRole}
                        assign_user_role={this.assignUserRole}
                    />
                </Main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: { ...state.auth },
        roles: { ...state.roles },
        contacts: { ...state.contacts },
    }
}

export default connect(mapStateToProps, {
    // checkUser,
    serverLoad,
    serverGetContacts,
    serverGetAllPrivileges,
    serverCreateUserRole,
    serverUpdateUserRole,
    serverAssignUserRole
})(RolesContainer)


