/* eslint-disable array-callback-return */
import React from 'react'
import { Link } from 'react-router-dom';
import AddAttachmentModal from '../AttachmentComponent/AddAttachmentModal';
import ViewAttachmentModal from '../AttachmentComponent/ViewAttachmentModal';
import types from '../../types';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TextField from '@material-ui/core/TextField';

const ComposeCorrespondence = (props) => {

    // console.log(props.routes.routes);

    return (
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Compose Correspondence</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/correspondence">View Correspondence</Link></li>
                                <li className="breadcrumb-item active">Compose Correspondence</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">Compose New Message</h3>

                                    <div className="card-tools">
                                        <Link to="/correspondence" className="btn btn-sm btn-outline-danger">Back to Inbox</Link>&nbsp;
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="form-group">
                                        {/* &nbsp;&nbsp;<button className="btn btn-xs btn-success" data-toggle="modal" data-target="#from_tag_modal"> Use Tags</button> */}
                                        <label htmlFor="from">From * </label>
                                        <Select
                                            options={props.c_state.contacts_dropdown}
                                            name="from"
                                            value={props.c_state.correspondence_details.from ? typeof props.c_state.correspondence_details.from !== "string" ? props.c_state.correspondence_details.from : JSON.parse(props.c_state.correspondence_details.from.replace(/'/g, '"')) : props.auth.user ? [{ "value": `${props.auth.user.contact_id}`, "label": `${props.auth.user.first_name} ${props.auth.user.last_name}` }] : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "from", "value": value }, 'correspondence')}
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="From"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input className="form-control" placeholder="Subject:" onChange={(event) => props.handleChange({ "field": "subject", "value": event.target.value }, 'correspondence')} value={props.c_state.correspondence_details.subject ? props.c_state.correspondence_details.subject : ''} />
                                    </div>
                                    <div className="form-group">
                                        {/* <textarea id="compose-textarea" className="form-control" style={{ height: 300 }} onChange={(event) => props.handleChange({ "field": "message", "value": event.target.value }, 'correspondence')} value={props.c_state.correspondence_details.message ? props.c_state.correspondence_details.message : ''} placeholder="Type your message here..."> </textarea> */}
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={props.c_state.correspondence_details.body ? props.c_state.correspondence_details.body : "<p>Type body of correspondence here...</p>"}
                                            onReady={editor => {
                                                if (editor) {
                                                    // You can store the "editor" and use when it is needed.
                                                    editor.ui.view.editable.element.style.minHeight = "200px";
                                                }
                                            }}
                                            onChange={(event, editor) => {
                                                if (editor) {
                                                    const data = editor.getData();
                                                    // console.log({ event, editor, data });
                                                    props.handleChange({ "field": "body", "value": data }, 'correspondence')
                                                }
                                            }}
                                            onBlur={(event, editor) => {
                                                // console.log('Blur.', editor);
                                                if (editor)
                                                    editor.ui.view.editable.element.style.minHeight = "200px";
                                            }}
                                            onFocus={(event, editor) => {
                                                // console.log('Focus.', editor);
                                                if (editor)
                                                    editor.ui.view.editable.element.style.minHeight = "200px";
                                            }}
                                        />
                                    </div>

                                    <div className="row">&nbsp;&nbsp;
                                        <div className="form-group">
                                            <div className="btn btn-default btn-file fileinput-button" data-toggle="modal" data-target="#attachments_modal">
                                                <i className="fas fa-paperclip"></i> Add Attachments To Main Correspondence
                                                {/* <input type="file" name="attachments[]" multiple onChange={(event) => props.handleChange({ "field": "attachments", "value": event.target.files })} /> */}
                                            </div>
                                        </div>
                                        {
                                            props.correspondence.saved_attachments.length ?
                                                <div className="form-group">
                                                    <div className="btn btn-default btn-file fileinput-button" onClick={props.clear_attachments}>
                                                        <i className="fas fa-times"></i> Clear Attachments
                                                        {/* <input type="button" onClick={props.clear_attachments} /> */}
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <div className="row">
                                        {
                                            props.c_state.my_computer_selected.length || props.c_state.my_documents_selected.length || props.c_state.files_selected.length ?
                                                props.c_state.a_type === "correspondence" ?
                                                    <div className="table table-striped files" id="previews">
                                                        {/* <strong className="lead" style={{ fontWeight: 'bold' }}>Selected Documents: </strong> */}
                                                        {
                                                            props.c_state.my_computer_selected.map((data, index) => {
                                                                return (
                                                                    <span key={index} className="lead" data-dz-name> {data.file_name}, </span>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            props.c_state.my_documents_selected.map((data, index) => {
                                                                return (
                                                                    <span key={index} className="lead" data-dz-name> {data.name}{data.ext}, </span>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            props.c_state.files_selected.map((data, index) => {
                                                                return (
                                                                    <span key={index} className="lead" data-dz-name> {data.filename}{data.filetype}, </span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    : null
                                                : null
                                        }
                                    </div>

                                    <div className="card card-info">
                                        <div className="card-header">
                                            <h3 className="card-title">Recipients</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="use_route">Use Route? </label><br />
                                                <label>No <input type="radio" data-toggle="collapse" href="#collapse" onChange={() => props.handleChange({ "field": "use_route", "value": "false" }, 'correspondence')} name="use_route" checked={props.c_state.correspondence_details.use_route === "false" ? true : false} /></label>&nbsp;&nbsp;
                                                <label>Yes <input type="radio" data-toggle="collapse" href="#collapse" onChange={() => props.handleChange({ "field": "use_route", "value": "true" }, 'correspondence')} name="use_route" checked={props.c_state.correspondence_details.use_route === "true" ? true : false} /></label>
                                            </div>
                                            {
                                                props.c_state.correspondence_details.use_route ? props.c_state.correspondence_details.use_route === "true" ?
                                                    <>
                                                        <div className="form-group">
                                                            <select className="form-control" onChange={(event) => props.handleChange({ "field": "selected_route", "value": event.target.value }, 'correspondence')}>
                                                                <option value="">Select Route</option>
                                                                {props.c_state.route_list.map((data, i) => {
                                                                    if (data.correspondence_id === null) {
                                                                        return (
                                                                            <option key={i} value={data.route_id}>{data.title}</option>
                                                                        )
                                                                    }
                                                                })
                                                                }
                                                            </select>
                                                        </div>
                                                        {props.c_state.correspondence_details.selected_route ?
                                                            <>
                                                                <p className="text-danger" style={{ textDecoration: 'underline' }}>NB: This is a cloned version of the original route; updates will be lost once you exit this page.</p>
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Recipient</th>
                                                                            <th>Deadline (Hours)</th>
                                                                            <th>Auto Forward</th>
                                                                            <th>Can Close</th>
                                                                            {/* <th>Expected Response Date</th> */}
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            props.c_state.selected_route_list.route_steps.map((data, i) => {
                                                                                return (
                                                                                    <tr key={i}>
                                                                                        <td>{i + 1}</td>
                                                                                        <td>{props.contact_details(data.user)}</td>
                                                                                        <td>{data.deadline}</td>
                                                                                        <td>{data.auto_forward}</td>
                                                                                        <td>{data.can_close}</td>
                                                                                        {/* <td>{data.expected_response_date}</td> */}
                                                                                        <td className="align-middle">
                                                                                            <div className="btn-group btn-group-sm">
                                                                                                <button className="btn btn-info" title="Edit Route Step" data-toggle="modal" data-target="#edit_route_step_modal" onClick={() => props.set_modal_content(data)}><i className="fas fa-edit"></i></button>&nbsp;
                                                                                                <button className="btn btn-danger" title="Delete Route Step" onClick={() => window.confirm(`Are you sure you want to delete?`) ? props.delete_route_step(data.step_id) : console.log(i)}><i className="fas fa-trash"></i></button>&nbsp;
                                                                                                {i !== 0 ?
                                                                                                    <><button className="btn btn-default" title="Move Up" onClick={() => props.move_up(data)}><i className="fas fa-arrow-up"></i></button>&nbsp;</>
                                                                                                    : null}
                                                                                                {props.c_state.selected_route_list.route_steps.length - 1 !== i ?
                                                                                                    <><button className="btn btn-default" title="Move Down" onClick={() => props.move_down(data)}><i className="fas fa-arrow-down"></i></button>&nbsp;</>
                                                                                                    : null}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <br /><br />
                                                            </>
                                                            : null}
                                                    </>
                                                    : null
                                                    : null
                                            }

                                            {
                                                !props.c_state.correspondence_details.use_route || props.c_state.correspondence_details.use_route !== "true" ?
                                                    <>
                                                        <div className="row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="through">Through &nbsp;&nbsp;<button className="btn btn-xs btn-warning" data-toggle="modal" data-target="#through_tag_modal"> Use Tags</button> </label>
                                                                <Select
                                                                    options={props.c_state.contacts_dropdown}
                                                                    name="through"
                                                                    value={props.c_state.correspondence_details.through ? typeof props.c_state.correspondence_details.through !== "string" ? props.c_state.correspondence_details.through : JSON.parse(props.c_state.correspondence_details.through.replace(/'/g, '"')) : ''}
                                                                    id="custom_select"
                                                                    onChange={(value) => props.handleChange({ "field": "through", "value": value }, 'correspondence')}
                                                                    isMulti
                                                                    style={{ width: '100%' }}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="Through"
                                                                />
                                                            </div>
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="to">To &nbsp;&nbsp;<button className="btn btn-xs btn-danger" data-toggle="modal" data-target="#to_tag_modal"> Use Tags</button> </label>
                                                                <Select
                                                                    options={props.c_state.contacts_dropdown}
                                                                    name="to"
                                                                    value={props.c_state.correspondence_details.to ? typeof props.c_state.correspondence_details.to !== "string" ? props.c_state.correspondence_details.to : JSON.parse(props.c_state.correspondence_details.to.replace(/'/g, '"')) : ''}
                                                                    id="custom_select"
                                                                    onChange={(value) => props.handleChange({ "field": "to", "value": value }, 'correspondence')}
                                                                    isMulti
                                                                    style={{ width: '100%' }}
                                                                    className="basic-multi-select"
                                                                    classNamePrefix="select"
                                                                    placeholder="To"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="row">
                                                                <div className="form-group col-md-6">
                                                                    <label htmlFor="cc">FYI &nbsp;&nbsp;<button className="btn btn-xs btn-info" data-toggle="modal" data-target="#cc_tag_modal"> Use Tags</button> </label>
                                                                    <Select
                                                                        options={props.c_state.contacts_dropdown}
                                                                        name="cc"
                                                                        value={props.c_state.correspondence_details.cc ? typeof props.c_state.correspondence_details.cc !== "string" ? props.c_state.correspondence_details.cc : JSON.parse(props.c_state.correspondence_details.cc.replace(/'/g, '"')) : ''}
                                                                        id="custom_select"
                                                                        onChange={(value) => props.handleChange({ "field": "cc", "value": value }, 'correspondence')}
                                                                        isMulti
                                                                        style={{ width: '100%' }}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        placeholder="FYI"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="form-group col-md-12">
                                                                <label htmlFor="action_type">Correspondence Scope: *</label>
                                                                <select className="form-control" onChange={(event) => props.handleChange({ "field": "action_type", "value": event.target.value }, 'correspondence')} value={props.c_state.correspondence_details.action_type ? props.c_state.correspondence_details.action_type : ""}>
                                                                    <option value=""></option>
                                                                    <option value="Forward To Anyone">Forward To Anyone</option>
                                                                    <option value="Forward Back To Me">Forward Back To Me</option>
                                                                    <option value="Forward To Anyone In This List">Forward To Anyone In This List</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {props.c_state.correspondence_details.action_type ?
                                                            props.c_state.correspondence_details.action_type === "Forward To Anyone In This List" ?
                                                                <div className="row">
                                                                    <div className="form-group col-md-12">
                                                                        <label htmlFor="forward_list">User(s): </label>
                                                                        <Select
                                                                            options={props.c_state.contacts_dropdown}
                                                                            name="forward_list"
                                                                            value={props.c_state.correspondence_details.forward_list ? typeof props.c_state.correspondence_details.forward_list !== "string" ? props.c_state.correspondence_details.forward_list : JSON.parse(props.c_state.correspondence_details.forward_list.replace(/'/g, '"')) : ''}
                                                                            id="custom_select"
                                                                            onChange={(value) => props.handleChange({ "field": "forward_list", "value": value }, 'correspondence')}
                                                                            isMulti
                                                                            style={{ width: '100%' }}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            placeholder="User(s)"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                : null
                                                            : null}

                                                    </>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <br />
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Correspondence Info</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {/* <div className="form-group col-md-6">
                                            <label htmlFor="schedule_date">Schedule Date/Time: </label>
                                            <div className="row col-12">
                                                <input className="form-control col-6" type="date" name="schedule_date" onChange={(event) => props.handleChange({ "field": "schedule_date", "value": event.target.value }, 'correspondence')} value={props.c_state.correspondence_details.schedule_date ? props.c_state.correspondence_details.schedule_date : ''} />
                                                <input className="form-control col-6" type="time" name="schedule_time" onChange={(event) => props.handleChange({ "field": "schedule_time", "value": event.target.value }, 'correspondence')} value={props.c_state.correspondence_details.schedule_time ? props.c_state.correspondence_details.schedule_time : ''} />
                                            </div>
                                        </div> */}
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="source">Correspondence Source: *</label>
                                                    <select className="form-control"
                                                        onChange={(event) => props.handleChange({ "field": "source", "value": event.target.value }, 'correspondence')}
                                                        value={props.correspondence.email_details.subject ? 'Email' : props.c_state.correspondence_details.source ? props.c_state.correspondence_details.source : ''}>
                                                        <option value=""></option>
                                                        <option value="Memo">Memo</option>
                                                        <option value="Correspondence">Correspondence</option>
                                                        <option value="Email">Email</option>
                                                        <option value="Fax">Fax</option>
                                                        <option value="Text Message">Text Message</option>
                                                        <option value="Call">Call</option>
                                                        <option value="Enquiry">Enquiry</option>
                                                        <option value="Envelope">Envelope</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="correspondence_tags">Tag This Correspondence: </label>
                                                    <Select
                                                        options={props.c_state.tags_dropdown}
                                                        name="correspondence_tags"
                                                        value={props.c_state.correspondence_details.correspondence_tags ? typeof props.c_state.correspondence_details.correspondence_tags !== "string" ? props.c_state.correspondence_details.correspondence_tags : JSON.parse(props.c_state.correspondence_details.correspondence_tags.replace(/'/g, '"')) : ''}
                                                        id="custom_select"
                                                        onChange={(value) => props.handleChange({ "field": "correspondence_tags", "value": value }, 'correspondence')}
                                                        isMulti
                                                        style={{ width: '100%' }}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Tags"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="priority">Priority: </label>
                                                    <select
                                                        className="form-control"
                                                        onChange={(event) => props.handleChange({ "field": "priority", "value": event.target.value }, 'correspondence')}
                                                        value={props.c_state.correspondence_details.priority ? props.c_state.correspondence_details.priority : ''}
                                                    >
                                                        <option value=""></option>
                                                        <option value="Low Priority">Low Priority</option>
                                                        <option value="Medium Priority">Medium Priority</option>
                                                        <option value="High Priority">High Priority</option>
                                                    </select>
                                                </div>
                                                {/* <div className="form-group col-md-6">
                                                    <label htmlFor="expected_response_date">Expected Response Date/Time: </label>
                                                    <div className="row col-md-12">
                                                        <input className="form-control col-md-6" type="date" name="expected_response_date" onChange={(event) => props.handleChange({ "field": "expected_response_date", "value": event.target.value }, 'correspondence')} value={props.c_state.correspondence_details.expected_response_date ? props.c_state.correspondence_details.expected_response_date : ''} />
                                                        <input className="form-control col-md-6" type="time" name="expected_response_time" onChange={(event) => props.handleChange({ "field": "expected_response_time", "value": event.target.value }, 'correspondence')} value={props.c_state.correspondence_details.expected_response_time ? props.c_state.correspondence_details.expected_response_time : ''} />
                                                    </div>
                                                </div> */}
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="schedule_date">Date Created *</label><br />
                                                    <input className="form-control" type="date" name="schedule_date" onChange={(event) => props.handleChange({ "field": "schedule_date", "value": event.target.value }, 'correspondence')} value={props.c_state.correspondence_details.schedule_date ? props.c_state.correspondence_details.schedule_date : ''} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="deadline">Deadline *</label><br />
                                                    <input className="form-control" type="date" name="deadline" onChange={(event) => props.handleChange({ "field": "deadline", "value": event.target.value }, 'correspondence')} value={props.c_state.correspondence_details.deadline ? props.c_state.correspondence_details.deadline : ''} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="use_route">Confidential? </label><br />
                                                    <label>No <input type="radio" onChange={() => props.handleChange({ "field": "confidential", "value": "false" }, 'correspondence')} name="confidential" checked={props.c_state.correspondence_details.confidential === "false" ? true : false} /></label>&nbsp;&nbsp;
                                                    <label>Yes <input type="radio" onChange={() => props.handleChange({ "field": "confidential", "value": "true" }, 'correspondence')} name="confidential" checked={props.c_state.correspondence_details.confidential === "true" ? true : false} /></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {props.correspondence.email_details.subject ?
                                        <>
                                            <br />
                                            <div className="card card-info">
                                                <div className="card-header">
                                                    <h3 className="card-title">Attached Email</h3>

                                                    <div className="card-tools">
                                                        <button type="button" className="btn btn-sm btn-info" title="Remove this email attachment" onClick={() => window.confirm(`Are you sure you want to remove this email attachment?`) ? props.remove_email_attachment() : ''}>
                                                            <i className="fas fa-times"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="card-body p-0" style={{ maxHeight: 500, overflowY: 'auto' }}>
                                                    <div className="mailbox-read-info">
                                                        <h5>{props.correspondence.email_details.subject}</h5>
                                                        <h6>To: {props.correspondence.email_details.to}
                                                            <span className="mailbox-read-time float-right">{props.correspondence.email_details.date.toLocaleString('en-GB', { timeZone: 'GMT' })}</span></h6>
                                                    </div>
                                                    {/* <div className="mailbox-controls with-border text-center">
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-default btn-sm" data-container="body" title="Delete">
                                                                <i className="far fa-trash-alt"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-default btn-sm" data-container="body" title="Reply">
                                                                <i className="fas fa-reply"></i>
                                                            </button>
                                                            <button type="button" className="btn btn-default btn-sm" data-container="body" title="Forward">
                                                                <i className="fas fa-share"></i>
                                                            </button>
                                                        </div>
                                                        <button type="button" className="btn btn-default btn-sm" title="Print">
                                                            <i className="fas fa-print"></i>
                                                        </button>
                                                    </div> */}
                                                    <div className="mailbox-read-message">
                                                        <div dangerouslySetInnerHTML={{ __html: props.correspondence.email_details.message }} />
                                                    </div>

                                                    {props.correspondence.email_details.attachments ?
                                                        <div className="card-footer bg-white">
                                                            <ul className="mailbox-attachments d-flex align-items-stretch clearfix">
                                                                {
                                                                    props.correspondence.email_details.attachments.map((data, indx) => {
                                                                        return (
                                                                            <li key={indx}>
                                                                                <span className="mailbox-attachment-icon"><i className="far fa-file"></i></span>

                                                                                <div className="mailbox-attachment-info">
                                                                                    {/* <a href={`${types.SERVER_URL}${data.file_path}`} target="view_attachment" className="mailbox-attachment-name"><i className="fas fa-paperclip"></i> {data.file_name}</a> */}
                                                                                    <Link to="#" className="mailbox-attachment-name" data-toggle="modal" data-target="#view_attachment_modal" onClick={() => props.set_attachment_info(data, 'email_attachments')}><i className="fas fa-paperclip"></i> {data.file_name}</Link>
                                                                                    <span className="mailbox-attachment-size clearfix mt-1">
                                                                                        {/* <span>1,245 KB</span> */}
                                                                                        <a href={`${types.SERVER_URL}${data.file_path}`} target="_blank" className="btn btn-default btn-sm float-right"><i className="fas fa-download"></i></a>
                                                                                    </span>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </>
                                        : null}
                                    <br />
                                    <div className="form-group">
                                        {/* <textarea id="compose-textarea" className="form-control" style={{ height: 300 }} onChange={(event) => props.handleChange({ "field": "message", "value": event.target.value }, 'correspondence')} value={props.c_state.correspondence_details.message ? props.c_state.correspondence_details.message : ''} placeholder="Type your message here..."> </textarea> */}
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={props.c_state.correspondence_details.message ? props.c_state.correspondence_details.message : "<p>Type instructions for your recipients here...</p>"}
                                            onReady={editor => {
                                                if (editor) {
                                                    // You can store the "editor" and use when it is needed.
                                                    editor.ui.view.editable.element.style.minHeight = "300px";
                                                }
                                            }}
                                            onChange={(event, editor) => {
                                                if (editor) {
                                                    const data = editor.getData();
                                                    // console.log({ event, editor, data });
                                                    props.handleChange({ "field": "message", "value": data }, 'correspondence')
                                                }
                                            }}
                                            onBlur={(event, editor) => {
                                                if (editor) {
                                                    // console.log('Blur.', editor);
                                                    editor.ui.view.editable.element.style.minHeight = "300px";
                                                }
                                            }}
                                            onFocus={(event, editor) => {
                                                if (editor) {
                                                    // console.log('Focus.', editor);
                                                    editor.ui.view.editable.element.style.minHeight = "300px";
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="float-right">
                                        <span>{props.correspondence.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}
                                            {/* <button type="button" className="btn btn-default" onClick={() => props.send_correspondence("true")}><i className="fas fa-pencil-alt"></i> Draft</button>&nbsp; */}
                                            <button type="submit" className="btn btn-primary" disabled={props.correspondence.isLoading === "true" ? true : false} onClick={() => props.send_correspondence("false")}><i className="far fa-envelope"></i> Send</button>
                                        </span>
                                    </div>
                                    <Link to="/correspondence" type="reset" className="btn btn-default"><i className="fas fa-times"></i> Discard</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        props.auth.user ?
                            props.auth.user.privileges.includes("add_tasks") ?
                                <button type="button" data-toggle="modal" data-target="#add_task_modal" className="btn btn-primary btn-sm" style={{ position: 'fixed', top: '85vh', left: '95vw', zIndex: 10 }}><i class="fa fa-tasks"></i></button>
                                : null
                            : null
                    }

                    {/* Modals */}

                    <div className="modal fade" id="from_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Select Tags</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <Select
                                            options={props.c_state.tags_dropdown}
                                            name="from_tags"
                                            value={props.c_state.correspondence_details.from_tags ? typeof props.c_state.correspondence_details.from_tags !== "string" ? props.c_state.correspondence_details.from_tags : JSON.parse(props.c_state.correspondence_details.from_tags.replace(/'/g, '"')) : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "from_tags", "value": value }, 'correspondence')}
                                            isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Tags"
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_from_tag_modal">Close</button>
                                    <button type="button" className="btn btn-primary" onClick={props.filter_by_from_tags}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="through_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Select Tags</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <Select
                                            options={props.c_state.tags_dropdown}
                                            name="through_tags"
                                            value={props.c_state.correspondence_details.through_tags ? typeof props.c_state.correspondence_details.through_tags !== "string" ? props.c_state.correspondence_details.through_tags : JSON.parse(props.c_state.correspondence_details.through_tags.replace(/'/g, '"')) : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "through_tags", "value": value }, 'correspondence')}
                                            isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Tags"
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_through_tag_modal">Close</button>
                                    <button type="button" className="btn btn-primary" onClick={props.filter_by_through_tags}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="to_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Select Tags</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <Select
                                            options={props.c_state.tags_dropdown}
                                            name="to_tags"
                                            value={props.c_state.correspondence_details.to_tags ? typeof props.c_state.correspondence_details.to_tags !== "string" ? props.c_state.correspondence_details.to_tags : JSON.parse(props.c_state.correspondence_details.to_tags.replace(/'/g, '"')) : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "to_tags", "value": value }, 'correspondence')}
                                            isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Tags"
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_to_tag_modal">Close</button>
                                    <button type="button" className="btn btn-primary" onClick={props.filter_by_to_tags}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="cc_tag_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Select Tags</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <Select
                                            options={props.c_state.tags_dropdown}
                                            name="cc_tags"
                                            value={props.c_state.correspondence_details.cc_tags ? typeof props.c_state.correspondence_details.cc_tags !== "string" ? props.c_state.correspondence_details.cc_tags : JSON.parse(props.c_state.correspondence_details.cc_tags.replace(/'/g, '"')) : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "cc_tags", "value": value }, 'correspondence')}
                                            isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Tags"
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_cc_tag_modal">Close</button>
                                    <button type="button" className="btn btn-primary" onClick={props.filter_by_cc_tags}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit_route_step_modal" style={{ overflow: "auto" }}>
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Edit Route Step</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-12">
                                        <label htmlFor="user">Recipient *</label>
                                        <Select
                                            options={props.c_state.contacts_dropdown}
                                            name="user"
                                            value={props.c_state.route_step_details.user ? props.contact_tags(props.c_state.route_step_details.user, "user") : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "user", "value": value }, "route_steps")}
                                            // isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="To"
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="cc">FYI</label>
                                        <Select
                                            options={props.c_state.contacts_dropdown}
                                            name="cc"
                                            value={props.c_state.route_step_details.cc ? props.contact_tags(props.c_state.route_step_details.cc, "cc") : ''}
                                            id="custom_select"
                                            onChange={(value) => props.handleChange({ "field": "cc", "value": value }, "route_steps")}
                                            isMulti
                                            style={{ width: '100%' }}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="CC"
                                        />
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="deadline">Deadline (hours) *</label>
                                        <input type="text" value={props.c_state.route_step_details.deadline} onChange={(event) => props.handleChange({ "field": "deadline", "value": event.target.value }, "route_steps")} className="form-control" name="deadline" placeholder="Deadline" />
                                    </div>
                                    {/* <div className="form-group col-12">
                                        <label htmlFor="expected_response_date">Expected Response Date *</label>
                                        <input type="date" value={props.c_state.route_step_details.expected_response_date} onChange={(event) => props.handleChange({ "field": "expected_response_date", "value": event.target.value }, "route_steps")} className="form-control" name="expected_response_date" placeholder="Expected Response Date" />
                                    </div> */}
                                    <div className="form-group col-12">
                                        <label htmlFor="auto_forward">Auto Forward *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "auto_forward", "value": event.target.value }, "route_steps")} value={props.c_state.route_step_details.auto_forward}>
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-12">
                                        <label htmlFor="can_close">Can Close *</label>
                                        <select className="form-control" onChange={(event) => props.handleChange({ "field": "can_close", "value": event.target.value }, "route_steps")} value={props.c_state.route_step_details.can_close}>
                                            <option value=""></option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_route_step_modal" onClick={props.clear_modal_contents}>Close</button>
                                    <span>{props.routes.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.routes.isLoading === "true" ? true : false} onClick={props.edit_route_step}>Save</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="add_task_modal">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Add New Task</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.clear_modal_contents}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="recipients">For *</label>
                                            <Select
                                                options={props.c_state.contacts_dropdown}
                                                name="recipients" value={props.c_state.task_details.recipients ? props.c_state.task_details.recipients : []}
                                                id="custom_select"
                                                onChange={(value) => props.handleChange({ "field": "recipients", "value": value }, 'tasks')}
                                                isMulti
                                                style={{ width: '100%' }}
                                                className="basic-multi-select"
                                                classNamePrefix="select" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="task">Task *</label>
                                            <textarea style={{ resize: 'none' }} type="text" onChange={(event) => props.handleChange({ "field": "task", "value": event.target.value }, 'tasks')} className="form-control" name="task" placeholder="Task" value={props.c_state.task_details.task ? props.c_state.task_details.task : ''}>{props.c_state.task_details.task ? props.c_state.task_details.task : ''}</textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <label htmlFor="deadline_date">Deadline Date *</label>
                                            <TextField
                                                id="date"
                                                type="date"
                                                // defaultValue={props.c_state.task_details.deadline_date}
                                                value={props.c_state.task_details.deadline_date ? props.c_state.task_details.deadline_date : ''}
                                                className="form-control"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(event) => props.handleChange({ "field": "deadline_date", "value": event.target.value }, 'tasks')}
                                            />
                                        </div>
                                        <div className="form-group col-6">
                                            <label htmlFor="deadline_time">Deadline Time *</label>
                                            <TextField
                                                id="time"
                                                type="time"
                                                // defaultValue={props.c_state.task_details.deadline_time}
                                                value={props.c_state.task_details.deadline_time ? props.c_state.task_details.deadline_time : ''}
                                                className="form-control"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                }}
                                                onChange={(event) => props.handleChange({ "field": "deadline_time", "value": event.target.value }, 'tasks')}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-6">
                                            <label htmlFor="tags">Tags</label>
                                            <Select
                                                options={props.c_state.tags_dropdown}
                                                name="tags"
                                                value={props.c_state.task_details.tags ? props.c_state.task_details.tags : ''}
                                                id="custom_select"
                                                onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'tasks')}
                                                isMulti
                                                style={{ width: '100%' }}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                        <div className="form-group col-6">
                                            <label htmlFor="priority">Priority *</label>
                                            <select className="form-control" name="priority" onChange={(event) => props.handleChange({ "field": "priority", "value": event.target.value }, 'tasks')} value={props.c_state.task_details.priority ? props.c_state.task_details.priority : ''}>
                                                <option value="" disabled>--Select--</option>
                                                <option value="3">Low Priority</option>
                                                <option value="2">Medium Priority</option>
                                                <option value="1">High Priority</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-5">
                                            <label htmlFor="reminder_date">Reminder Date *</label>
                                        </div>
                                        <div className="form-group col-5">
                                            <label htmlFor="reminder_time">Reminder Time *</label>
                                        </div>
                                    </div>

                                    {props.c_state.reminders.map((data, index) => {
                                        return (
                                            <div className="row" key={index}>
                                                <div className="form-group col-5">
                                                    <TextField
                                                        id="date"
                                                        type="date"
                                                        // defaultValue={data.r_date}
                                                        value={data.r_date ? data.r_date : ''}
                                                        className="form-control"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={(event) => props.set_reminder_date(event.target.value, index)}
                                                    />
                                                </div>
                                                <div className="form-group col-5">
                                                    <TextField
                                                        id="time"
                                                        type="time"
                                                        // defaultValue={data.r_time}
                                                        value={data.r_time ? data.r_time : ''}
                                                        className="form-control"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 300, // 5 min
                                                        }}
                                                        onChange={(event) => props.set_reminder_time(event.target.value, index)}
                                                    />
                                                </div>
                                                {index === 0 ?
                                                    <div className="form-group col-2">
                                                        <button className="btn btn-sm btn-outline-info" onClick={props.add_reminder}><i className="fa fa-plus"></i> Add New</button>
                                                    </div>

                                                    :
                                                    <div className="form-group col-2">
                                                        <button className="btn btn-sm btn-outline-danger" onClick={() => props.remove_reminder(index)}><i className="fa fa-times"></i> Remove</button>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_task_modal" onClick={props.clear_modal_contents}>Close</button>
                                    <span>{props.tasks.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" onClick={props.add_task}>Save changes</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <AddAttachmentModal
                        handleChange={props.handleChange}
                        templates={props.templates}
                        attachments={props.attachments}
                        set_attachment_info={props.set_attachment_info}
                        set_template_modal_content={props.set_template_modal_content}
                        select_my_document={props.select_my_document}
                        select_template={props.select_template}
                        select_files={props.select_files}
                        enter_folder={props.enter_folder}
                        click_breadcrumbs={props.click_breadcrumbs}
                        c_state={props.c_state}
                        use_template={props.use_template}
                        auth={props.auth}
                        save_attachments={props.save_attachments}
                        search_attachment={props.search_attachment}
                    />

                    <ViewAttachmentModal
                        c_state={props.c_state}
                        clear_attachment_info={props.clear_attachment_info}
                        file_type={props.file_type}
                        download={props.download}
                    />
                </div>
            </section>
        </div>
    )
}

export default ComposeCorrespondence
