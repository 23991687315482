import types from '../types';
import axios from 'axios';

export const serverLoad = (action) => {
    return (dispatch) => {
        return dispatch({
            type: types.TEMPLATES_LOADING,
            payload: action
        })
    }
}

export const serverCreateTemplate = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TEMPLATES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'create_template',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TEMPLATES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TEMPLATES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TEMPLATES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TEMPLATES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverUploadTemplate = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TEMPLATES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "multipart/form-data",
            "Authorization": token
        }

        axios({
            headers,
            url: 'upload_template',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TEMPLATES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TEMPLATES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TEMPLATES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TEMPLATES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverSavePlaceholders = (placeholders, tid, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TEMPLATES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'save_placeholders',
            data: {
                "placeholders": placeholders,
                "tid": tid
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TEMPLATES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TEMPLATES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TEMPLATES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TEMPLATES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverGetTemplates = (token) => {
    return (dispatch) => {

        dispatch({
            type: types.TEMPLATES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {};

        axios({
            headers,
            url: 'get_templates',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TEMPLATES,
                    payload: response.data
                });
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TEMPLATES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            return dispatch({
                type: types.DO_LOGOUT,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
        })
    }
}

export const serverDeleteTemplate = (tid, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TEMPLATES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'delete_template',
            data: {
                "tid": tid
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TEMPLATES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TEMPLATES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TEMPLATES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TEMPLATES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverEditTemplate = (data) => {
    return (dispatch) => {

        dispatch({
            type: types.TEMPLATES_EDIT,
            payload: data
        });

        return

    }
}

export const serverEditPlaceholders = (placeholders, tid, template_name, tags, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TEMPLATES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'edit_placeholders',
            data: {
                "placeholders": placeholders,
                "tid": tid,
                "template_name": template_name,
                "tags": tags
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TEMPLATES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TEMPLATES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TEMPLATES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TEMPLATES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverUseTemplate = (tid, document_name, tags, answers, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TEMPLATES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'use_template',
            data: {
                "document_name": document_name,
                "tags": tags,
                "tid": tid,
                "answers": answers
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TEMPLATES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TEMPLATES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TEMPLATES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TEMPLATES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverGetDocuments = (token) => {
    return (dispatch) => {

        dispatch({
            type: types.TEMPLATES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        let data = {};

        axios({
            headers,
            url: 'get_documents',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.DOCUMENTS,
                    payload: response.data
                })
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return;
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TEMPLATES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            return dispatch({
                type: types.DO_LOGOUT,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
        })
    }
}

export const serverDeleteDocument = (did, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TEMPLATES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'delete_document',
            data: {
                "did": did
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TEMPLATES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TEMPLATES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TEMPLATES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TEMPLATES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverEditDocument = (data) => {
    return (dispatch) => {

        dispatch({
            type: types.DOCUMENTS_EDIT,
            payload: data
        });

        return

    }
}

export const serverRenameDocument = (document, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TEMPLATES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'rename_document',
            data: {
                'document': document
            },
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TEMPLATES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TEMPLATES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TEMPLATES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TEMPLATES_LOADING,
                payload: "false"
            });
            return
        })
    }
}

export const serverCloneTemplate = (data, token) => {
    return (dispatch) => {

        dispatch({
            type: types.TEMPLATES_LOADING,
            payload: "true"
        });

        const headers = {
            "Content-Type": "application/json",
            "Authorization": token
        }

        axios({
            headers,
            url: 'clone_template',
            data,
            method: 'post'
        }).then((response) => {
            if (response.data.success === "true") {
                dispatch({
                    type: types.TEMPLATES_SUCCESS,
                    payload: response.data
                });
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                dispatch({
                    type: types.CLEAR_DATA,
                    payload: "true"
                });
                return
            } else if (response.data.success === "false" && (response.data.msg === "Authorization token expired" ||
                response.data.msg === "Wrong authorization token" || response.data.msg === "Authorization token not defined")) {
                return dispatch({
                    type: types.DO_LOGOUT,
                    payload: response.data
                })
            } else if (response.data.success === "false") {
                dispatch({
                    type: types.TEMPLATES_FAIL,
                    payload: response.data
                })
                dispatch({
                    type: types.TEMPLATES_LOADING,
                    payload: "false"
                });
                return
            }
        }).catch((err) => {
            dispatch({
                type: types.TEMPLATES_FAIL,
                payload: {
                    "sucess": "false",
                    "msg": "Your request failed! Please check your internet and try again",
                    "data": []
                }
            })
            dispatch({
                type: types.TEMPLATES_LOADING,
                payload: "false"
            });
            return
        })
    }
}
