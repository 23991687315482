import { BrowserRouter as Router, Route } from "react-router-dom";
import ProfileContaner from './components/ProfileComponent';
import React from 'react';
import LoginContainer from "./components/AuthComponent";
import DashboardContainer from "./components/DashboardComponent";
import ViewContactsContainer from "./components/ContactsComponent";
import TagsContainer from "./components/TagsComponent";
import TasksContainer from "./components/TasksComponent";
import NewTemplateContainer from "./components/NewTemplateComponent";
import ViewTemplatesContainer from "./components/ViewTemplatesComponent";
import EditTemplateContainer from "./components/EditTemplateComponent";
import TemplateDocsContainer from "./components/TemplateDocsComponent";
import EditDocumentContainer from "./components/EditDocumentComponent";
import ViewEmailsContainer from "./components/ViewEmailsComponent";
import EmailDetailsContainer from "./components/EmailDetailsComponent";
import ComposeEmailContainer from "./components/ComposeEmailComponent";
import ViewCorrespondenceContainer from "./components/ViewCorrespondenceComponent";
import ComposeCorrespondenceContainer from "./components/ComposeCorrespondenceComponent";
import CorrespondenceDetailsContainer from "./components/CorrespondenceDetailsComponent";
import ViewRoutesContainer from "./components/ViewRoutesComponent";
import CreateRouteStepsContainer from "./components/CreateRouteStepsComponent";
import EditRouteContainer from "./components/EditRouteComponent";
import ConversionsContainer from "./components/ConversionsComponent";
import PrivilegesContainer from "./components/PrivilegesComponent";
import RolesContainer from "./components/RolesComponent";
import ResetPasswordContainer from "./components/ResetPasswordComponent";

function App() {
	return (
		<div>
			<Router>
				<Route path="/" component={LoginContainer} exact />
				<Route path="/dashboard" component={DashboardContainer} exact />
				<Route path="/contacts" component={ViewContactsContainer} exact />
				<Route path="/tags" component={TagsContainer} exact />
				<Route path="/profile" component={ProfileContaner} exact />
				<Route path="/tasks" component={TasksContainer} exact />
				<Route path="/new_template" component={NewTemplateContainer} exact />
				<Route path="/view_templates" component={ViewTemplatesContainer} exact />
				<Route path="/edit_template" component={EditTemplateContainer} exact />
				<Route path="/documents" component={TemplateDocsContainer} exact />
				<Route path="/edit_document" component={EditDocumentContainer} exact />
				<Route path="/emails" component={ViewEmailsContainer} exact />
				<Route path="/email_details" component={EmailDetailsContainer} exact />
				<Route path="/compose_email" component={ComposeEmailContainer} exact />
				<Route path="/correspondence" component={ViewCorrespondenceContainer} exact />
				<Route path="/compose_correspondence" component={ComposeCorrespondenceContainer} exact />
				<Route path="/correspondence_details" component={CorrespondenceDetailsContainer} exact />
				<Route path="/view_routes" component={ViewRoutesContainer} exact />
				<Route path="/new_route_steps" component={CreateRouteStepsContainer} exact />
				<Route path="/edit_route" component={EditRouteContainer} exact />
				<Route path="/conversions" component={ConversionsContainer} exact />
				<Route path="/privileges" component={PrivilegesContainer} exact />
				<Route path="/roles" component={RolesContainer} exact />
				<Route path="/reset_password" component={ResetPasswordContainer} exact />
			</Router>
		</div>
	);
}

export default App;
