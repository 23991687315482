/* eslint-disable array-callback-return */
import React from 'react'
import { Link } from 'react-router-dom'
import types from '../../types';
// import TextField from '@material-ui/core/TextField';
import Select from 'react-select'

const EditTemplate = (props) => {

    return (
        <>
            <div className="content-wrapper">
                {/* <!-- Content Header (Page header) --> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Edit Template {props.c_state.single_template.templates ? `(${props.c_state.single_template.templates.template_name})` : ''}</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/view_templates">View Templates</Link></li>
                                    <li className="breadcrumb-item active">Edit Template</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <!-- /.container-fluid --> */}
                </section>

                {/* <!-- Main content --> */}
                <section className="content">
                    <div className="card" >
                    <div className="card-header">
                            <h3 className="card-title">Update Your Template</h3>
                            <div align="right">
                                {props.docs_config.document ? <><a className="btn btn-sm btn-outline-info" href={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.docs_config)}`)} target="_blank" rel="noreferrer"><i className="fa fa-file"></i> Open Document In New Tab</a>&nbsp;</> : null}
                            </div>
                        </div>
                        <div className="card card-outline">
                            {props.docs_config.document ?
                                <div className="row">
                                    <div className="col-9">

                                        <div className="embed-responsive embed-responsive-16by9" style={{ height: 1500 }}>
                                            <iframe
                                                className="embed-responsive-item"
                                                title="doc_viewer"
                                                src={encodeURI(`${types.SERVER_URL}docreader?configs=${JSON.stringify(props.docs_config)}`)}
                                                allowFullScreen>
                                            </iframe>
                                        </div>

                                    </div>
                                    <div className="col-3">
                                        <div align="center">
                                            <br />
                                            <button className="btn btn-sm btn-outline-danger" data-toggle="modal" data-target="#add_placeholders_modal" onClick={props.clear_modal_contents}><i className="fa fa-plus"></i> Add Placeholders</button>
                                        </div>
                                        <br /><br />
                                        {props.c_state.placeholder_table ?
                                            <table width="100%" className="table">
                                                <tbody>
                                                    {
                                                        props.c_state.placeholder_table.map((val, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td align="left"><span>{val.placeholder}</span></td>
                                                                    <td align="right">
                                                                        <button className="btn btn-xs btn-outline-primary" data-toggle="modal" data-target="#edit_placeholders_modal" onClick={() => props.set_modal_content(val, index)}>View/Edit</button>
                                                                        <button className="btn btn-xs btn-outline-danger" onClick={() => props.delete_placeholder(index)}>Delete</button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            : null}
                                        {
                                            props.c_state.placeholder_table.length ?
                                                <>
                                                    <div className="row">
                                                        <div className="form-group col-12">
                                                            <label htmlFor="category">Template Name *</label>
                                                            <input className="form-control" type="text" style={{ width: '95%' }} placeholder="Template Name" value={props.c_state.template_details.template_name ? props.c_state.template_details.template_name : ''} onChange={(event) => props.handleChange({ "field": "template_name", "value": event.target.value }, 'template')} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group col-12">
                                                            <label htmlFor="category">Tags </label>
                                                            <Select
                                                                options={props.c_state.tags_dropdown}
                                                                name="tags"
                                                                value={props.c_state.template_details.tags ? typeof props.c_state.template_details.tags !== "string" ? props.c_state.template_details.tags : JSON.parse(props.c_state.template_details.tags.replace(/'/g, '"')) : ''}
                                                                id="custom_select"
                                                                onChange={(value) => props.handleChange({ "field": "tags", "value": value }, 'template')}
                                                                isMulti
                                                                style={{ width: '95%' }}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div align="center">
                                                        <br />
                                                        <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button className="btn btn-block btn-primary" style={{ width: '95%' }} disabled={props.isLoading === "true" ? true : false} onClick={props.save_placeholders}><i className="fa fa-save"></i> Save</button></span>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>

                    {/* modals will come here */}
                    <div className="modal fade" id="add_placeholders_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Add Placeholder</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="placeholder">Placeholder *</label>
                                            <input type="text" value={props.c_state.placeholder_details.placeholder ? props.c_state.placeholder_details.placeholder : ''} onChange={(event) => props.handleChange({ "field": "placeholder", "value": event.target.value }, 'placeholder')} className="form-control" name="placeholder" placeholder="Placeholder" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="field_type">Feild Type *</label>
                                            <select className="form-control" name="field_type" onChange={(event) => props.handleChange({ "field": "field_type", "value": event.target.value }, 'placeholder')} value={props.c_state.placeholder_details.field_type ? props.c_state.placeholder_details.field_type : ''}>
                                                <option value="">--Select--</option>
                                                <option value="text">Text</option>
                                                <option value="textarea">Text Area</option>
                                                <option value="drop_down">Drop Down</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        props.c_state.placeholder_details.field_type ? props.c_state.placeholder_details.field_type === 'drop_down' ?
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label htmlFor="options">Options *</label>
                                                    <textarea onChange={(event) => props.handleChange({ "field": "options", "value": event.target.value }, 'placeholder')} style={{ resize: 'none' }} value={props.c_state.placeholder_details.options ? props.c_state.placeholder_details.options : ''} className="form-control" name="options" placeholder="Options separated by commas. e.g. Book, Pen, Pencil"></textarea>
                                                </div>
                                            </div>
                                            : null
                                            : null
                                    }
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="question">Question *</label>
                                            <textarea onChange={(event) => props.handleChange({ "field": "question", "value": event.target.value }, 'placeholder')} style={{ resize: 'none' }} value={props.c_state.placeholder_details.question ? props.c_state.placeholder_details.question : ''} className="form-control" name="question" placeholder="Type your question here..."></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_add_placeholder_modal">Close</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.add_placeholder}>Save changes</button></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="edit_placeholders_modal">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Edit Placeholder</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="placeholder">Placeholder *</label>
                                            <input type="text" value={props.c_state.placeholder_details.placeholder ? (props.c_state.placeholder_details.placeholder.startsWith('[') && props.c_state.placeholder_details.placeholder.endsWith(']')) ? props.c_state.placeholder_details.placeholder.slice(1, -1) : props.c_state.placeholder_details.placeholder : ''} onChange={(event) => props.handleChange({ "field": "placeholder", "value": event.target.value }, 'placeholder')} className="form-control" name="placeholder" placeholder="Placeholder" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="field_type">Feild Type *</label>
                                            <select className="form-control" name="field_type" onChange={(event) => props.handleChange({ "field": "field_type", "value": event.target.value }, 'placeholder')} value={props.c_state.placeholder_details.field_type ? props.c_state.placeholder_details.field_type : ''}>
                                                <option value="">--Select--</option>
                                                <option value="text">Text</option>
                                                <option value="textarea">Text Area</option>
                                                <option value="drop_down">Drop Down</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        props.c_state.placeholder_details.field_type ? props.c_state.placeholder_details.field_type === 'drop_down' ?
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label htmlFor="options">Options *</label>
                                                    <textarea onChange={(event) => props.handleChange({ "field": "options", "value": event.target.value }, 'placeholder')} style={{ resize: 'none' }} value={props.c_state.placeholder_details.options ? props.c_state.placeholder_details.options : ''} className="form-control" name="options" placeholder="Options separated by commas. e.g. Book, Pen, Pencil"></textarea>
                                                </div>
                                            </div>
                                            : null
                                            : null
                                    }
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <label htmlFor="question">Question *</label>
                                            <textarea onChange={(event) => props.handleChange({ "field": "question", "value": event.target.value }, 'placeholder')} style={{ resize: 'none' }} value={props.c_state.placeholder_details.question ? props.c_state.placeholder_details.question : ''} className="form-control" name="question" placeholder="Type your question here..."></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" id="dismiss_edit_placeholder_modal">Close</button>
                                    <span>{props.isLoading === "true" ? <img src="static/assets/dist/img/loader.gif" alt="loader" /> : ''}<button type="button" className="btn btn-primary" disabled={props.isLoading === "true" ? true : false} onClick={props.edit_placeholder}>Save changes</button></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default EditTemplate
